import { totalFileSizeLimit } from 'src/Services/Constants';

export const fileSizeToMB = (fileSize: number) =>
  parseInt(parseFloat((fileSize / 1024 / 1024).toString()).toFixed(2), 10);

export const fileSizeToKB = (fileSize: number) =>
  Math.round(parseInt(parseFloat((fileSize / 1024).toString()).toFixed(2), 10));

function displayFileSize(size) {
  const oneMB = 1000000;
  if (size > oneMB) {
    return `${fileSizeToMB(size)} MB`;
  }
  return `${fileSizeToKB(size)} KB`;
}
export default displayFileSize;

export function checkTotalFileSize(fileArray: Array<any>) {
  let totalSize = 0;

  for (let index = 0; index < fileArray?.length; index += 1) {
    const element = fileArray[index];
    totalSize += parseFloat(element.size);
  }
  if (totalSize > totalFileSizeLimit) {
    return { isError: true, totalSize: displayFileSize(totalSize) };
  }
  return { isError: false, totalSize: 0 };
}
