import { PDFColumLimit } from 'src/Services/Constants';

export default {
  overview: {
    Overview: 'Overview',
    'Now Happening': 'Current Events',
    'Latest Events': 'Latest Events',
    'New Organisers': 'New Organisers',
    'New Organiser Contacts': 'New Organiser Contacts',
    'There are no events today': 'There are no events today',
    'No events found': 'No events found',
    'Upcoming Events': 'Upcoming Events',
    'Past Events': 'Past Events',
    'Rally Details': 'Rally Details',
    'Event Details': 'Event Details',
    'ORGANISER DETAILS': 'ORGANISER DETAILS',
    'New Participants': 'New Participants',
    'PARTICIPANT DETAILS': 'PARTICIPANT DETAILS',
    'Organiser Details': 'Organiser Details',
    'All Events': 'All Events',
    'Status Filter': 'Status Filter',
    'Table Reports ': 'Table Reports ',
  },
  organizers: {
    Organisers: 'Organisers',
    Organiser: 'Organiser',
    'Add new Organiser': 'Add new Organiser',
    'Add New': 'Add New',
    'Organisers Listing': 'Organisers Listing',
    'Company/Full Name': 'Company/Full Name',
    Contact: 'Contact',
    'Registered as': 'Registered as',
    'New Organisers Listing': 'New Organisers Listing',
    'Created at': 'Created at',
    'Add New Organiser Contact': 'Add New Organiser Contact',
    'Edit Organiser Contact': 'Edit Organiser Contact',
    'Organiser Contact Details': 'Organiser Contact Details',
    'Company Details': 'Company Details',
    'Organiser Contact': 'Organiser Contact',
    Image: 'Image',
    Name: 'Name',
    Phone: 'Phone',
    Email: 'Email',
    Actions: 'Actions',
    Never: 'Never',
    'Member since': 'Member since',
    organizerCreate:
      'Organiser Created and login credentials sent to the Organiser email',
    organizerDelete: 'Organiser Deleted Successfully',
    organizerBlocked: 'Organiser Blocked Successfully',
    organizerUpdate: 'Organiser Updated Successfully',
    organizerContactBlocked: 'Organiser Contact Blocked Successfully',
    organizerContactUnBlocked: 'Organiser Contact Unblocked Successfully',
    eventOrganizerCreate: 'Event Organiser Created Successfully',
    eventOrganizerUpdate: 'Event Organiser Updated Successfully',
    eventOrganizerDelete: 'Event Organiser Deleted Successfully',
    organizerCreatedSucessfully: 'Organiser Created Sucessfully',
    organizerActivatedSuccessfully: 'Organiser Activated Successfully',
    'Organizer Contact': 'Organiser Contact',
  },
  formFields: {
    'Select Form Field Type': 'Select Form Field Type',
    'Form Field Management': 'Form Field Management',
    'Form Field Listing': 'Form Field Listing',
    'Form Field': 'Form Field',
    'Form Type': 'Form Type',
    Hint: 'Hint',
    Category: 'Category',
    'Add New': 'Add New',
    Actions: 'Actions',
    'Select Form Field': 'Select Form Field',
    'Select Category': 'Select Category',
    'Filter FormFields': 'Filter Form Fields',
    Id: 'Id',
    Entrant: 'Entrant',
    Settings: 'Settings',
    'Label Field is required': 'Label Field is required',
    'Id Field is required': 'Id Field is required',
    'New Form': 'New Form',
    'Clone Form': 'Clone Form',
    'Form Field is required': 'Form Field is required',
    'Category is required': 'Category is required',
    'Form Field Group': 'Form Field Group',
    'Form Field Group Listing': 'Form Field Group Listing',
    ParentName: 'Parent Name',
    GroupName: 'Group Name ',
    'An order number is required': 'An order number is required',
    'An order number must be an integer': 'An order number must be an integer',
    'An order number must be a positive value':
      'An order number must be a positive value',
    'Value field is required': 'Value field is required',
    'ParentName is required': 'Parent Name is required',
    'GroupName is required': 'Group Name is required',
    'Select Group': 'Select Group',
    ASCENDING: 'Ascending',
    DSCENDING: 'Dscending',
    CSV: 'CSV',
    PDF: 'PDF',
    'New Form Field Group': 'New Form Field Group',
    'Edit Form Field Group': 'Edit Form Field Group',
    createdAt: 'createdAt',
    eventName: 'eventName',
    startDateTime: 'startDateTime',
    endDateTime: 'endDateTime',
    enquiryPhone: 'enquiryPhone',
    enquiryEmail: 'enquiryEmail',
    memo: 'memo',
    eventDescription: 'eventDescription',
    isActive: 'isActive',
    isCancelled: 'isCancelled',
    status: 'status',
    companyname: 'companyname',
    email: 'email',
    phone: 'phone',
    address1: 'address1',
    address2: 'address2',
    street: 'street',
    city: 'city',
    state: 'state',
    country: 'country',
    postcode: 'postcode',
    facebookUrl: 'facebookUrl',
    instagramUrl: 'instagramUrl',
    webciteUrl: 'webciteUrl',
    participants: 'participants',
    postCode: 'postCode',
    secondAddress: 'secondAddress',
    firstAddress: 'firstAddress',
    rallyName: 'rallyName',
    fullname: 'fullname',
    Username: 'Username',
    RacingNo: 'RacingNo',
    FolderNo: 'FolderNo',
    'Co-pilot': 'Co-pilot',
    Car: 'Car',
    Phone: 'Phone',
    IsApproved: 'IsApproved',
    groupName: 'groupName',
    hint: 'hint',
    order: 'order',
    isMultiple: 'isMultiple',
    isCloned: 'isCloned',
    type: 'type',
    label: 'label',
    category: 'category',
    key: 'key',
    Group: 'Group',
    'Add A New Image': 'Add A New Image',
  },
  eventTypes: {
    'Event Type Management': 'Event Type Management',
    'Add New': 'Add New',
    'Event Type Listing': 'Event Type Listing',
    'Event Type': 'Event Type',
    Actions: 'Actions',
  },
  rallyTypes: {
    'Rally Type Management': 'Rally Type Management',
    'Add New': 'Add New',
    'Rally Type Listing': 'Rally Type Listing',
    'Rally Type': 'Rally Type',
    Actions: 'Actions',
    'Add New Rally Type': 'Add New Rally Type',
  },
  generalSettings: {
    'General Settings': 'General Settings',
  },
  forms: {
    'Organiser type': 'Organiser type',
    'Organiser Contact': 'Organiser Contact',
    'Select Type': 'Select Type',
    Select: 'Select',
    'Enquiry Contact': 'Enquiry Contact',
    'Enquiry Email': 'Enquiry Email',
    Company: 'Company',
    Individual: 'Individual',
    'Company Name': 'Company Name',
    'Full Name': 'Full Name',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    Email: 'Email',
    Phone: 'Phone',
    'Address Line 1': 'Address Line 1',
    Street: 'Street',
    City: 'City',
    State: 'State',
    Country: 'Country',
    Postcode: 'Postcode',
    'Facebook URL': 'Facebook URL',
    'Instagram URL': 'Instagram URL',
    Submit: 'Submit',
    Actions: 'Actions',
    Active: 'Active',
    inactive: 'inactive',
    Delete: 'Delete',
    Completed: 'Completed',
    completed: 'completed',
    'Filter Options': 'Filter Options',
    'Last login': 'Last login',
    Designation: 'Designation',
    Memo: 'Memo',
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    Import: 'Import',
    Status: 'Status',
    'Event Name': 'Event Name',
    'Event Type': 'Event Type',
    'Event Start Date': 'Event Start Date',
    'Event End Date': 'Event End Date',
    'Rally Start Date': 'Rally Start Date',
    'Rally End Date': 'Rally End Date',
    Venue: 'Venue',
    'Last Date of Registration': 'Last Date of Registration',
    'Start Date of Registration': 'Start Date of Registration',
    Website: 'Website',
    'Add Event Organiser': 'Add Event Organiser',
    'Add Rally': 'Add Rally',
    'Edit Rally': 'Edit Rally',
    Rally: 'Rally',
    'Manage Forms': 'Manage Forms',
    Participants: 'Participants',
    'Rally Name': 'Rally Name',
    'Rally Type': 'Rally Type',
    'Maximum No of Participants': 'Maximum No of Participants',
    'Last Date & Time of Registration': 'Last Date & Time  of Registration',
    'Address Line 2': 'Address Line 2',
    'Rally Forms': 'Rally Forms',
    'Racing No': 'Racing No',
    'Event Details': 'Event Details',
    'Add existing participant': 'Add existing participant',
    'Add New Participant': 'Add New Participant',
    'Add Participant': 'Add Participant',
    Pilot: 'Pilot',
    Contact: 'Contact',
    Edit: 'Edit',
    'Select a rally': 'Select a rally',
    'Website URL': 'Website URL',
    Password: 'Password',
    Username: 'Username',
    Deleted: 'Deleted',
    'Event Organiser': 'Event Organiser',
    'Enquiry Number': 'Enquiry Number',
    'Enroll Now': 'Enroll Now',
    pending: 'pending',
    'Form Name': 'Form Name',
    'New form added successfully': 'New form added successfully',
    'Form updated successfully': 'Form updated successfully',
    'Form Clone Warning':
      'Current form changes will get cleared if you proceed with cloning',
    'Form Submitted Successfully': 'Form Submitted Successfully',
    'Form Delete Warning': 'Are you sure you want to delete the form?',
    'Form Deleted Successfully': 'Form Deleted Successfully',
    'Event Forms': 'Event Forms',
    'Uploaded Files': 'Uploaded Files',
    'No Forms Available': 'No Forms Available',
    'View Event': 'View Event',
    'Text copied to clipboard': 'Text copied to clipboard',
    'Rally Cloned Successfully': 'Rally Cloned Successfully',
    'Clone Form From Previous Rally': 'Clone Form From Previous Rally',
    'Form Preview': 'Form Preview',
    Clone: 'Clone',
    'Form cloned successfully': 'Form cloned successfully',
    Label: 'Label',
    Hint: 'Hint',
    'Add Participants': 'Add Participants',
    Type: 'Type',
    Forms: 'Forms',
    'Update Form Field': 'Update Form Field',
    "You can't save an empty form": "You can't save an empty form",
    SUBMIT: 'SUBMIT',
    'Add File': 'Add File',
    'View Form': 'View Form',
    'Cannot submit an empty form': 'Cannot submit an empty form',
    'Pass atleast one editable field. Then save':
      'Pass atleast one editable field. Then save',
    'Form submission failed': 'Form submission failed',
    'Upload files under 50mb': 'Upload files under 50mb',
    'File Upload': 'File Upload',
    Block: 'Block',
    Unblock: 'Unblock',
    'All forms': 'All forms',
    'All Participants': 'All Participants',
    EnrollStatus: 'EnrollStatus',
    Upload: 'Upload',
    'Form Listing': 'Form Listing',
    Form: 'Form',
    'To adjust tables': 'To adjust tables',
    'Set headers for each page': 'Set headers for each page',
    Order: 'Order',
  },
  users: {
    'All Users Listing': 'All Users Listing',
    'All Users': 'All Users',
    Users: 'Participants',
    'Add New Participants': 'Add New Participants',
    'Participants Listing': 'Participants Listing',
    Profile: 'Profile',
    'Personal Details': 'Personal Details',
    'Hello,': 'Hello,',
    Welcome: 'Welcome',
    'Member since': 'Member since',
    Events: 'Events',
    Rallies: 'Rallies',
    Participants: 'Participants',
    'Last login on': 'Last login on',
    'Participant Profile': 'Participant Profile',
    'Organiser Profile': 'Organiser Profile',
    'Users Participation Declined': 'Users Participation Declined',
    'Declined by Organiser': 'Declined by Organiser',
    'Organiser Imported': 'Imported successfully',
    'Super Admin': 'Super Admin',
    'Invite Action': 'Invite Action',
    'Import Action': 'Import Action',
    Invite: 'Invite',
    Restore: 'Restore',
    'Participant Imported Successfully': 'Participant Imported Successfully',
    'Organiser Imported Successfully': 'Organiser Imported Successfully',
    Role: 'Role',
  },
  events: {
    Events: 'Events',
    Event: 'Event',
    'Event Details': 'Event Details',
    'Add New': 'Add New',
    'Add new Events': 'Add New Events',
    'Event Place': 'Event Place',
    'Filter Events': 'Filter Events',
    'Events Listing': 'Events Listing',
    'Add Event Organiser': 'Add Event Organiser',
    Memo: 'Memo',
    Website: 'Website',
    'Facebook Url': 'Facebook Url',
    'Instagram Url': 'Instagram Url',
    'Event Image': 'Event Image',
    'Rally Listing': 'Rally Listing',
    'Latest Events': 'Latest Events',
    'No events found': 'No events found',
    'Manage Forms': 'Manage Forms',
    'Manage Participants': 'Manage Participants',
    'Fill Up The Below Forms To Participate': 'Fill Up The Below Forms To Participate',
    'Filter Participants': 'Filter Participants',
    Export: 'Export',
    'Download List': 'Download List',
    Download: 'Download',
    'All Participants': 'All participants',
    'View Details': 'View Details',
    Rallies: 'Rallies',
    'Download PDF': 'Download PDF',
    'SUBMITTED FORMS': 'SUBMITTED FORMS',
    'PARTICIPANT DETAILS': 'PARTICIPANT DETAILS',
    'View profile': 'View profile',
    'Racing Number': 'racing number',
    'Clone Form From Previous Rally': 'Clone Form From Previous Rally',
    'Select event Name': 'Select event Name',
    'Select form': 'Select form',
    'Form name': 'Form name',
    'Source form name': 'Source form name',
    'New form name': 'New form name',
    Approved: 'Approved',
    Approve: 'Approve',
    Decline: 'Decline',
    Reinstate: 'Reinstate',
    Declined: 'Declined',
    Cancelled: 'Cancelled',
    Completed: 'Completed',
    'Registrations Completed': 'Registrations Completed',
    'Registration Closed': 'Registration Closed',
    'Registration Progressing': 'Registration Progressing',
    Others: 'Others',
    'Clone Form': 'Clone Form',
    Details: 'Details',
    Forms: 'Forms',
    'Organized By': 'Organized By',
    Invalid: 'Invalid',
    'View details': 'View details',
    'Participant Status': 'Participant Status',
    'In Progress': 'In Progress',
    "Participant's Event Status": "Participant's Event Status",
    'Enroll to Participate': 'Enroll to Participate',
    'Pending approval': 'Pending approval',
    Active: 'Active',
    'Edit form fields': 'Edit form fields',
    'Update Event Type': 'Update Event Type',
    'Update Rally Type': 'Update Rally Type',
    count: 'count',
    rallies: 'Rallies',
    participants: 'Participants',
    forms: 'Forms',
    'Event Cloned Successfully': 'Event Cloned Successfully',
    'Event Clone failed': 'Event Clone failed',
    eventCreate: 'Event Created Successfully',
    eventUpdate: 'Event Updated Successfully',
    eventDelete: 'Event deleted Successfully',
    eventCancelled: 'Event Cancelled Successfully',
    eventShared: 'Event Shared Successfully',
    userEventExist:
      'This user is not exist in this Compreg community. Add a new user then shared this event',
    Date: 'Date',
    'File Size': 'File Size',
    'File Name': 'File Name',
    'File Type': 'File Type',
    'Drag or Drop your files here': 'Drag or Drop your files here',
    'Date should not be before': 'Date should not be before',
    'Date should not be after': 'Date should not be after',
    'Races / Rallies': 'Races / Rallies',
    'Pass Event': 'Pass Event',
    Registrations: 'Registrations',
    Open: 'Open',
    Preparation: 'Preparation',
    'Choose different events for Source and Target':
      'Choose different events for Source and Target',
    'Participants invited Successfully': 'Participants invited Successfully',
    'Choose an event!!': 'Choose an event!!',
    Source: 'Source',
    Destination: 'Destination',
    'Add New Event Type': 'Add New Event Type',
  },
  addNewForm: {
    'Add New Form': 'Add New Form',
    'Clone Form': 'Clone Form',
    'Save & Preview': 'Save & Preview',
    Pilot: 'Pilot',
    'Co-Pilot': 'Co-Pilot',
    Car: 'Car',
    'Reco (Reconotering)': 'Reco (Reconotering)',
    'Assistance (Service)': 'Assistance (Service)',
    'Enter Form Name': 'Enter Form Name',
    'Edit Form': 'Edit Form',
    'Update & Preview': 'Update & Preview',
    Others: 'Others',
    Entrant: 'Entrant',
    FormHeader: 'Form Header',
    Organiser: 'Organiser',
    Participant: 'Participant',
    Rally: 'Rally',
    Organisers: 'Organisers',
    Customs: 'Customs',
    'Preview as Participant': 'Preview as Participant',
  },
  signUp: {
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    Login: 'Login',
    username: 'username',
    USERNAME: 'USERNAME',
    PASSWORD: 'PASSWORD',
    'Forgot password?': 'Forgot password?',
    'Don’t have an account?': 'Don’t have an account?',
    'Sign up': 'Sign up',
    'RESET PASSWORD': 'RESET PASSWORD',
    'SEND RESET PASSWORD LINK': 'SEND RESET PASSWORD LINK',
    'Sign in': 'Sign in',
    'Change Username': 'Change Username',
    'Change Password': 'Change Password',
    Signout: 'Signout',
    'Username*': 'Username*',
    Username: 'Username',
    'Old Password': 'Old Password',
    'New Password': 'New Password',
    'New Password*': 'New Password*',
    'New Password Confirmation': 'New Password Confirmation',
    Register: 'Register',
    Telephone: 'Telephone',
    'Confirm Password*': 'Confirm Password*',
    'Confirm Password': 'Confirm Password',
    Back: 'Back',
    Next: 'Next',
    'ADD SOCIAL MEDIA': 'ADD SOCIAL MEDIA',
    'SKIP & COMPLETE REGISTRATION': 'SKIP & COMPLETE REGISTRATION',
    'SAVE & Complete registration': 'SAVE & Complete registration',
    'Please change username': 'Please change username',
    'Old password does not match our records': 'Old password does not match our records',
    'Passwords are same': 'Passwords are same',
    'Password Updated Successfully, Please Login again to Continue':
      'Password Updated Successfully, Please Login again to Continue',
    'Switch Profile': 'Switch Profile',
    organizer: 'Organiser',
    eventOrganizer: 'Event Organiser',
    Participant: 'Participant',
  },
  others: {
    'Add pin': 'Add pin',
    'Search…': 'Search…',
    'Upload CSV': 'Upload CSV',
    'Sample CSV': 'Sample CSV',
    'Export CSV': 'Export CSV',
    Yes: 'Yes',
    Cancel: 'Cancel',
    Share: 'Share',
    'Copy Link': 'Copy Link',
    'Enter username': 'Enter username',
    Invite: 'Invite',
    Copy: 'Copy',
    Optional: 'Optional',
    'Event Status': 'Event Status',
    by: 'by',
    'Import CSV': 'Import CSV',
    'No items found': 'No items found',
    from: 'from',
    to: 'to',
    count: 'Count',
    page: 'page',
    'View Rallys': 'View Rallys',
    'View Rally': 'View Rally',
    'No item found': 'No item found',
    'Nothing to show': 'Nothing to show',
    'SELECT ALL': 'SELECT ALL',
    'Select All': 'Select All',
    'Unselect All': 'Unselect All',
    'DESELECT ALL': 'DESELECT ALL',
    'Rows per page': 'Rows per page',
    Change: 'Change',
    'Something went wrong': 'Something went wrong',
    'Please select a valid Excel file': 'Please select a valid Excel file',
    'Please login to view the forms': 'Please login to view the forms',
    'Table Reports Listing': 'Table Reports Listing',
    'Resource Name': 'Resource Name',
    'Report Name': 'Report Name',
    'Table Reports': 'Table Reports',
    'Save as Table report': 'Save as Table report',
    'Report saved successfully': 'Report saved successfully',
    'Report updated successfully': 'Report updated successfully',
    'Choose File': 'Choose File',
    'Aspect ratio': 'Aspect ratio',
    'Report Name field is required': 'Report Name field is required',
    'Filter all': 'Filter all',
    Filter: 'Filter',
    "The participant has been cancelled.Can't be share the rallys":
      "The participant has been cancelled.Can't be share the rallys",
    Deselect: 'Deselect',
    items: 'items',
    'Save Changes': 'Save Changes',
  },
  profile: {
    'Are you sure you want to sign out?': 'Are you sure you want to sign out?',
  },
  participant: {
    'Participant Approved': 'Participant Approved',
    'Participant Declined': 'Participant Declined',
    'View Registration': 'View Registration',
    created:
      'Participant Created and login credentials are send to the participant email',
    deleted: 'User deleted successfully',
    updated: 'User updated successfully',
    participantCreatedSuccessfuly: 'Participant Created Successfuly',
    'Manage Participant': 'Manage Participant',
    'Participant Name': 'Participant Name',
    'Select Rally': 'Select Rally',
    Save: 'Save',
  },
  popup: {
    Yes: 'Yes',
    Cancel: 'Cancel',
    'Are you sure you want to delete the participant?':
      'Are you sure want to delete the participant?',
    'You have been enrolled successfully. Please start filling the event forms':
      'You have been enrolled successfully. Please start filling the event forms',
    'Are you sure you want to cancel the participant?':
      'Are you sure you want to cancel the participant?',
    'Are you sure you want to approve the participant?':
      'Are you sure you want to approve the participant?',
    'Are you sure you want to unblock the organiser?':
      'Are you sure you want to unblock the organiser?',
    'Are you sure you want to block the organiser?':
      'Are you sure you want to block the organiser?',
    'Are you sure you want to delete the event?':
      'Are you sure you want to delete the event?',
    'This is an irreversible action.': 'This is an irreversible action.',
    'Are you sure you want to cancel the event?':
      'Are you sure you want to cancel the event?',
    'Do you want to delete ?': 'Do you want to delete ?',
    'Are you sure you want to delete the organiser?':
      'Are you sure you want to delete the organiser?',
    'Are you sure you want to delete the event organiser?':
      'Are you sure you want to delete the event organiser?',
    '+Add New Contact': '+Add New Contact',
    'Are you sure you want to cancel the rally?':
      'Are you sure you want to cancel the rally?',
    'Are you sure you want to delete the rally?':
      'Are you sure you want to delete the rally?',
    'There is a mismatch between the event date and rally date. Please manually change the rally date':
      'There is a mismatch between the event date and rally date. Please manually change the rally date',
    'Participant created and login credentials are sent to the participant email':
      'Participant created and login credentials are sent to the participant email',
    'You cannot participate in this event either your are super admin or organiser':
      'You cannot participate in this event either your are super admin or organiser',
    'You cannot participant in this event either your are super admin or organiser':
      'You cannot participant in this event either your are super admin or organiser',
    'Unsaved changes will be discarded': 'Unsaved changes will be discarded',
    'Are you sure you want to change the block status?':
      'Are you sure you want to change the block status?',
    'Are you sure you want to delete the form fields?':
      'Are you sure you want to delete the form fields?',
    'You cannot participate in this rally / event':
      'You cannot participate in this rally / event',
    'This event organizer is assigned to an event. Are you sure you want to delete?':
      'This event organizer is assigned to an event. Are you sure you want to delete?',
    'It will take time, continue in background':
      'Preparing your backup. We will email you the download link once it is ready.',
    'Restore event': 'Restore event',
    'Restore organiser': 'Restore organiser',
    'Backup event': 'Backup event',
    'Backup Organiser': 'Backup organiser',
    "It's an approved participant.": "It's an approved participant.",
    'Are you sure you want to delete the Form Field Group?':
      'Are you sure you want to delete the Form Field Group?',
    'You have been enrolled successfully. Please see the event details from participant dashboard':
      'You have been enrolled successfully. Please see the event details from participant dashboard',
    eventRegistrationClosed: 'Event registration has been closed',
    eventRegistrationNotOpen: 'Event registration is not yet open',
    eventFinished: 'The event has successfully finished',
    eventCancelled: 'The event has been cancelled',
    'Are you sure you want to update Rally details?':
      'Are you sure you want to update Rally details?',
  },
  validationErrors: {
    'First name is Required': 'First name is Required',
    'Username is Required': 'Username is Required',
    'Password is Required': 'Password is Required',
    'Confirm Password is Required': 'Confirm Password is Required',
    'Invalid email': 'Invalid email',
    'Type is Required': 'Type is Required',
    'Company name is Required': 'Company name is Required',
    'Event name is Required': 'Event name is Required',
    'Start Date Time is Required': 'Start Date Time is Required',
    'End Date Time is Required': 'End Date Time is Required',
    'Start Date of Registration is Required': 'Start Date of Registration is Required',
    'Last Date of Registration is Required': 'Last Date of Registration is Required',
    'Organiser is Required': 'Organiser is Required',
    'Rally Type is Required': 'Rally Type is Required',
    'Rally name is Required': 'Rally name is Required',
    'Form name is required': 'Form name is required',
    'Event Type is Required': 'Event Type is Required',
    'Username already exist': 'Username already exist',
    'Too Short!': 'Too Short!',
    'Too Long!': 'Too Long!',
    'choose total file size lessthan': 'choose total file size lessthan',
    'Choose individual file size less than': 'choose file size less than',
    'Passwords must match': 'Passwords must match',
    'Admin email is required': 'Admin email is required',
    'First name is not allowed to be empty': 'First name is not allowed to be empty',
    'The firstname cannot include leading and trailing spaces':
      'The firstname cannot include leading and trailing spaces',
    'Status Type is Required': 'Status Type is Required',
    'Source event is required': 'Source event is required',
    'Target event is required': 'Target event is required',
    'End date cant be before Start date': "End date can't be before Start date",
    'Rally name is too Short!': 'Rally name is too Short!',
    'file is not in the file size limit, Pleas choose less than':
      'file is not in the file size limit, Pleas choose less than',
    'total file size, Pleas choose  less than':
      'total file size, Pleas choose  less than',
    'The username cannot include leading and trailing spaces':
      'The username cannot include leading and trailing spaces',
    'The password cannot include leading and trailing spaces':
      'The password cannot include leading and trailing spaces',
    'The Email cannot include leading and trailing spaces':
      'The Email cannot include leading and trailing spaces',
    'use at least one alphabet': 'use at least one alphabet',
    'The lastname cannot include leading and trailing spaces':
      'The lastname cannot include leading and trailing spaces',
    'Phone Number cannot include leading and trailing spaces':
      'Phone Number cannot include leading and trailing spaces',
    'Invalid Phone Number': 'Invalid Phone Number',
    'The Email cannot include uppercase alphabets':
      'The Email cannot include uppercase alphabets',
    'The username cannot include uppercase alphabets':
      'The username cannot include uppercase alphabets',
    'Select a participant': 'Select a participant',
    'Invalid Status': 'Invalid Status',
    'Event name is too Short!': 'Event name is too Short!',
    'choose a valid event status': 'choose a valid event status',
  },
  filterForms: {
    'Filter Forms': 'Filter Forms',
  },
  toast: {
    'Start Date of Registration must be less than or equal to Start Date':
      'Start Date of Registration must be less than or equal to Start Date',
    'Last Date of Registration must be greater than or equal to Start Date of Registration':
      'Last Date of Registration must be greater than or equal to Start Date of Registration',
    'Last Date of Registration must be less than or equal to End Date':
      'Last Date of Registration must be less than or equal to End Date',
    'End Date must be greater than or equal to Start Date':
      'End Date must be greater than or equal to Start Date',
    "This user has been blocked.Can't be share the rallys":
      "This user has been blocked.Can't be share the rallys",
    "This user is an organiser.Can't be share the rallys":
      "This user is an organiser.Can't be share the rallys",
    'Cant submit empty email draft': "Can't submit empty email draft",
    'Enroll Decline, This rally is Cancelled': 'Enroll Decline, This rally is Cancelled',
    'Enroll Decline, This rally is completed': 'Enroll Decline, This rally is completed',
    "This user has been blocked.Can't be share the events":
      "This user has been blocked.Can't be share the events",
    'rally end date must be before or same as the event end date':
      'rally end date must be before or same as the event end date',
    'rally start date must be after or same as the event start date':
      'rally start date must be after or same as the event start date',
    'Denied for Cancelled Event ': 'Denied for Cancelled Event ',
    'Please choose an Organizer': 'Please choose an Organizer',
    'Language Changed Successfully': 'Language Changed Successfully',
    'Organiser Contact created successfully, credentials will email to you':
      'Organiser Contact created successfully, credentials will email to you',
    'Organiser Contact updated successfully': 'Organiser Contact updated successfully',
    'Username already exist': 'Username already exist',
    'Form Field Group Added Successfully': 'Form Field Group Added Successfully',
    'Sucessfully updated': 'Successfully updated',
    'Enroll in Event First': 'Enroll in Event First',
    'Past date is not allowed': 'Past date is not allowed',
    'restore failed': 'restore failed',
    'This participant active in event': 'This participant active in event.',
    'This participant may active in event': 'This participant may active in event.',
    '"email" must be a valid email': 'Email must be a valid email',
    'Unfortunately, the cancelled cannot be edited':
      'Unfortunately, the cancelled cannot be edited',
    'You were declined by Organiser': 'You were declined by Organiser',
    'Your approval is in pending': 'Your approval is in pending',
    'View event details from participant dashboard':
      'View event details from participant dashboard',
    "It's not possible to delete this organiser since there are events to be organised":
      "It's not possible to delete this organiser since there are events to be organised",
    'file is not acceptable': 'file is not acceptable',
    "The chosen event's start date has been reached":
      "The chosen event's start date has been reached",
    "The chosen event's registration start date has not yet been reached":
      "The chosen event's registration start date has not yet been reached",
    "The chosen event's registration end date has been reached":
      "The chosen event's registration end date has been reached",
    "The chosen event's registration deadline has not yet been reached":
      "The chosen event's registration deadline has not yet been reached",
    "The chosen event's start date has not yet been reached":
      "The chosen event's start date has not yet been reached",
    "The chosen event's end date has been reached":
      "The chosen event's end date has been reached",
    "The chosen event's end date has not yet been reached":
      "The chosen event's end date has not yet been reached",
    'Are you sure you want to cancel the event?':
      'Are you sure you want to cancel the event?',
    'You do not have the authorization to access this':
      'You do not have the authorization to access this',
    'Please choose valid event status': 'Please choose valid event status',
    'The event will be cancelled upon submission':
      'The event will be cancelled upon submission',
  },
  user: {
    loginMessage: 'Logged in successfully',
    signup: 'User Registered Successfully',
    alreadyExist: 'Username already exist',
    passWordResetLink: 'Password reset link sent to your email account',
    passwordUpdate: 'Password Updated Successfully, Please Login again to Continue',
    userNotfound: 'No user found',
    userNameNotExist: 'User with given username does not exist',
    userNameUpdated: 'Username Updated',
    userNameExist: 'Username already exist',
    passwordChanged: 'Your password was changed',
    userDeleted: 'User deleted successfully',
    userUpdated: 'User updated successfully',
  },
  rally: {
    rallyCreate: 'Rally Created Successfully',
    rallyUpdate: 'Rally Updated Successfully',
    rallyDelete: 'Rally deleted Successfully',
    rallyCancelled: 'Rally Cancelled Successfully',
    rallyShared: 'Rally shared successfully',
    userExistRally:
      'This user is not exist in this Compreg community. Add a new user then shared this rally',
    rallyCloned: 'Rally cloned successfully',
    'Select atleast one Race': 'Select atleast one Race',
    'Select Active participant': 'Select Active participant',
  },
  eventParticipant: {
    participantApproved: 'Participant status updated Successfully',
    formSubmitted: 'Form Submitted Successfully',
    formUpdated: 'Form Updated Successfully',
    racingNumberUpdate: 'Racing number updated',
    folderNumberUpdate: 'Folder number updated',
  },
  eventForm: {
    formUpdated: 'Form updated added successfully',
    formCloned: 'Form cloned successfully',
    formDeleted: 'Form Deleted Successfully',
  },
  formField: {
    alreadyExist: 'Id Already Exist',
    created: 'Form field created successfully',
    updated: 'Form field updated successfully',
    deleted: 'Form field deleted successfully',
  },
  eventType: {
    created: 'Event type created sucessfully',
    updated: 'Event type updated sucessfully',
    deleted: 'Event type deleted sucessfully',
    existingTypeError: 'Event Type already exists in the application.',
  },
  rallyType: {
    created: 'Rally type created sucessfully',
    updated: 'Rally type updated sucessfully',
    deleted: 'Rally type deleted sucessfully',
    existingTypeError: 'Rally Type already exists in the application.',
  },
  other: {
    error: 'Something went wrong',
    'Sort By': 'Sort By',
    'Sort Order': 'Sort Order',
    'Download Type': 'Download Type',
    orientation: 'Orientation',
  },
  mail: {
    signup: 'Welcome to Compreg Community',
    reset: 'Password Reset',
    organizer: 'Compreg New Organiser Account',
    event: 'Invitation to',
    participant: 'Compreg New Participant Account',
  },
  fields: {
    'Pilot First Name': 'Pilot First Name',
    'Pilot Nationality': 'Pilot Nationality',
    'Pilot Birth Date': 'Pilot Birth Date',
    'Pilot Birth Place': 'Pilot Birth Place',
    'Pilot Emergency Contact': 'Pilot Emergency Contact',
    'Pilot Drivers License Number': 'Pilot Drivers License Number',
    'pilot_drivers_license_date.Pilot Drivers License Date': 'Pilot Drivers License Date',
    'Pilot Drivers License Place': 'Pilot Drivers License Place',
    'Pilot Drivers License Country': 'Pilot Drivers License Country',
    'Pilot Passport Number': 'Pilot Passport Number',
    'Pilot Passport Delivery Date': 'Pilot Passport Delivery Date',
    'Pilot Passport Delivery Place': 'Pilot Passport Delivery Place',
    'Pilot License': 'Pilot License',
    'Pilot Team': 'Pilot Team',
    'Pilot Clothing Size': 'Pilot Clothing Size',
    'Pilot Signature': 'Pilot Signature',
    Copilot: 'Copilot',
    'Copilot Nationality': 'Copilot Nationality',
    'Copilot Birth Date': 'Copilot Birth Date',
    'Copilot Birth Place': 'Copilot Birth Place',
    'Copilot Emergency Contact': 'Copilot Emergency Contact',
    'Copilot Drivers License Number': 'Copilot Drivers License Number',
    'Copilot Drivers LicenseDate': 'Copilot Drivers LicenseDate',
    'Copilot Drivers License Place': 'Copilot Drivers License Place',
    'Copilot Drivers License Country': 'Copilot Drivers License Country',
    'Copilot Passport Number': 'Copilot Passport Number',
    'Copilot Passport Delivery Date': 'Copilot Passport Delivery Date',
    'Copilot Passport Delivery Place': 'Copilot Passport Delivery Place',
    'Copilot License': 'Copilot License',
    'Copilot Team': 'Copilot Team',
    'Copilot Clothing Size': 'Copilot Clothing Size',
    'Copilot Signature': 'Copilot Signature',
    'Car Brand': 'Car Brand',
    'Car Model': 'Car Model',
    'Car Group': 'Car Group',
    'Car Class': 'Car Class',
    'Car Registration': 'Car Registration',
    'Car Displacement': 'Car Displacement',
    'Car Homologation  Number': 'Car Homologation Number',
    'Car Historic Technical Passport': 'Car Historic Technical Passport',
    'Car Owner Name': 'Car Owner Name',
    'Car Owner Loan Aprroval Date': 'Car Owner Loan Aprroval Date',
    'Car Technical Check OK': 'Car Technical Check OK',
    'Car Chassis Number': 'Car Chassis Number',
    'Car Engine Number': 'Car Engine Number',
    'Car Color': 'Car Color',
    'Car Picture': 'Car Picture',
    'Reco Car Brand': 'Reco Car Brand',
    'Reco Car Model': 'Reco Car Model',
    'Reco Car Registration': 'Reco Car Registration',
    'Reco Car Control Plate': 'Reco Car Control Plate',
    'Reco Car Color': 'Reco Car Color',
    'Assistance Contact In Charge': 'Assistance Contact In Charge',
    'Assistance Requirements Width': 'Assistance Requirements Width',
    'Assistance Requirements Length': 'Assistance Requirements Length',
    'Assistance Requirements Height': 'Assistance Requirements Height',
    'Assistance Memo': 'Assistance Memo',
    Heading: 'Heading',
    Paragraph: 'Paragraph',
    Address: 'Address',
    City: 'City',
    Country: 'Country',
    'Date and place of birth': 'Date and place of birth',
    Email: 'Email',
    Name: 'Name',
    Nationality: 'Nationality',
    'Phone Number': 'Phone Number',
    'Postal Code': 'Postal Code',
    Surname: 'Surname',
    'First Name': 'First Name',
    'Passport Number': 'Passport Number',
    Company: 'Company',
    Title: 'Title',
    Mobile: 'Mobile',
    'Car Period': 'Car Period',
    'Car Year': 'Car Year',
    'Generic Checkbox': 'Generic Checkbox',
    'Generic Date': 'Generic Date',
    'Pilot Licence Number': 'Pilot Licence Number',
    'Pilot Licence Scan': 'Pilot Licence Scan',
    'Pilot Memo': 'Pilot Memo',
    'Copilot City': 'Copilot City',
    'Copilot Country': 'Copilot Country',
    'Copilot Email': 'Copilot Email',
    'Copilot Fixed': 'Copilot Fixed Phone',
    'Pilot Sex': 'Pilot Sex',
    'Pilot Email': 'Pilot Email',
    'Generic Amount': 'Generic Amount',
    'Copilot Sex': 'Copilot Sex',
    'Pilot Company': 'Pilot Company',
    'Pilot Title': 'Pilot Title',
    'Pilot Address': 'Pilot Address',
    'Pilot Address 2': 'Pilot Address 2',
    'Pilot City': 'Pilot City',
    'Pilot Country': 'Pilot Country',
    'Pilot Fixed': 'Pilot Fixed Phone',
    'Copilot Picture': 'Copilot Picture',
    'Copilot Postal Code': 'Copilot Postal Code',
    'Copilot Web': 'Copilot Web',
    'Pilot Mobile': 'Pilot Mobile',
    'Pilot Picture': 'Pilot Picture',
    'Pilot Postal Code': 'Pilot Postal Code',
    'Pilot Date Entry Country': 'Pilot Date Entry Country',
    'Pilot Emergency Email': 'Pilot Emergency Email',
    'Pilot Emergency Phone': 'Pilot Emergency Phone',
    'Pilot Passport Scan': 'Pilot Passport Scan',
    'Pilot Place Entry Country': 'Pilot Place Entry Country',
    'Pilot Drivers Licence Country': 'Pilot Drivers Licence Country',
    'Pilot Drivers Licence Date': 'Pilot Drivers Licence Date',
    'Pilot Drivers Licence Number': 'Pilot Drivers Licence Number',
    'Pilot Drivers Licence Place': 'Pilot Drivers Licence Place',
    'Pilot Drivers Licence Scan': 'Pilot Drivers Licence Scan',
    'Copilot Passport Scan': 'Copilot Passport Scan',
    'Copilot Place Entry Country': 'Copilot Place Entry Country',
    'Copilot Drivers Licence Country': 'Copilot Drivers Licence Country',
    'Copilot Drivers Licence Date': 'Copilot Drivers Licence Date',
    'Copilot Drivers Licence Number': 'Copilot Drivers Licence Number',
    'Copilot Drivers Licence Place': 'Copilot Drivers Licence Place',
    'Copilot Drivers Licence Scan': 'Copilot Drivers Licence Scan',
    'Copilot Licence Number': 'Copilot Licence Number',
    'Copilot Licence Scan': 'Copilot Licence Scan',
    'Copilot Licence Type': 'Copilot Licence Type',
    'Entrant Sex': 'Entrant Sex',
    'Entrant Title': 'Entrant Title',
    'Entrant First Name': 'Entrant First Name',
    'Entrant Last Name': 'Entrant Last Name',
    'Entrant Company': 'Entrant Company',
    'Entrant Address': 'Entrant Address',
    'Entrant Address 2': 'Entrant Address 2',
    'Entrant Postal Code': 'Entrant Postal Code',
    'Entrant City': 'Entrant City',
    'Entrant Country': 'Entrant Country',
    'Entrant Mobile': 'Entrant Mobile',
    'Entrant Fixed': 'Entrant Fixed Phone',
    'Entrant Email': 'Entrant Email',
    'Entrant Web': 'Entrant Web',
    'Entrant Picture': 'Entrant Picture',
    'Entrant Passport Number': 'Entrant Passport Number',
    'Entrant Passport Delivery Date': 'Entrant Passport Delivery Date',
    'Entrant Passport Delivery Place': 'Entrant Passport Delivery Place',
    'Entrant Passport Scan': 'Entrant Passport Scan',
    'Entrant Emergency Contact': 'Entrant Emergency Contact',
    'Entrant Emergency Phone': 'Entrant Emergency Phone',
    'Entrant Emergency Email': 'Entrant Emergency Email',
    'Entrant Place Entry Country': 'Entrant Place Entry Country',
    'Entrant Date Entry Country': 'Entrant Date Entry Country',
    'Entrant Nationality': 'Entrant Nationality',
    'Entrant Birth Date': 'Entrant Birth Date',
    'Entrant Birth Place': 'Entrant Birth Place',
    'Entrant Signature': 'Entrant Signature',
    'Entrant Drivers Licence Scan': 'Entrant Drivers Licence Scan',
    'Entrant Drivers Licence Number': 'Entrant Drivers Licence Number',
    'Entrant Drivers Licence Date': 'Entrant Drivers Licence Date',
    'Entrant Drivers Licence Place': 'Entrant Drivers Licence Place',
    'Entrant Drivers Licence Country': 'Entrant Drivers Licence Country',
    'Entrant Licence Scan': 'Entrant Licence Scan',
    'Entrant Licence Number': 'Entrant Licence Number',
    'Entrant Licence Type': 'Entrant Licence Type',
    'Entrant Clothing Size': 'Entrant Clothing Size',
    'Entrant Team': 'Entrant Team',
    'Entrant Memo': 'Entrant Memo',
    'Rally Event Name': 'Race Event Name',
    'Rally Name': 'Race Name',
    'Rally Category': 'Race Category',
    'Rally Logo': 'Race Logo',
    'Rally Venue': 'Race Venue',
    'Rally Start Date': 'Race Start Date',
    'Rally End Date': 'Race End Date',
    'Rally Timing Web': 'Race Timing Web',
    'Rally Timing Organisation': 'Race Timing Organisation',
    'Rally Timing Contact Person Sex': 'Race Timing Contact Person Sex',
    'Rally Timing Contact Person Title': 'Race Timing Contact Person Title',
    'Rally Timing Contact Person First Name': 'Race Timing Contact Person First Name',
    'Rally Timing Contact Person Last Name': 'Race Timing Contact Person Last Name',
    'Rally Timing Contact Person Company': 'Race Timing Contact Person Company',
    'Rally Timing Contact Person Mobile': 'Race Timing Contact Person Mobile',
    'Rally Timing Contact Person Address': 'Race Timing Contact Person Address',
    'Rally Timing Contact Person Address 2': 'Race Timing Contact Person Address 2',
    'Rally Timing Contact Person Postal Code': 'Race Timing Contact Person Postal Code',
    'Rally Timing Contact Person City': 'Race Timing Contact Person City',
    'Rally Timing Contact Person Country': 'Race Timing Contact Person Country',
    'Rally Timing Contact Person Fixed': 'Race Timing Contact Person Fixed Phone',
    'Rally Timing Contact Person Web': 'Race Timing Contact Person Web',
    'Rally Timing Contact Person Picture': 'Race Timing Contact Person Picture',
    'Rally Timing Contact Person Passport Number':
      'Race Timing Contact Person Passport Number',
    'Rally Timing Contact Person Email': 'Race Timing Contact Person Email',
    'Rally Timing Contact Person Passport Delivery Date':
      'Race Timing Contact Person Passport Delivery Date',
    'Rally Timing Contact Person Passport Delivery Place':
      'Race Timing Contact Person Passport Delivery Place',
    'Rally Timing Contact Person Passport Scan':
      'Race Timing Contact Person Passport Scan',
    'Rally Timing Contact Person Emergency Contact':
      'Race Timing Contact Person Emergency Contact',
    'Rally Timing Contact Person Emergency Phone':
      'Race Timing Contact Person Emergency Phone',
    'Rally Timing Contact Person Emergency Email':
      'Race Timing Contact Person Emergency Email',
    'Rally Timing Contact Person Place Entry Country':
      'Race Timing Contact Person Place Entry Country',
    'Rally Timing Contact Person Date Entry Country':
      'Race Timing Contact Person Date Entry Country',
    'Rally Timing Contact Person Nationality': 'Race Timing Contact Person Nationality',
    'Rally Timing Contact Person Birth Date': 'Race Timing Contact Person Birth Date',
    'Rally Timing Contact Person Signature': 'Race Timing Contact Person Signature',
    'Rally Timing Contact Person Drivers Licence Scan':
      'Race Timing Contact Person Drivers Licence Scan',
    'Rally Timing Contact Person Birth Place': 'Race Timing Contact Person Birth Place',
    'Rally Timing Contact Person Drivers Licence Number':
      'Race Timing Contact Person Drivers Licence Number',
    'Rally Timing Contact Person Drivers Licence Date':
      'Race Timing Contact Person Drivers Licence Date',
    'Rally Timing Contact Person Drivers Licence Place':
      'Race Timing Contact Person Drivers Licence Place',
    'Rally Timing Contact Person Drivers Licence Country':
      'Race Timing Contact Person Drivers Licence Country',
    'Rally Timing Contact Person Licence Scan': 'Race Timing Contact Person Licence Scan',
    'Rally Timing Contact Person Licence Number':
      'Race Timing Contact Person Licence Number',
    'Rally Timing Contact Person Licence Type': 'Race Timing Contact Person Licence Type',
    'Rally Timing Contact Person Clothing Size':
      'Race Timing Contact Person Clothing Size',
    'Rally Timing Contact Person Team': 'Race Timing Contact Person Team',
    'Rally Timing Contact Person Memo': 'Race Timing Contact Person Memo',
    'Reco Memo': 'Reco Memo',
    'Assistance Contact Incharge Sex': 'Assistance Contact In charge Sex',
    'Assistance Contact Incharge Title': 'Assistance Contact In charge Title',
    'Assistance Contact Incharge First Name': 'Assistance Contact In charge First Name',
    'Assistance Contact Incharge Last Name': 'Assistance Contact In charge Last Name',
    'Assistance Contact Incharge Company': 'Assistance Contact In charge Company',
    'Assistance Contact Incharge Address': 'Assistance Contact In charge Address',
    'Assistance Contact Incharge Address 2': 'Assistance Contact In charge Address 2',
    'Assistance Contact Incharge Postal Code': 'Assistance Contact In charge Postal Code',
    'Assistance Contact Incharge City': 'Assistance Contact In charge City',
    'Assistance Contact Incharge Country': 'Assistance Contact In charge Country',
    'Assistance Contact Incharge Mobile': 'Assistance Contact In charge Mobile',
    'Assistance Contact Incharge Fixed': 'Assistance Contact In charge Fixed Phone',
    'Assistance Contact Incharge Email': 'Assistance Contact In charge Email',
    'Assistance Contact Incharge Web': 'Assistance Contact In charge Web',
    'Assistance Contact Incharge Picture': 'Assistance Contact In charge Picture',
    'Assistance Contact Incharge Passport Number':
      'Assistance Contact In charge Passport Number',
    'Assistance Contact Incharge Passport Delivery Date':
      'Assistance Contact In charge Passport Delivery Date',
    'Assistance Contact Incharge Passport Delivery Place':
      'Assistance Contact In charge Passport Delivery Place',
    'Assistance Contact Incharge Passport Scan':
      'Assistance Contact In charge Passport Scan',
    'Assistance Contact Incharge Emergency Contact':
      'Assistance Contact In charge Emergency Contact',
    'Assistance Contact Incharge Emergency Phone':
      'Assistance Contact In charge Emergency Phone',
    'Assistance Contact Incharge Emergency Email':
      'Assistance Contact In charge Emergency Email',
    'Assistance Contact Incharge Nationality': 'Assistance Contact In charge Nationality',
    'Assistance Contact Incharge Place Entry Country':
      'Assistance Contact In charge Place Entry Country',
    'Assistance Contact Incharge Date Entry Country':
      'Assistance Contact In charge Date Entry Country',
    'Assistance Contact Incharge Birth Date': 'Assistance Contact In charge Birth Date',
    'Assistance Contact Incharge Birth Place': 'Assistance Contact In charge Birth Place',
    'Assistance Contact Incharge Signature': 'Assistance Contact In charge Signature',
    'Assistance Contact Incharge Drivers Licence Scan':
      'Assistance Contact In charge Drivers Licence Scan',
    'Assistance Contact Incharge Drivers Licence Number':
      'Assistance Contact In charge Drivers Licence Number',
    'Assistance Contact Incharge Drivers Licence Date':
      'Assistance Contact In charge Drivers Licence Date',
    'Assistance Contact Incharge Drivers Licence Place':
      'Assistance Contact In charge Drivers Licence Place',
    'Assistance Contact Incharge Drivers Licence Country':
      'Assistance Contact In charge Drivers Licence Country',
    'Assistance Contact Incharge Licence Scan':
      'Assistance Contact In charge Licence Scan',
    'Assistance Contact Incharge Licence Number':
      'Assistance Contact In charge Licence Number',
    'Assistance Contact Incharge Licence Type':
      'Assistance Contact In charge Licence Type',
    'Assistance Contact Incharge Clothing Size':
      'Assistance Contact In charge Clothing Size',
    'Assistance Contact Incharge Team': 'Assistance Contact In charge Team',
    'Assistance Contact Incharge Memo': 'Assistance Contact In charge Memo',
    'Organiser Name': 'Organiser Name',
    'Organiser Logo': 'Organiser Logo',
    'Organiser Contact Person Sex': 'Organiser Contact Person Sex',
    'Organiser Contact Person Title': 'Organiser Contact Person Title',
    'Organiser Contact Person Last Name': 'Organiser Contact Person Last Name',
    'Organiser Contact Person First Name': 'Organiser Contact Person First Name',
    'Organiser Contact Person Company': 'Organiser Contact Person Company',
    'Organiser Contact Person Address': 'Organiser Contact Person Address',
    'Organiser Contact Person Address 2': 'Organiser Contact Person Address 2',
    'Organiser Contact Person Postal Code': 'Organiser Contact Person Postal Code',
    'Organiser Contact Person City': 'Organiser Contact Person City',
    'Organiser Contact Person Country': 'Organiser Contact Person Country',
    'Organiser Contact Person Mobile': 'Organiser Contact Person Mobile',
    'Organiser Contact Person Fixed': 'Organiser Contact Person Fixed Phone',
    'Organiser Contact Person Email': 'Organiser Contact Person Email',
    'Organiser Contact Person Web': 'Organiser Contact Person Web',
    'Organiser Contact Person Picture': 'Organiser Contact Person Picture',
    'Organiser Contact Person Passport Number':
      'Organiser Contact Person Passport Number',
    'Organiser Contact Person Passport Delivery Place':
      'Organiser Contact Person Passport Delivery Place',
    'Organiser Contact Person Passport Delivery Date':
      'Organiser Contact Person Passport Delivery Date',
    'Organiser Contact Person Passport Scan': 'Organiser Contact Person Passport Scan',
    'Organiser Contact Person Emergency Contact':
      'Organiser Contact Person Emergency Contact',
    'Organiser Contact Person Emergency Phone':
      'Organiser Contact Person Emergency Phone',
    'Organiser Contact Person Emergency Email':
      'Organiser Contact Person Emergency Email',
    'Organiser Contact Person Place Entry Country':
      'Organiser Contact Person Place Entry Country',
    'Organiser Contact Person Date Entry Country':
      'Organiser Contact Person Date Entry Country',
    'Organiser Contact Person Nationality': 'Organiser Contact Person Nationality',
    'Organiser Contact Person Birth Date': 'Organiser Contact Person Birth Date',
    'Organiser Contact Person Birth Place': 'Organiser Contact Person Birth Place',
    'Organiser Contact Person Signature': 'Organiser Contact Person Signature',
    'Organiser Contact Person Drivers Licence Scan':
      'Organiser Contact Person Drivers Licence Scan',
    'Organiser Contact Person Drivers Licence Number':
      'Organiser Contact Person Drivers Licence Number',
    'Organiser Contact Person Drivers Licence Date':
      'Organiser Contact Person Drivers Licence Date',
    'Organiser Contact Person Drivers Licence Place':
      'Organiser Contact Person Drivers Licence Place',
    'Organiser Contact Person Licence Scan': 'Organiser Contact Person Licence Scan',
    'Organiser Contact Person Drivers Licence Country':
      'Organiser Contact Person Drivers Licence Country',
    'Organiser Contact Person Licence Number': 'Organiser Contact Person Licence Number',
    'Organiser Contact Person Licence Type': 'Organiser Contact Person Licence Type',
    'Organiser Contact Person Clothing Size': 'Organiser Contact Person Clothing Size',
    'Organiser Contact Person Team': 'Organiser Contact Person Team',
    'Organiser Contact Person Memo': 'Organiser Contact Person Memo',
    'Car Registration Scan': 'Car Registration Scan',
  },
  'General settings': {
    'General settings': 'General settings',
    'Email Restrictions': 'Email Restrictions',
    SMTP: 'SMTP',
    'Admin Email': 'Admin Email',
    'Email Restriction for Participant': 'Email Restriction for Participant',
    'Email Restriction for Oragnizer': 'Email Restriction for Organiser',
    'Email Restriction for Oragnizer Contact': 'Email Restriction for Organiser Contact',
    'All the participant emails will be forwarded to admin email':
      'All the participant emails will be forwarded to admin email',
    'All the Oragniser emails will be forwarded to admin email':
      'All the Organiser emails will be forwarded to admin email',
    'All the Oragniser Contact emails will be forwarded to admin email':
      'All the Organiser Contact emails will be forwarded to admin email',
    Host: 'Host',
    Port: 'Port',
    Secure: 'Secure',
    'End Date Time is Required': 'End Date Time is Required',
    'Start Date Time is Required': 'Start Date Time is Required',
    'Type is Required': 'Type is Required',
    'Assign Participants To Race': 'Assign Participants To Race',
    'Choose CSV': 'Choose CSV',
    'Add file': 'Add file',
    Regulations: 'Regulations',
    'Official forms': 'Official forms',
    'Timing schedule': 'Timing schedule',
    Roadbook: 'Roadbook',
    other: 'other',
    'Event Cloned Successfully': 'Event Cloned Successfully',
    'filter forms': 'filter forms',
    'No Forms Available': 'No Forms Available',
    'Update Event Type': 'Update Event Type',
    'email must be a valid email': 'email must be a valid email',
    'Edit Form Header': 'Edit Form Header',
    'Pdf download failed': 'Pdf download failed',
    'Assign Participants To Rally': 'Assign Participants To Rally',
    'Event Type Updated Successfully': 'Event Type Updated Successfully',
    'Download Sample CSV': 'Download Sample CSV',
    'Form field - Multiple- True/False': 'Form field - Multiple- True/False',
    'Release Notes': 'Release Notes',
    'Copilot Nationality': 'Copilot Nationality',
    'Copilot Passport Delivery Place': 'Copilot Passport Delivery Place',
    'Date and place of birth': 'Date and place of birth',
    'Phone Number': 'Phone Number',
    'Postal Code': 'Postal Code',
    Surname: 'Surname',
    'Add participants': 'Add participants',
    FormHeader: 'Form Header',
    to: 'to',
    count: 'count',
    page: 'page',
    'Are you sure you want to delete the organiser?':
      'Are you sure you want to delete the organiser?',
    'Filter Forms': 'Filter Forms',
    'Type is required': 'Type is required',
    'start date time is required': 'start date time is required',
    'end date time is required': 'end date time is required',
    'organiser is required': 'organiser is required',
    'Form name is required': 'Form name is required',
    'No space and Uppercase letters allowed': 'No space and Uppercase letters allowed',
    Radio: 'Radio',
    File: 'File',
    'choose less than 1 MB': 'choose less than 1 MB',
    'Driving License': 'Driving License',
    'Menu Icon': 'Menu Icon',
    'Participant Created Successfuly': 'Participant Created Successfuly',
    'Submitted successfully': 'Submitted successfully',
    'Zip download failed': 'Zip download failed',
    From: 'From',
    'Empty list could not convert to csv': 'Empty list could not convert to csv',
    'choose an image file less than 1MB': 'choose an image file less than 1MB',
    'Please choose an image file': 'Please choose an image file',
  },
  formTypes: {
    number: 'Number',
    text: 'Text',
    textarea: 'Text area',
    radio: 'Radio',
    checkbox: 'checkbox',
    date: 'date',
    time: 'time',
    datetime: 'datetime',
    file: 'file',
    heading: 'heading',
    paragraph: 'paragraph',
    image: 'image',
    line: 'line',
    dropdown: 'dropdown',
  },
  menuItems: {
    AllCategory: 'All Category',
    Pilot: 'Pilot',
    Copilot: 'Copilot',
    Car: 'Car',
    Reco: 'Reco',
    Assistance: 'Assistance',
    Entrant: 'Entrant',
    Organiser: 'Organiser',
    Rally: 'Rally',
    Participant: 'Participant',
    Other: 'Other',
  },
  // drag: {
  //   createdAt: 'createdAt',
  //   companyname: 'companyname',
  //   email: 'email',
  //   phone: 'phone',
  //   enquiryPhone: 'enquiryPhone',
  //   address1: 'address1',
  //   address2: 'address2',
  //   street: 'street',
  //   city: 'city',
  //   state: 'state',
  //   isActive: 'isActive',
  //   isCancelled: 'isCancelled',
  //   status: 'status',
  //   label: 'label',
  // },
  exportDesigner: {
    exportWarning: `Cannot download Pdf with more than ${PDFColumLimit} fields. You can either choose CSV with the same settings or choose fields below ${PDFColumLimit}`,
  },
  message: {
    'at document row': 'at document row',
    'eventName is required': 'eventName is required',
    'eventName duplicate found': 'eventName duplicate found',
    'eventType is required': 'eventType is required',
    "Can't submit empty sheet": "Can't submit empty sheet",
    'invalid eventType': 'invalid eventType',
    'timezone is required': 'timezone is required',
    'timezone mismatch': 'timezone mismatch',
    'startDateTime is required': 'startDateTime is required',
    'endDateTime is required': 'endDateTime is required',
    'startDateTime is greater than endDateTime':
      'startDateTime is greater than endDateTime ',
    'lastDate of registarion is required': 'lastDate of registarion is required',
    'lastDate of registarion is less than endDateTime':
      'lastDate of registarion is less than endDateTime ',
    'organizer is required': 'organizer is required',
    'invalid organizer': 'invalid organizer',
    // rally
    'rallyName is required': 'rallyName is required',
    'rallName duplicate found': 'rallName duplicate found',
    'invalid eventName': 'invalid eventName',
    'rallyType is required': 'rallyType is required',
    'invalid rallyType': 'invalid rallyType',
    'Rally startDate must between event startDate/endDate':
      ' Rally startDate must between event startDate/endDate',
    'Rally endDate must between event startDate/endDate':
      ' Rally endDate must between event startDate/endDate',
    // organizer
    'password is required': 'password is required',
    'password length must be greater than 6 characters':
      'password length must be greater than 6 characters ',
    'username is required': 'username is required',
    'username is already exist': 'username is already exist',
    'name is already exist': 'name is already exist',
  },
  login: {
    'Your account is blocked.Please contact admin for more details':
      'Your account is blocked.Please contact admin for more details',
    'No user found': 'No user found',
  },
  restore: {
    'Restore Event': 'Restore Event',
    'Restore Organiser': 'Restore Organiser',
  },
  errors: {
    'Invalid email at document row ': 'Invalid email at document row ',
    'Invalid username format': 'Invalid username format',
    'Username is required at document row ': 'Username is required at document row ',
    'Password is required at document row ': 'Password is required at document row ',
    'FirstName is required at document row ': 'FirstName is required at document row ',
  },
  eventStatus: {
    Preparation: 'Preparation',
    'Registrations open': 'Registrations open',
    'Registrations completed': 'Registrations completed',
    'In progress': 'In progress',
    'Event finished': 'Event finished',
    Cancelled: 'Cancelled',
  },
  Exporter: {
    Source: 'Source',
    Destination: 'Destination',
    'Rally Exporter': 'Rally Exporter',
    'Event Exporter': 'Event Exporter',
    'Organizer Exporter': 'Organiser Exporter',
    'Organizer Contact Exporter': 'Organiser Contact Exporter',
    'Form Fields Exporter': 'form Fields Exporter',
    'Event Participants Exporter': 'Event Participants Exporter',
    'Rally Participants Exporter': 'Rally Participants Exporter',
  },
  AppDropDown: {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
  },
  'File Type': {
    'File Type': 'File Type',
    'File Type Management': 'File Type Management',
    'File Type Listing': 'File Type Listing',
    Delete: 'Are you sure want to delete the FileType?',
    Deleted: 'FileType deleted successfully',
    'File type is required': 'File type is required',
    created: 'File type created successfully',
    updated: 'File type updated successfully',
    existingError: 'File type already exists in the application',
    addNew: 'Add New File Type',
    update: 'Update File Type',
  },
};
