// eslint-disable-next-line no-shadow
enum EnumPermissions {
  GetGeneralSettings = `get-general_settings`,
  ViewOrganizerProfile = 'view-organizer-profile',
  ViewFormFieldGroup = 'view-form_field_group',
  GetSwitchProfile = 'get-switch_profile',
  ViewParticipantProfile = 'view-participant-profile',
  ShowProfileNavigationButton = 'show-profile_navigation_button',
  ViewNewOrganizers = 'view-NewOrganizers',
  ShowTableActions = 'show-table_actions',
  CreateEvent = 'create-event',
  CreateEventOrganizer = 'create-eventOrganizer',
  DeleteEvent = 'delete-event',
  ViewEventOrganizer = 'view-NewEventOrganizer',
  Restore = 'restore',
  RestoreEvent = 'restore_event',
  RestoreOrganiser = 'restore_organiser',
  GetAllUsers = 'get_all-users',
  GetFileType = 'get_file_type',
}

export default EnumPermissions;
