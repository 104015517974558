import { Icon } from '@iconify/react';
import Button from '@material-ui/core/Button/Button';
import React from 'react';
import { downloadDocumentFromUrl, openTab, previewTypes } from 'src/Services/Utils';
import useStyles from './style';

function DownloadButton({ item }) {
  const classes = useStyles();

  const handleView = () => {
    const url = item.BlobURL || item.files;
    const name = item.name || item.dropdown;
    if (item.type && previewTypes.includes(item.type)) {
      openTab(item.type, url, name);
    } else {
      downloadDocumentFromUrl(url, name);
    }
  };
  return (
    <div>
      <Button onClick={() => handleView()}>
        <Icon icon="feather:eye" className={classes.eyeIcon} />
      </Button>
    </div>
  );
}

export default DownloadButton;
