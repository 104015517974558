import Box from '@material-ui/core/Box/Box';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Formik, Form, Field } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AvatarUpload from 'src/Components/AvatarUpload/AvatarUpload';
import { ErrorMessageCustom, FormButton, FormLabel } from 'src/Components/Utils';
import { rallyTypes } from 'src/Services/Auth/Actions/RallyActions';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import useStyles from './RallyAddEditFormStyle';
import rallyimgAvatar from './rallyimgAvatar.svg';
import AddFileButton from './AddFileButton';

function RallyAddEditForm({
  title,
  initialValues,
  RallyValidations,
  onSubmit,
  setImage,
  details,
  eventDetail,
  files,
  setFiles,
  rallyTypeId,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [list, setList] = useState([]);

  const getRallyType = () => {
    rallyTypes(rallyTypeId)
      .then((response) => {
        setList(response.data.rallyType.rallyTypes);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getRallyType();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={RallyValidations}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form>
          <div className={classes.wrapper}>
            <h2 id="transition-modal-title" className={classes.title}>
              {t(title)}
            </h2>
            <FormButton name={t('forms.Submit')} isLoading={isSubmitting} />
          </div>
          <Box sx={{ flexGrow: 1 }} className={classes.outerbox}>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <AvatarUpload
                  image={rallyimgAvatar}
                  setImage={setImage}
                  defaultImage={details && details.rallyImage}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormLabel name={t('forms.Rally Name')} require />
                <Field
                  className={classes.input1}
                  text="rallyName"
                  type="text"
                  name="rallyName"
                  value={values.rallyName}
                  onChange={handleChange}
                />
                <ErrorMessageCustom errors={errors} field="rallyName" touched={touched} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <FormLabel name={t('forms.Rally Type')} require />
                <select
                  name="rallyType"
                  value={values.rallyType}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  style={{ display: 'block', marginBottom: '0px' }}
                >
                  <option value="" label={t('forms.Select')} />
                  {list.map((item: any) => (
                    <option value={item._id} label={`${item.type}`} />
                  ))}
                </select>
                <ErrorMessageCustom errors={errors} field="rallyType" touched={touched} />
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormLabel name={t('forms.Rally Start Date')} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="popupDatePickerWrapper">
                    <KeyboardDatePicker
                      allowKeyboardControl
                      invalidDateMessage={null}
                      clearable
                      minDate={(eventDetail && eventDetail.startDateTime) || new Date()}
                      minDateMessage={`Date should not be before ${moment(
                        eventDetail && eventDetail.startDateTime,
                      ).format('DD/MM/YYYY')}`}
                      maxDate={(eventDetail && eventDetail.endDateTime) || new Date()}
                      maxDateMessage={`Date should not be after ${moment(
                        eventDetail && eventDetail.endDateTime,
                      ).format('DD/MM/YYYY')}`}
                      onChange={(value) => {
                        setFieldValue('startDateTime', value);
                      }}
                      format="dd/MM/yyyy"
                      name="startDateTime"
                      value={values.startDateTime}
                      className={classes.input1}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <ErrorMessageCustom
                  errors={errors}
                  field="startDateTime"
                  touched={touched}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <FormLabel name={t('forms.Rally End Date')} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="popupDatePickerWrapper">
                    <KeyboardDatePicker
                      allowKeyboardControl
                      invalidDateMessage={null}
                      clearable
                      minDate={(eventDetail && eventDetail.startDateTime) || new Date()}
                      minDateMessage={`Date should not be before ${moment(
                        eventDetail && eventDetail.startDateTime,
                      ).format('DD/MM/YYYY')}`}
                      maxDate={(eventDetail && eventDetail.endDateTime) || new Date()}
                      maxDateMessage={`Date should not be after ${moment(
                        eventDetail && eventDetail.endDateTime,
                      ).format('DD/MM/YYYY')}`}
                      onChange={(value) => {
                        setFieldValue('endDateTime', value);
                      }}
                      format="dd/MM/yyyy"
                      className={classes.input1}
                      value={values.endDateTime}
                      name="endDateTime"
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <ErrorMessageCustom
                  errors={errors}
                  field="endDateTime"
                  touched={touched}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormLabel name={t('forms.Memo')} />
                <Field
                  className={classes.input1}
                  text="memo"
                  component="textarea"
                  rows="5"
                  name="memo"
                  value={values.memo}
                  onChange={handleChange}
                />
                <ErrorMessageCustom errors={errors} field="memo" touched={touched} />
              </Grid>
            </Grid>
            <AddFileButton files={files} setFiles={setFiles} />
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default RallyAddEditForm;
