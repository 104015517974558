/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { loggedUser } from 'src/Services/Auth/Actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import LoginView from './Views/Auth/LoginView';
import RegisterView from './Views/Auth/RegisterView';
import ForgotPasswordView from './Views/Auth/ForgotPasswordView';
import OverView from './Views/Dashboard/OverView';
import Organizers from './Views/Organizers/Organizers';
import Events from './Views/Events/Events';
import Users from './Views/Users/Users';
import ForgotPasswordCompleteView from './Views/Auth/ForgotPasswordCompleteView';
import Settings from './Views/Settings/Settings';
import AddNewOrganizer from './Views/Organizers/AddNewOrganizer';
import AddNewUser from './Views/Users/AddNewUsers';
import OrganizerResetView from './Views/Auth/organizerResetView';
import OrganizerDetails from './Views/Organizers/OrganizerDetails';
import UserDetails from './Views/Users/UserDetails';
import AddNewEvent from './Views/Events/AddNewEvent';
import EventDetails from './Views/Events/EventDetails/index';
import ManageParticipant from './Views/Events/ManageParticipant';
import CloneEventForm from './Views/Events/CloneEventForm';
import EventView from './Views/Events/EventView';
import FormFields from './Views/FormFields/FormFeilds';
import EventTypes from './Views/EventTypes/EventTypes';
import RallyTypes from './Views/RallyTypes/RallyTypes';
import NewEventForms from './Containers/Events/Rally/NewEventForm';
import EditNewEventForm from './Containers/Events/Rally/EditNewEventForm';
import AddRally from './Containers/Events/Rally/RallyParticipant';
import FormWrapper from './Containers/Events/Rally/FormWrapper';
import GeneralSettings from './Components/GeneralSettings/GeneralSettings';
import FormFieldGroup from './Components/FormFieldGroup/FormFieldGroup';
import { RootState } from './Store';
import Forms from './Views/Forms/Forms';
import Restore from './Views/Restore/Restore';
import TableReport from './Views/TableReports/Tablereports';
import AllUsers from './Views/Users/AllUsers';
import FileTypes from './Views/FileTypes/FileTypes';

function Routes() {
  const userRole = useSelector((store: RootState) => store?.role?.user?.role);
  const user = useSelector((store: RootState) => store?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      return;
    }
    loggedUser(userRole)
      .then((response) => {
        dispatch({
          type: 'SET_USER',
          payload: response.data.user,
        });
      })
      .catch((err) => console.error('err', err));
  }, []);

  return (
    <Switch>
      <Route path="/events/forms/editForm/:eventId/:formId" exact>
        <FormWrapper>
          <EditNewEventForm />
        </FormWrapper>
      </Route>
      <Route exact path="/">
        <LoginView />
      </Route>
      <Route path="/register">
        <RegisterView />
      </Route>
      <Route path="/forgot-password">
        <ForgotPasswordView />
      </Route>
      <Route path="/forgot-password-complete/password-reset/:id">
        <ForgotPasswordCompleteView />
      </Route>
      <Route path="/organizer-reset/user-credentials/:id">
        <OrganizerResetView />
      </Route>
      <Route path="/dashboard">
        <OverView />
      </Route>
      <Route path="/form-field-group">
        <FormFieldGroup />
      </Route>
      <Route path="/form-fields">
        <FormFields />
      </Route>
      <Route path="/event-types">
        <EventTypes />
      </Route>
      <Route path="/rally-types">
        <RallyTypes />
      </Route>
      <Route path="/restore">
        <Restore />
      </Route>
      <Route path="/file-types">
        <FileTypes />
      </Route>
      <Route path="/general-settings">
        <GeneralSettings />
      </Route>
      <Route path="/forms">
        <Forms />
      </Route>
      <Route path="/settings">
        <Settings />
      </Route>
      <Route exact path="/organizers">
        <Organizers />
      </Route>
      <Route path="/organizers/add-new">
        <AddNewOrganizer />
      </Route>
      <Route path="/organizers/details/:id/:tab">
        <OrganizerDetails />
      </Route>
      <Route path="/eventorganizers/details/:id/:eventOrgId/:tab">
        <OrganizerDetails isEventOrganizers />
      </Route>
      <Route path="/events/add-new" exact>
        <AddNewEvent />
      </Route>
      <Route path="/events/add-new/:organizerId">
        <AddNewEvent />
      </Route>
      <Route path="/events/details/:id/:page">
        <EventDetails />
      </Route>
      <Route path="/events/details/:id">
        <EventDetails />
      </Route>
      <Route path="/users/details/events/details/:id/:page">
        <EventDetails />
      </Route>
      <Route path="/events" exact>
        <Events />
      </Route>
      <Route exact path="/users">
        <Users />
      </Route>
      <Route exact path="/all-users">
        <AllUsers />
      </Route>
      <Route exact path="/table-reports">
        <TableReport />
      </Route>
      <Route path="/users/add-new">
        <AddNewUser />
      </Route>
      <Route path="/users/details/:id/:page">
        <UserDetails />
      </Route>
      <Route path="/events/forms/createForm/:eventId" exact>
        <FormWrapper>
          <NewEventForms />
        </FormWrapper>
      </Route>
      <Route path="/events/forms/participants/:eventId/:userId" exact>
        <ManageParticipant />
      </Route>
      <Route path="/events/forms/cloneForm/:eventId" exact>
        <CloneEventForm />
      </Route>
      <Route path="/events/rally/add-participant/:eventId/:rallyId" exact>
        <AddRally />
      </Route>
      <Route path="/events/rally/add-participant/:eventId/:rallyId/users/details/:id">
        <UserDetails />
      </Route>
      <Route exact path="/event-details/:eventId/:rallyId">
        <EventView />
      </Route>
      <Route exact path="/event-details/:eventId">
        <EventView />
      </Route>
    </Switch>
  );
}

export default Routes;
