import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import {
  Button,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { split } from 'lodash';
import { useTranslation } from 'react-i18next';
import DownloadButton from 'src/Services/DownloadButton';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import displayFileSize, {
  checkTotalFileSize,
} from 'src/Components/Utils/DisplayFileSize';
import clsx from 'clsx';
import useStyles from './Attachmenttablestyle';
import { ImageConfig } from './ImageConfig/ImageConfig';
import { formateDateWithoutTime } from '../../../Services/Utils';

function AttachmentTable({ files, setFiles, setTotalFileSize, disabled = false }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [fileNameEdit, setFileNameEdit] = useState<any>();
  const [fileName, setFileName] = useState<any>();
  const confirm: any = useConfirm();

  const deleteFile = (item) => {
    confirm({
      description: t('Are you sure you want to delete the file?'),
    }).then(() => {
      const filterFiles: Array<any> = [...files.filter((file) => file._id !== item._id)];
      setFiles(filterFiles);
      setTotalFileSize(checkTotalFileSize(filterFiles));
    });
  };

  const viewItemById = (id) => {
    const pdfWindow: any = window.open('');
    const item = files && files.filter((data) => data.id === id);
    if (item) {
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='${encodeURI(
          item[0].BlobURL || item[0].files || item[0].tempFileBlobURL,
        )}'></iframe>`,
      );
    }
  };

  const handleDropDownChange = (event, file) => {
    const currentDropDown = files.find((f) => f._id === file._id);
    currentDropDown.dropdown = event.target.value;
    setFiles([...files]);
  };

  const onFileNameChange = (filename, file) => {
    const currentFile = files.find((f) => f === file);
    currentFile.name = filename;
    setFiles([...files]);
  };

  const handleFileNameClick = (index, newFileName) => {
    setFileName(split(newFileName, '.')[0]);
    setFileNameEdit(index);
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const saveFileName = (item) => {
    if (fileName.length > 0) {
      setFileNameEdit(null);
      onFileNameChange(fileName, item);
    }
    setFileNameEdit(null);
  };

  return (
    <TableContainer
      className={clsx(classes.tablecontainer, disabled && classes.tablecontainerModal)}
    >
      <Table aria-label="simple table" className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell className={classes.tablehead} />
            <TableCell className={classes.tablehead}>{t('events.File Name')}</TableCell>
            <TableCell className={classes.tablehead}>{t('events.File Size')}</TableCell>
            <TableCell className={classes.tablehead}>{t('events.Date')}</TableCell>
            <TableCell className={classes.tablehead}>{t('events.File Type')}</TableCell>
            <TableCell className={classes.tablehead}>{t('formFields.Actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((item: any, index) => (
            <TableRow>
              <TableCell>
                <img
                  style={{ height: '35px' }}
                  src={ImageConfig[item?.type?.split('/')[1]] || ImageConfig.default}
                  alt="fileTypeImage"
                />
              </TableCell>
              <TableCell
                onDoubleClick={() => {
                  if (!disabled) {
                    handleFileNameClick(index, item.name);
                  }
                }}
              >
                {fileNameEdit === index ? (
                  <OutlinedInput
                    type="text"
                    name="fileName"
                    value={fileName}
                    onChange={(event) => handleFileNameChange(event)}
                    startAdornment={
                      <Icon
                        icon="mdi:tick"
                        color="green"
                        width="24"
                        height="24"
                        onClick={() => {
                          saveFileName(item);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    }
                    notched
                    autoFocus
                  />
                ) : (
                  split(item.name, '.')[0]
                )}
              </TableCell>
              <TableCell>{displayFileSize(item?.size)}</TableCell>
              <TableCell>{formateDateWithoutTime(item.date)}</TableCell>
              <TableCell>
                <select
                  style={{ width: '160px' }}
                  value={item?.dropdown}
                  onChange={(event) => handleDropDownChange(event, item)}
                  disabled={disabled}
                >
                  <option value="Regulations">{t('General settings.Regulations')}</option>
                  <option value="Official forms">
                    {t('General settings.Official forms')}
                  </option>
                  <option value="Timing schedule">
                    {t('General settings.Timing schedule')}
                  </option>
                  <option value="Roadbook">{t('General settings.Roadbook')}</option>
                  <option value="other">{t('General settings.other')}</option>
                </select>
              </TableCell>
              <TableCell>
                <div className={classes.eyeWrapper}>
                  {item?.id ? (
                    <Button onClick={() => viewItemById(item?.id)}>
                      <Icon icon="feather:eye" className={classes.eyeIcon} />
                    </Button>
                  ) : (
                    <DownloadButton item={item} />
                  )}
                  <div>
                    {!disabled && (
                      <Button onClick={() => deleteFile(item)}>
                        <Icon icon="ant-design:delete-twotone" className={classes.icon} />
                      </Button>
                    )}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default AttachmentTable;
