import { PDFColumLimit } from 'src/Services/Constants';

export default {
  overview: {
    Overview: 'En résumé',
    'Now Happening': 'Evènements en cours',
    'Latest Events': 'Derniers évènements',
    'New Organisers': 'Nouveaux organisateurs',
    'New Organiser Contacts': 'Nouveaux contacts organisateurs',
    'There are no events today': "Il n'y a aucun évènemement, aujourd'hui",
    'No events found': 'Aucun évènement trouvé',
    'Upcoming Events': 'Evènements à venir',
    'Past Events': 'Evènements passés',
    'Rally Details': 'Détails du rallye',
    'Event Details': "Détails de l'évènement",
    'ORGANISER DETAILS': "DETAIL DE L'ORGANISATEUR",
    'New Participants': 'Nouveaux participants',
    'PARTICIPANT DETAILS': 'DETAILS DU PARTICIPANT',
    'Organiser Details': "Detail de l'Organisateur",
    'All Events': 'Tous les évènements',
    'Status Filter': "Filtre d'état",
    'Table Reports ': 'Rapports de tableau',
  },
  organizers: {
    Organisers: 'Organisateurs',
    Organiser: 'Organisateur',
    'Add new Organiser': 'Ajouter un organisateur',
    'Add New': 'Ajouter',
    'Organisers Listing': 'Liste des organisateurs',
    'Company/Full Name': 'Société, Nom',
    Contact: 'Contact',
    'Registered as': 'Enregistré sous',
    'New Organisers Listing': 'Liste des nouveaux organisateurs',
    'Created at': 'Créé le',
    'Add New Organiser Contact': 'Ajouter un contact à un organisateur',
    'Edit Organiser Contact': "Modifier un contact d'un organisateur",
    'Company Details': 'Détails de la société',
    'Organiser Contact': 'Organisateur contact',
    'Organiser Contact Details': "Coordonnées de l'organisateur",
    Image: 'Image',
    Name: 'Nom',
    Phone: 'Téléphone',
    Email: 'Email',
    Actions: 'Actions',
    Never: 'Jamais',
    'Member since': 'Membre depuis',
    organizerCreate: "L'organisateur de l'évènement a été créé",
    organizerDelete: "L'organisateur a bien été supprimé",
    organizerBlocked: 'Organiser Blocked Successfully',
    organizerUpdate: "L'organisateur a été bien mis à jour",
    eventOrganizerCreate: "L'organisateur de l'évènement a été créé",
    eventOrganizerUpdate: "L'organisateur de l'évènement a été mis à jour",
    eventOrganizerDelete: "L'organisateur de l'évènement a ét supprimé",
    organizerCreatedSucessfully: "L'organisateur a été créé avec succès",
    organizerActivatedSuccessfully: "L'organisateur a été activé avec succès",
    'Organizer Contact': "Coordonnées de l'organisateur",
    organizerContactUnBlocked: "Contact de l'organisateur débloqué avec succès",
    organizerContactBlocked: "Contact de l'organisateur bloqué avec succès",
  },
  formFields: {
    'Select Form Field Type': 'Select Form Field Type',
    'Form Field Management': 'Gestion des champs des formulaires',
    'Form Field Listing': 'Liste des champs des formulaires',
    'Form Field': "Champ d'un formulaire",
    'Form Type': 'Type de champ',
    Hint: 'Suggestion',
    Category: 'Catégorie',
    'Add New': 'Ajouter un nouveau champ',
    Actions: 'Actions',
    'Select Form Field': 'Choix du champ du formulaire',
    'Select Category': 'Choisir le type',
    'Filter FormFields': 'Filtrer les champs du formulaire',
    Id: 'identifiant',
    Entrant: 'Participant',
    Settings: 'Paramètres',
    'Label Field is required': 'le texte du champ est obligatoire',
    'Id Field is required': 'cet identificateur de champ est obligatoire',
    'New Form': 'Nouveau Formulaire',
    'Clone Form': 'Clôner un formulaire',
    'Form Field is required': 'Le champ du formulaire doit être indiqué',
    'Category is required': 'la catégorie est obligatoire',
    'Form Field Group': 'Groupe de champs d`édition',
    'Form Field Group Listing': 'Liste des groupes de champs d`édition',
    ParentName: 'Nom du parent',
    GroupName: 'Nom du Groupe  ',
    'An order number is required': "Un numéro d'ordre est requis",
    'An order number must be an integer': 'Un numéro de commande doit être un entier',
    'An order number must be a positive value':
      'Un numéro de commande doit être une valeur positive',
    'Value field is required': 'Le champ valeur est requis',
    'ParentName is required': 'Le nom du parent est requis',
    'GroupName is required': 'Le nom du groupe de champs est requis',
    'Select Group': 'Choisir le Groupe de champs',
    ASCENDING: 'Ascendant',
    DSCENDING: 'Descendant',
    CSV: 'CSV',
    PDF: 'PDF',
    'New Form Field Group': 'Nouveau Groupe de champs ',
    Group: 'Groupe',
    'Add A New Image': 'Ajouter une nouvelle image',
  },
  eventTypes: {
    'Event Type Management': "Gestion des types d'évènements",
    'Add New': "Ajouter un type d'évènement",
    'Event Type Listing': "Liste des types d'évènements",
    'Event Type': "Type d'évènement",
    Actions: 'Actions',
  },
  rallyTypes: {
    'Rally Type Management': 'Gestion des types de compétitions',
    'Add New': 'Ajouter un type de compétition',
    'Rally Type Listing': 'Liste des types de compétitions',
    'Rally Type': 'Type de compétition',
    Actions: 'Actions',
    'Add New Rally Type': 'Ajout du type de rallye/course',
  },
  generalSettings: {
    'General Settings': 'General Settings',
  },
  forms: {
    'Organiser type': "Type d'organisateur",
    'Organiser Contact': 'Organisateur Contact',
    'Select Type': 'Choisir le type',
    Select: 'Choisir',
    'Enquiry Contact': 'Renseignement, contact',
    'Enquiry Email': 'Renseignement, email',
    Company: 'Société',
    Individual: 'Individuel',
    'Company Name': 'Nom de la société',
    'Full Name': 'Nom complet',
    'First Name': 'Prénom',
    'Last Name': 'Nom',
    Email: 'Email',
    Phone: 'Téléphone',
    'Address Line 1': 'Adresse ligne 1',
    Street: 'Rue',
    City: 'Ville / Localité',
    State: 'Région / Département',
    Country: 'Pays',
    Postcode: 'Code postal',
    'Facebook URL': 'Facebook URL',
    'Instagram URL': 'Instagram URL',
    Submit: 'Soumettre',
    Actions: 'Actions',
    Active: 'Actif',
    inactive: 'inactive',
    Delete: 'Supprimer',
    Completed: 'Complet',
    completed: 'complet',
    'Filter Options': 'Filtre, Options',
    'Last login': 'Dernier login',
    Designation: 'Désignation',
    Memo: 'Mémo',
    'Start Date': 'Date de début',
    'End Date': 'Date de fin',
    Import: 'Import',
    Status: 'Etat',
    'Event Name': "Nom de l'évpnement",
    'Event Type': 'Type de compétition',
    'Event Start Date': "Date de début de l'événement",
    'Event End Date': "Date de fin de l'événement",
    'Rally Start Date': 'Date de début du rallye',
    'Rally End Date': 'Date de fin du rallye',
    Venue: 'Lieu',
    'Last Date of Registration': 'Date dernière inscription',
    'Start Date of Registration': "Date de début de l'inscription",
    Website: 'Site Web',
    'Add Event Organiser': "Ajouter un organisateur d'évènements",
    'Add Rally': 'Ajouter un rallye/course',
    'Edit Rally': 'Modifier un rallye/une course',
    Rally: 'Rallye/Course',
    'Manage Forms': 'Gestion des formulaires',
    Participants: 'Participants',
    'Rally Name': 'Nom du rallye/de la course',
    'Rally Type': 'Tyoe de compétition',
    'Maximum No of Participants': 'Nombre maximum de participants',
    'Last Date & Time of Registration': 'Date/Heure de la dernière inscription',
    'Address Line 2': 'Adresse ligne 2',
    'Rally Forms': "Formulaires d'inscription",
    'Racing No': 'Numéro de Course',
    'Event Details': "Détails de l'évènement",
    'Add existing participant': 'Ajouter un participant existant',
    'Add New Participant': 'Ajouter des participants',
    'Add Participant': 'Ajouter participants',
    Pilot: 'Pilote',
    Contact: 'Contact',
    Edit: 'Modifier',
    'Select a rally': 'Sélectionnez un rallye',
    'Website URL': 'Website URL',
    Password: 'Mot de passe',
    Username: 'Utilisateur',
    Deleted: 'Supprimer',
    'Event Organiser': "Organisateur de l'évènement",
    'Enquiry Number': 'Numéro de la recherche',
    'Enroll Now': "S'inscrire maintenant",
    pending: 'en cours',
    'Form Name': 'Nom de forme',
    'New form added successfully': 'Le nouveau formulaire a été ajouté avec succès',
    'Form updated successfully': 'Formulaire mis à jour avec succès',
    'Form Clone Warning':
      'Si vous avez déjà saisi des données sur ce formulaire, elles seront effacées lors de la duplification',
    'Form Submitted Successfully': 'Le formulaire a été soumis avec succès',
    'Form Delete Warning': 'Confirmez-vous vouloir supprimer ce formulaire ?',
    'Form Deleted Successfully': 'Formulaire supprimé avec succès,',
    'Event Forms': "Formulaires d'événement",
    'Uploaded Files': 'Fichiers téléchargés',
    'No Forms Available': '',
    'View Event': "Voir l'événement",
    'Text copied to clipboard': 'Texte copié dans le presse-papier',
    'Rally Cloned Successfully': 'Rallye duplifié avec succès',
    'Clone Form From Previous Rally': "Cloner un formulaire d'un autre rallye",
    'Form Preview': 'Prévisualiser le formulaire',
    Clone: 'Dupliquer',
    'Form cloned successfully': 'Formulaire duplifié avec succès',
    Label: 'Texte',
    Hint: 'Suggestion',
    'Add Participants': 'Ajouter participants',
    Type: 'Type',
    Forms: 'Formulaires',
    'Update Form Field': 'Mettre à jour le champ du formulaire',
    "You can't save an empty form": "You can't save an empty form",
    SUBMIT: 'SOUMETTRE',
    'Add File': 'Ajouter le fichier',
    'View Form': 'Afficher le formulaire',
    'Cannot submit an empty form': 'Impossible de soumettre un formulaire vierge',
    'Pass atleast one editable field. Then save':
      'Définir au moins un champ éditable, puis Mémoriser',
    'Form submission failed': 'La soumission du formulaire a échoué',
    'Upload files under 50mb': 'Taille maximale des fichiers téléchargés 50 Mb',
    'File Upload': 'Télécharger le fichier',
    Block: 'Bloquer',
    Unblock: 'débloquer',
    'All forms': 'Toutes les formes',
    'All Participants': 'Tous les participants',
    EnrollStatus: 'EnrollStatus',
    Upload: 'Télécharger',
    'Form Listing': 'Liste des formulaire',
    Form: 'Formulaire',
    'To adjust tables': 'Pour ajuster les tables',
    'Set headers for each page': 'Définir des en-têtes pour chaque page',
    Order: 'Numéro de commande',
  },
  users: {
    'All Users Listing': 'All Users Listing',
    'All Users': 'All Users',
    Users: 'Participants',
    'Add New Participants': 'Ajouter des participants',
    'Participants Listing': 'Liste des participants',
    Profile: 'Profil',
    'Personal Details': 'Détails personnels',
    'Hello,': 'Bonjour,',
    Welcome: 'Bienvenue',
    'Member since': 'Membre depuis',
    Events: 'Evènements',
    Rallies: 'Rallyes/Courses',
    Participants: 'Participants',
    'Last login on': 'Dernière connection le',
    'Participant Profile': 'Profil du participant',
    'Users Participation Declined': 'Participation des utilisateurs refusée',
    'Organiser Profile': 'Organisateur Profil',
    'Declined by Organiser': "Décliné par l'organisateur",
    'Organiser Imported': 'Importé avec succès',
    'Super Admin': 'Super administrateur',
    'Invite Action': 'Inviter des action',
    'Import Action': 'Action d importation',
    Invite: 'Inviter',
    Restore: 'Restaurer',
    'Participant Imported Successfully': 'Participant Importé avec succès',
    'Organiser Imported Successfully': 'Organisateur Importé avec succès',
    Role: 'Rôle',
  },
  events: {
    Events: 'Evènements',
    Event: 'Evènement',
    'Event Details': "Détails de l'évènement",
    'Add New': 'Ajouter un nouvel évènement',
    'Add new Events': 'Ajouter de nouveaux évènements',
    'Event Place': "Lieu de l'évpnement",
    'Filter Events': 'Filtrer les évènements',
    'Events Listing': 'Liste des évènements',
    'Add Event Organiser': "Ajouter l'organisateur de l'évènement",
    Memo: 'Mémo',
    Website: 'Site Web',
    'Facebook Url': 'Facebook URL',
    'Instagram Url': 'Instagram URL',
    'Event Image': "Image pour l'évènement",
    'Rally Listing': 'Liste des Rallies/Courses',
    'Latest Events': 'Derniers évènements',
    'No events found': 'Aucun évènement trouvé',
    'Manage Forms': 'Gestion des formulaires',
    'Manage Participants': 'Gestion des participants',
    'Fill Up The Below Forms To Participate':
      'Remplir les formulaires ci-dessous pour participere',
    'Filter Participants': 'Filtrer les participants',
    'Export ': 'Exporter',
    'Download List': 'Télécharger la liste',
    Download: 'Télécharger',
    'All Participants': 'Tous les participants',
    'View Details': 'Afficher les détails',
    Rallies: 'Rallyes/Courses',
    'Download PDF': 'Télécharger PDF',
    'SUBMITTED FORMS': 'Formulaires soumis',
    'PARTICIPANT DETAILS': 'Détails du participant',
    'View profile': 'Afficher le profil',
    'Racing Number': 'numéro de course',
    'Clone Form From Previous Rally': "Cloner un formulaire d'un autre rallye",
    'Select event Name': "Choisir le nom de l'évènement",
    'Select form': 'Choisir le formulaire',
    'Form name': 'Nom du formulaire',
    'Source form name': 'Nom du formulaire source',
    'New form name': 'Nouveau nom de formulaire',
    Approved: 'Validé',
    Declined: 'Diminué',
    Approve: 'Approuver',
    Decline: 'Déclin',
    Reinstate: 'Rétablir',
    Cancelled: 'Annulé',
    Completed: 'Complet',
    'Registrations Completed': 'Inscriptions Complet',
    'Registration Closed': 'Inscription clôturée',
    'Registration Progressing': 'Inscription en cours',
    Others: 'Autres',
    'Clone Form': 'Cloner un formulaire',
    Details: 'Détails',
    Forms: 'Formulaires',
    'Organized By': 'Organisé par',
    Invalid: 'Invalide',
    'View details': 'Afficher les détails',
    'Participant Status': 'Statut du participant',
    'In Progress': 'En cours',
    "Participant's Event Status": "Participant's Event Status",
    'Enroll to Participate': "S'inscrire pour participer",
    'Pending approval': 'En attente de validation',
    Active: "C'est actif",
    'Edit form fields': 'Editer le champ du formulaire',
    'Update Event Type': "Modifier le type d'évènement",
    'Update Rally Type': 'Modifier le type de rallye',
    count: 'compter',
    rallies: 'rallyes',
    participants: 'Participants',
    forms: 'forms',
    'Event Cloned Successfully': 'Evénement duplifié avec succès',
    'Event Clone failed': 'Evénement duplifié avec succès',
    eventCreate: 'Evènement créé avec succès',
    eventUpdate: 'Evènement modifié avec succès',
    eventDelete: 'Evènement supprimé avec succès',
    eventCancelled: "L'évènement a été effacé avec succès",
    eventShared: 'Event Shared Successfully',
    userEventExist:
      "Cet utilisateur n'existe pas dans la base de données CompReg. Ajoutez un nouvel utilisateur, puis partagez le evènement",
    Date: 'Date',
    'File Size': 'Taille du fichier',
    'File Name': 'Nom de fichier',
    'File Type': 'Type de fichier',
    'Drag or Drop your files here': 'Faites glisser ou déposez vos fichiers ici',
    'Date should not be before': 'La date ne doit pas être antérieure àu',
    'Date should not be after': 'La date ne doit pas être postérieure àu',
    'Races / Rallies': 'Les courses / Rallyes/Courses',
    'Pass Event': 'Pass Event',
    Registrations: 'Inscriptions',
    ' Open': 'Ouvert',
    Preparation: 'Préparation',
    'Choose different events for Source and Target':
      'Choose different events for Source and Target',
    'Participants invited Successfully': 'Participants invités avec succès',
    'Choose an event!!': 'Choisir un évènement',
    Source: 'Source',
    Destination: 'Destination',
    'Add New Event Type': "Ajouter un nouveau type d'événement",
  },
  addNewForm: {
    'Add New Form': 'Ajouter un noueau formulaire',
    'Clone Form': 'Cloner un  formulaire',
    'Save & Preview': 'Mémoriser et prévisualiser',
    Pilot: 'Pilote',
    'Co-Pilot': 'Copilote',
    Car: 'Voiture',
    'Reco (Reconotering)': 'Reconaissances (Reconotering)',
    'Assistance (Service)': 'Assistance (Service)',
    'Enter Form Name': 'Entrez le nom du formulaire',
    'Edit Form': 'Modifier le formulaire',
    'Update & Preview': 'Mettre à jour et visualiser',
    Others: 'Autres',
    Entrant: 'Participant',
    FormHeader: '',
    Organiser: 'Organisateurs',
    Participant: 'Participant',
    Rally: 'Rallye',
    Organisers: '',
    'Preview as Participant': 'Aperçu en tant que participant',
  },
  signUp: {
    'First Name': 'Prénom',
    Login: 'Connexion',
    'Last Name': 'Nom',
    username: 'utilisateur',
    USERNAME: 'UTILISATEUR',
    PASSWORD: 'MOT DE PASSE',
    'Forgot password?': 'Mot de passe oublié ?',
    'Don’t have an account?': "Vous n'avez pas encore de compte ?",
    'Sign up': 'Inscription',
    'RESET PASSWORD': 'RÉINITIALISER LE MOT DE PASSE',
    'SEND RESET PASSWORD LINK': 'ENVOYER UN LIEN POUR RÉINITIALISER LE MOT DE PASSE',
    'Sign in': 'Prochain',
    'Change Username': "Modifier l'utilisateur",
    'Change Password': 'Modifier le mot de passe',
    Signout: 'Quitter',
    'Username*': 'utilisateur*',
    Username: 'utilisateur',
    'Old Password': 'Ancien mot de passe',
    'New Password': 'Nouveau mot de passe',
    'New Password*': 'Nouveau mot de passe*',
    'New Password Confirmation': 'confirmation du mot de passe',
    Register: "S'enregistrer",
    Telephone: 'Téléphone',
    'Confirm Password*': 'Confirmez le mot de passe*',
    'Confirm Password': 'Confirmez le mot de passe',
    Back: 'Retour',
    Next: 'Prochain',
    'ADD SOCIAL MEDIA': 'Ajouter les réseaux sociaux',
    'SKIP & COMPLETE REGISTRATION': "Passer et terminer l'enregistrement",
    'SAVE & Complete registration': "Sauver et terminer l'enregistrement",
    'Please change username': "Merci de changer le code de l'utilisateur",
    'Old password does not match our records': "L'ancien mot de passe n'est pas correct",
    'Passwords are same': 'Mots de passe identique',
    'Password Updated Successfully, Please Login again to Continue':
      'Le mots de passe a été modifié avec succès, veuiller vous ré-identifier',
    'Switch Profile': 'Changer de profil',
    organizer: 'Organisateur',
    eventOrganizer: "Organisateur de l'événement",
    Participant: 'Participant',
  },
  others: {
    'Add pin': 'Ajouter une épingle',
    'Search…': 'Rechercher…',
    'Upload CSV': 'Téléverser un fichier CSV',
    'Sample CSV': 'Exemple de fichier CSV',
    'Export CSV': 'Exporter un fichier CSV',
    Yes: 'Oui',
    Cancel: 'Annuler',
    Share: 'Partager',
    'Copy Link': 'Copier le lien',
    'Enter username': "Entrer l'utilisateur",
    Invite: 'Inviter',
    Copy: 'Copie',
    Optional: 'Optionnel',
    'Event Status': 'Statut du evènement',
    by: 'par',
    'Import CSV': 'Import un fichier CSV',
    'No items found': 'Aucun élément trouvé',
    from: 'à partir de',
    to: 'pour',
    count: 'compter',
    page: 'page',
    'View Rallys': 'Voir les rallyes',
    'View Rally': 'Voir les rallye',
    'No item found': 'Aucun élément trouvé',
    'Nothing to show': 'Rien à montrer',
    'SELECT ALL': 'Tout sélectionner',
    'Select All': 'Tout sélectionner',
    'Unselect All': 'Tout déselectionner',
    'DESELECT ALL': 'Tout déselectionner',
    'Rows per page': 'Lignes par page',
    Change: 'Modifier',
    'Something went wrong': "Une erreur s'est produite",
    'Please login to view the forms': 'Please login to view the forms',
    'Table Reports Listing': 'Liste des rapports de tableau',
    'Resource Name': 'Nom de la ressource',
    'Report Name': 'Nom du rapport',
    'Table Reports': 'Rapports de tableau',
    'Save as Table report': 'Rapport Enregistrer en tant que tableau',
    'Report saved successfully': 'Rapport enregistré avec succès',
    'Report updated successfully': 'Rapport mis à jour avec succès',
    'Choose File': 'Choisir le fichier',
    'Aspect ratio': "Ratio d'aspect",
    'Report Name field is required': 'Le champ Nom du rapport est obligatoire',
    'Filter all': 'Tout filtrer',
    Filter: 'Filtre',
    "The participant has been cancelled.Can't be share the rallys":
      'Le participant a été annulé. Ne peut pas partager les rallyes',
    Deselect: 'Désélectionner',
    items: 'éléments',
    'Save Changes': 'Sauver',
  },
  profile: {
    'Are you sure you want to sign out?': 'Confirmer que vous voulez quitter ?',
  },
  participant: {
    'Participant Approved': 'Participant accepté.',
    'Participant Declined': 'Participant refusé',
    'View Registration': "Afficher l'inscription",
    created:
      "Le participant créé et ses identifiants sont envoyés sur l'email du participant",
    deleted: 'User deleted successfully',
    updated: 'User updated successfully',
    participantCreatedSuccessfuly: 'Le participant a été créé avec succès',
    'Participant Name': 'Nom du participant',
    'Select Rally': 'Sélectionnez Rallye',
    Save: 'Sauvegarder',
  },
  popup: {
    Yes: 'Oui',
    Cancel: 'Annuler',
    'Are you sure you want to delete the participant?':
      'Voulez-vous vraiment supprimer le participant?',
    'You have been enrolled successfully. Please start filling the event forms':
      "Vous avez été inscrit avec succès. S'il vous plaît commencer à remplir le formulaire d'événement",
    'Are you sure you want to cancel the participant?':
      'Voulez-vous vraiment annuler le participant?',
    'Are you sure you want to approve the participant?':
      'Êtes-vous sûr de vouloir approuver le participant?',
    'Are you sure you want to unblock the organiser?':
      "Voulez-vous vraiment débloquer l'organisateur?",
    'Are you sure you want to block the organiser?':
      "Voulez-vous vraiment bloquer l'organisateur?",
    'Are you sure you want to delete the event?':
      "Voulez-vous vraiment supprimer l'événement?",
    'This is an irreversible action.': "C'est une action irréversible.",
    'Are you sure you want to cancel the event?':
      "Voulez-vous vraiment annuler l'événement?",
    'Do you want to delete ?': 'Voulez-vous supprimer ?',
    'Are you sure you want to delete the organiser?':
      "Voulez-vous vraiment supprimer l'organisateur?",
    'Are you sure you want to delete the event organiser?':
      "Êtes-vous sûr de vouloir supprimer l'organisateur de l'événement?",
    '+Add New Contact': '+Ajouter un contact',
    'Are you sure you want to cancel the rally?':
      "Confirmez-vous l'annulation de ce rallye ?",
    'Are you sure you want to delete the rally?':
      'Confirmez-vous la suppression de ce rallye ?',
    'There is a mismatch between the event date and rally date. Please manually change the rally date':
      "Il y a un décalage entre la date de l'événement et la date du rallye. Veuillez modifier manuellement la date du rallye",
    'Participant created and login credentials are sent to the participant email':
      "Le participant créé et ses identifiants sont envoyés sur l'email du participant",
    'You cannot participate in this event either your are super admin or organiser':
      "les comptes de l'administrateur ou de l'organisateur ne peuvent être utilisés par des participants",
    'Unsaved changes will be discarded':
      'les modifications non enregistrées seront ignorées',
    'Are you sure you want to change the block status?':
      'Voulez-vous vraiment modifier létat du blocage ?',
    'Are you sure you want to delete the form fields?':
      'Voulez-vous vraiment supprimer les champs du formulaire ?',
    'You cannot participate in this rally / event':
      'Vous ne pouvez pas participer à ce rallye / événement',
    'This event organizer is assigned to an event. Are you sure you want to delete?':
      "Cet organisateur d'événements est affecté à un événement. Etes-vous sûr que vous voulez supprimer?",
    'It will take time, continue in background':
      'Cela prendra du temps, continuer en arrière-plan',
    'Restore event': "Restaurer l'événement",
    "It's an approved participant.": "C'est un participant agréé.",
    'Are you sure you want to delete the Form Field Group?':
      'Voulez-vous vraiment supprimer le groupe de champs de formulaire ?',
    'You have been enrolled successfully. Please see the event details from participant dashboard':
      "Vous avez été inscrit avec succès. Veuillez consulter les détails de l'événement sur le tableau de bord des participants",
    eventRegistrationClosed: "L'inscription à l'événement a été clôturée",
    eventRegistrationNotOpen: "L'inscription à l'événement n'est pas encore ouverte",
    eventFinished: "L'événement s'est terminé avec succès",
    eventCancelled: "L'événement a été annulé",
    'Backup event': 'Événement de sauvegarde',
    'Backup Organiser': 'Backup organisieren',
    'Are you sure you want to update Rally details?':
      'Etes-vous sûr de vouloir mettre à jour les détails du rallye ?',
  },

  validationErrors: {
    'First name is Required': 'Le prénom est obligatoire',
    'Username is Required': 'le code utilisateur est obligatoire',
    'Password is Required': 'le mot de passe est obligatoire',
    'Confirm Password is Required': 'la confirmation du mot de passe est obligatoire',
    'Invalid email': 'Email invalide',
    'Type is Required': 'Le type est requis',
    'Company name is Required': 'Le nom de la société est requis',
    'Event name is Required': "Le nom de l'évènement est requis",
    'Start Date Time is Required': "L'heure de la date de début est requise",
    'End Date Time is Required': "L'heure de la date de fin est requise",
    'Start Date of Registration is Required':
      'La date de début de l’inscription est requise',
    'Last Date of Registration is Required': "La dernière date d'inscription est requise",
    'Organiser is Required': "L'organisateur est requis",
    'Rally Type is Required': 'le type de rallye est obligatoire',
    'Rally name is Required': 'Le nom du rallye est obligatoire',
    'Form name is required': 'Nom du formulaire est obligatoire',
    'Event Type is Required': "Le type d'évènement est obligatoire",
    'Username already exist': 'Code utilisateur déjà existant',
    'Too Short!': 'Trop court!',
    'Too Long!': 'Too Long!',
    'choose total file size lessthan':
      'choisissez la taille totale du fichier inférieure à',
    'Choose individual file size less than':
      'choisissez une taille de fichier inférieure à',
    'Passwords must match': 'les mots de passe doivent être identiques',
    'Admin email is required': 'Email administrateur est obligatoire',
    'First name is not allowed to be empty': 'Le prénom ne peut être vide',
    'The firstname cannot include leading and trailing spaces':
      "Le prénom ne peut pas inclure d'espaces de début et de fin",
    'Status Type is Required': 'Type de statut requis',
    'Source event is required': "L'évènement source est requis",
    'Target event is required': "L'évènement destination est requis",
    'End date cant be before Start date':
      'La date de fin ne peut être avant la date de début',
    'Rally name is too Short!': 'Le nom du rallye/course est trop court',
    'file is not in the file size limit, Pleas choose less than':
      "le fichier n'est pas dans la limite de taille de fichier, veuillez choisir moins de",
    'total file size, Pleas choose  less than':
      'taille totale du fichier, veuillez choisir moins de',
    'The username cannot include leading and trailing spaces':
      "Le nom d'utilisateur ne peut pas inclure d'espaces de début et de fin",
    'The password cannot include leading and trailing spaces':
      "Le mot de passe ne peut pas inclure d'espaces de début et de fin",
    'The Email cannot include leading and trailing spaces':
      "L'e-mail ne peut pas inclure d'espaces de début et de fin",
    'use at least one alphabet': 'utiliser au moins un alphabet',
    'The lastname cannot include leading and trailing spaces':
      "Le nom de famille ne peut pas inclure d'espaces de début et de fin",
    'Phone Number cannot include leading and trailing spaces':
      "Le numéro de téléphone ne peut pas inclure d'espaces de début et de fin",
    'Invalid Phone Number': 'Numéro de téléphone invalide',
    'The Email cannot include uppercase alphabets':
      "L'e-mail ne peut pas contenir d'alphabets majuscules",
    'The username cannot include uppercase alphabets':
      "Le nom d'utilisateur ne peut pas inclure d'alphabets majuscules",
    'Select a participant': 'Sélectionnez un participant',
    'Invalid Status': 'Statut invalide',
    'Event name is too Short!': "Le nom de l'événement est trop court !",
    'choose a valid event status': "choisissez un statut d'événement valide",
  },
  filterForms: {
    'Filter Forms': 'Filter Forms',
  },
  toast: {
    'Start Date of Registration must be less than or equal to Start Date':
      "La date de début de l'inscription doit être inférieure ou égale à la date de début",
    'Last Date of Registration must be greater than or equal to Start Date of Registration':
      "La dernière date d'inscription doit être supérieure ou égale à la date de début de l'inscription.",
    'Last Date of Registration must be less than or equal to End Date':
      "La dernière date d'inscription doit être inférieure ou égale à la date de fin",
    'End Date must be greater than or equal to Start Date':
      'La date de fin doit être postérieure à la date de début (ou égale)',
    'Cant submit empty email draft':
      'Pas possible de soumettre un brouillon de couriel vide',
    'Enroll Decline, This rally is Cancelled':
      'Inscription refusée, ce rallye est annulé',
    'Enroll Decline, This rally is completed':
      'Inscription refusée, Ce rallye est terminé',
    "This user has been blocked.Can't be share the events":
      'cet utilisateur est verouillé, impossible de partager les évènements',
    'Denied for Cancelled Event ': 'Refusé pour événement annulé',
    'Language Changed Successfully': 'Langue changée avec succès',
    'Organiser Contact created successfully, credentials will email to you':
      "Le contact de l'organisateur a été créé avec succès, les informations d'identification vous seront envoyées par e-mail",
    'Organiser Contact updated successfully':
      "Le contact de l'organisateur a été mis à jour avec succès",
    'Username already exist': 'Code utilisateur déjà existant',
    'Form Field Group Added Successfully':
      'Groupe de champs de formulaire ajouté avec succès',
    'Sucessfully updated': 'Mise à jour réussie',
    'Enroll in Event First': "Inscrivez-vous d'abord à l'événement",
    'rally start date must be after or same as the event start date':
      "la date de début du rallye doit être postérieure ou identique à la date de début de l'épreuve",
    'rally end date must be before or same as the event end date':
      "la date de fin du rallye doit être antérieure ou identique à la date de fin de l'événement",
    'Past date is not allowed': "La date passée n'est pas autorisée",
    'restore failed': 'la restauration a échoué',
    'This participant active in event': "Ce participant est actif dans l'événement.",
    'This participant may active in event':
      "Ce participant peut être actif dans l'événement",
    '"email" must be a valid email': 'L e-mail doit être un e-mail valide',
    'Unfortunately, the cancelled cannot be edited':
      "Malheureusement, l'annulation ne peut pas être modifiée",
    'You were declined by Organiser': "Vous avez été refusé par l'organisateur",
    'Your approval is in pending': 'Votre approbation est en attente',
    'View event details from participant dashboard':
      "Afficher les détails de l'événement à partir du tableau de bord des participants",
    "It's not possible to delete this organiser since there are events to be organised":
      "Il n'est pas possible de supprimer cet organisateur car il y a des événements à organiser",
    'file is not acceptable': "le fichier n'est pas acceptable",
    "The chosen event's start date has been reached":
      "La date de début de l'événement choisi a été atteinte",
    "The chosen event's registration start date has not yet been reached":
      "La date de début des inscriptions à l'événement choisi n'est pas encore atteinte",
    "The chosen event's registration end date has been reached":
      "La date de fin d'inscription pour l'événement choisi est atteinte",
    "The chosen event's registration deadline has not yet been reached":
      "La date limite d'inscription à l'événement choisi n'est pas encore atteinte",
    "The chosen event's start date has not yet been reached":
      "La date de début de l'événement choisi n'a pas encore été atteinte",
    "The chosen event's end date has been reached":
      "La date de fin de l'événement choisi a été atteinte",
    "The chosen event's end date has not yet been reached":
      "La date de fin de l'événement choisi n'est pas encore atteinte",
    'Are you sure you want to cancel the event?':
      "Etes-vous sûr de vouloir annuler l'événement?",
    'You do not have the authorization to access this':
      "Vous n'avez pas l'autorisation pour y accéder",
    'Please choose valid event status': "Veuillez choisir un statut d'événement valide",
    'The event will be cancelled upon submission':
      "L'événement sera annulé lors de la soumission",
  },
  user: {
    loginMessage: 'Login avec succès',
    signup: 'Utilisateur enregistré avec succès',
    alreadyExist: 'Cet utilisateur existe déjà',
    passWordResetLink:
      'un email contenant un lien pour changer le mot de passe a été envoyé',
    passwordUpdate:
      'Le mots de passe a été modifié avec succès, veuiller vous ré-identifier',
    userNotfound: 'Aucun utilisateur trouvé',
    userNameNotExist: "Un utilisateur avec ce code utilisateur n'existe pas",
    userNameUpdated: 'Code utilisateur mis à jour',
    userNameExist: 'Cet utilisateur existe déjà',
    passwordChanged: 'Code utilisateur déjà existant',
    userDeleted: "L'utilisateur a été supprimé avec succès",
    userUpdated: "L'utilisateur a été mis à jour avec succès",
  },
  rally: {
    rallyCreate: 'le rallye a été créé avec succès',
    rallyUpdate: 'Le rallye a été modifié avec succès',
    rallyDelete: 'Le rallye a été supprimé avec succès',
    rallyCancelled: 'Le rallye a été annulé avec succès',
    rallyShared: 'Le rallye a été partagé avec succès',
    userExistRally:
      "Cet utilisateur n'existe pas dans la base de données CompReg. Ajoutez un nouvel utilisateur, puis partagez le rallye",
    rallyCloned: 'Rallye duplifié avec succès',
    'Select atleast one Race': 'Choisir au moins une course',
    'Select Active participant': 'Choisir le participant actif',
  },
  eventParticipant: {
    participantApproved: 'Statut du participant mis à jour avec succès',
    formSubmitted: 'Le formulaire a été soumis avec succès',
    formUpdated: 'Le formulaire a été mis à jour avec succès',
    racingNumberUpdate: 'Le numéro de course a été modifié',
    folderNumberUpdate: 'Le numéro du dossier été modifié.',
  },
  eventForm: {
    formUpdated: 'Formulaire ajouté avec succès',
    formCloned: 'Formulaire duplifié avec succès',
    formDeleted: 'Formulaire supprimé avec succès',
  },
  formField: {
    alreadyExist: 'Cet utilisateur existe déjà',
    created: 'Le champ du formulaire a été créé avec succès',
    updated: 'le cha,p du formulaire a été mis à jour avec succès',
    deleted: 'Le champ du formulaire a été supprimé avec succès',
  },
  eventType: {
    created: "Le type d'évènement a été créé avec succès",
    updated: "Le type d'évènement a été modifié avec succès",
    deleted: "Le type d'évènement a été supprimé avec succès",
    existingTypeError: "Le type d'événement existe déjà dans l'application.",
  },
  rallyType: {
    created: 'Le type de rallye a été créé avec succès',
    updated: 'Le type de rallye a été miodifié avec succès',
    deleted: 'Le type de rallye a été supprimé avec succès',
    existingTypeError: "Le type de rallye existe déjà dans l'application.",
  },
  other: {
    error: "Une erreur s'est produite",
    'Sort By': 'Trier par',
    'Sort Order': 'Ordre de tri',
    'Download Type': 'Type de téléchargement',
    orientation: 'Orientation',
  },
  mail: {
    signup: 'Bienvenue à COMPREG',
    reset: 'Réinitialisation du mot de passe',
    organizer: 'COMPREG Nouveau compte pour un Organisateur',
    event: "Invitation à l'évènement",
    participant: 'COMPREG Nouveau compte pour un participant',
  },
  fields: {
    'Pilot First Name': 'Pilote, nom',
    'Pilot Nationality': 'Pilote, nationalité',
    'Pilot Birth Date': 'Pilote,date de naissance',
    'Pilot Birth Place': 'Pilote, lieu de naissance',
    'Pilot Emergency Contact': "Pilote, numéro en cas d'urgence",
    'Pilot Drivers License Number': 'Pilote, numéro du permis de conduire',
    'pilot_drivers_license_date.Pilot Drivers License Date': 'Pilot Drivers License Date',
    'Pilot Drivers License Place': 'Pilote, permis de conduire obtenu à',
    'Pilot Drivers License Country': 'Pilote, pays de la licence',
    'Pilot Passport Number': 'Pilote, numéro de passeport',
    'Pilot Passport Delivery Date': 'Pilote, date du passeport',
    'Pilot Passport Delivery Place': 'Pilote, lieu du passeport',
    'Pilot License': 'Pilote, numéro de licence',
    'Pilot Team': 'Pilote Team',
    'Pilot Clothing Size': "Pilote Taille d'habits",
    'Pilot Signature': 'Pilote Signature',
    Copilot: 'Copilote',
    'Copilot Nationality': 'Copilote, nationalité',
    'Copilot Birth Date': 'Copilote, date de naissance',
    'Copilot Birth Place': 'Copilote, lieu de naissance',
    'Copilot Emergency Contact': "Copilote, numéro en cas d'urgence",
    'Copilot Drivers License Number': 'Copilote, numéro du permis de conduire',
    'Copilot Drivers LicenseDate': 'Copilote, date du permis de conduire',
    'Copilot Drivers License Place': 'Copilote, lieu du permis de conduire',
    'Copilot Drivers License Country': 'Copilote, Pays du permis de conduire',
    'Copilot Passport Number': 'Copilote, numéro du passeport',
    'Copilot Passport Delivery Date': 'Copilote, date du passeport',
    'Copilot Passport Delivery Place': 'Copilote, lieu du passeport',
    'Copilot License': 'Copilote, numéro de licence',
    'Copilot Team': 'Copilot Team',
    'Copilot Clothing Size': "Copilote Taille d'habits",
    'Copilot Signature': 'Copilot Signature',
    'Car Brand': 'Voiture, marque',
    'Car Model': 'Voiture, modèle',
    'Car Group': 'Voiture, groupe',
    'Car Class': 'Voiture classe',
    'Car Registration': 'Voiture, immatriculation',
    'Car Displacement': 'Voiture, Cylindrée [cm3]',
    'Car Homologation  Number': 'Car Homologation Number',
    'Car Historic Technical Passport': 'Voiture, Numéro du PTH',
    'Car Owner Name': 'Voiture, nom du propriétaire',
    'Car Owner Loan Aprroval Date': 'Voiture, date du prêt',
    'Car Technical Check OK': 'Voiture Vérifications OK',
    'Car Chassis Number': 'Car Chasis Number',
    'Car Engine Number': 'Car Engine Number',
    'Car Color': 'Car Color',
    'Car Picture': 'Car Picture',
    'Reco Car Brand': 'Reconnaissances, marque de la voiture',
    'Reco Car Model': 'Reconnaissances, modèle de la voiture',
    'Reco Car Registration': "Reconaissance, Numéro d'identification",
    'Reco Car Control Plate': 'Reconnaissances, immatriculation de la voiture',
    'Reco Car Color': 'Reconnaissances, couleur de la voiture',
    'Assistance Contact In Charge': 'Assistance : Contact responsible',
    'Assistance Requirements Width': 'Assistance : Surface Largeur',
    'Assistance Requirements Length': 'Assistance : Surface Longueur',
    'Assistance Requirements Height': 'Assistance : Surface Hauteur',
    'Assistance Memo': 'Asssistance : Mémo',
    Heading: 'Entête',
    Paragraph: 'Paragraphe',
    Address: 'Adresse',
    City: 'Ville / Localité',
    Country: 'Pays',
    'Date and place of birth': 'Date and place of birth',
    Email: 'Email',
    Name: 'Nom',
    Nationality: 'Nationalit',
    'Phone Number': 'Phone Number',
    'Postal Code': 'Postal Code',
    Surname: 'Surname',
    'First Name': 'First Name',
    'Passport Number': 'Numéro de passeport',
    Company: 'Société',
    Title: 'Titre',
    Mobile: 'Mobile',
    'Car Period': 'Voiture Période',
    'Car Year': 'Voiture Année',
    'Generic Checkbox': 'Generice Checkbox',
    'Generic Date': 'Generice Date',
    'Pilot Licence Number': 'Pilote, numéro de licence',
    'Pilot Licence Scan': 'Pilote, scan de la licence',
    'Pilot Memo': 'Pilote mémo',
    'Copilot City': 'Copilote Localité',
    'Copilot Country': 'Copilote Pays',
    'Copilot Email': 'Copilote Email',
    'Copilot Fixed': 'Copilote téléphone fixe',
    'Pilot Sex': 'Pilote Sexe',
    'Pilot Email': 'Pilote Email',
    'Generic Amount': 'Montant générique',
    'Copilot Sex': 'Copilote Sexe',
    'Pilot Company': 'Pilote Société',
    'Pilot Title': 'Pilote Titre',
    'Pilot Address': 'Pilote Adresse',
    'Pilot Address 2': 'Pilote Adresse 2',
    'Pilot City': 'Pilote Localité',
    'Pilot Country': 'Pilote Pays',
    'Pilot Fixed': 'Pilote Téléphone fixe',
    'Copilot Picture': 'Copilote photo',
    'Copilot Postal Code': 'Copilote Code Postal',
    'Copilot Web': 'Copliote Site web',
    'Pilot Mobile': 'Pilote Mobile',
    'Pilot Picture': 'Pilote Photo',
    'Pilot Postal Code': 'Pilote Code postal',
    'Pilot Date Entry Country': "Pilote Date d'entrée",
    'Pilot Emergency Email': "Pilote email en cas d'urgence",
    'Pilot Emergency Phone': "Pilote téléphone en cas d'urgence",
    'Pilot Passport Scan': 'Pilote Scan du passeport',
    'Pilot Place Entry Country': "Pilote lieu d'entrée dans le pays",
    'Pilot Drivers Licence Country': 'Pilote nationalité du permis de conduire',
    'Pilot Drivers Licence Date': 'Pilote date du permis de conduire',
    'Pilot Drivers Licence Number': 'Pilote numéro du permis de conduire',
    'Pilot Drivers Licence Place': 'Piulote lieu du permis de conduire',
    'Pilot Drivers Licence Scan': 'Pilote scan du permis de conduire',
    'Copilot Passport Scan': 'Copilote scan du passeport',
    'Copilot Place Entry Country': "Copilote lieu d'entrée dans le pays",
    'Copilot Drivers Licence Country': 'Copilote nationalité du permis de conduire',
    'Copilot Drivers Licence Date': 'Copilote date du permis de conduire',
    'Copilot Drivers Licence Number': 'Copilote numéro du permis de conduire',
    'Copilot Drivers Licence Place': 'Copliote lieu du permis de conduire',
    'Copilot Drivers Licence Scan': 'Copilote scan du permis de conduire',
    'Copilot Licence Number': 'Copilote numéro de licence',
    'Copilot Licence Scan': 'Copilote scan de la licence',
    'Copilot Licence Type': 'Copilote type de licence',
    'Entrant Sex': 'Concurrent Sexe',
    'Entrant Title': 'Concurrent titre',
    'Entrant First Name': 'Concurrent prénom',
    'Entrant Last Name': 'Concurrent nom',
    'Entrant Company': 'Concurrent société',
    'Entrant Address': 'Concurrent adresse',
    'Entrant Address 2': 'Concurrent adresse 2',
    'Entrant Postal Code': 'Concurrent Code postal',
    'Entrant City': 'Concurrent Localité',
    'Entrant Country': 'Concurrent Pays',
    'Entrant Mobile': 'Concurrent Mobile',
    'Entrant Fixed': 'Concurrent Téléphone fixe',
    'Entrant Email': 'Concurrent Email',
    'Entrant Web': 'Concurrent Web',
    'Entrant Picture': 'Concurrent Photo',
    'Entrant Passport Number': 'Concurrent Passeport numéro',
    'Entrant Passport Delivery Date': 'Concurrent Passeport Date de délivrance',
    'Entrant Passport Delivery Place': 'Concurrent Passeport Lieu de délivrance',
    'Entrant Passport Scan': 'Concurrent Passeport Scan',
    'Entrant Emergency Contact': "Concurrent Contact en cas d'urgence",
    'Entrant Emergency Phone': "Concurrent Téléphone en cas d'urgence",
    'Entrant Emergency Email': "Concurrent Email en cas d'urgence",
    'Entrant Place Entry Country': "Concurrent Lieu d'entrée dans le pays",
    'Entrant Date Entry Country': "Concurrent Date d'entrée dans le pays",
    'Entrant Nationality': 'Concurrent Nationalité',
    'Entrant Birth Date': 'Concurrent Date de naissance',
    'Entrant Birth Place': 'Concurrent Lieu de naissance',
    'Entrant Signature': 'Concurrent Scan de la signature',
    'Entrant Drivers Licence Scan': 'Concurrent Scan du permis de conduire',
    'Entrant Drivers Licence Number': 'Concurrent Numéro du permis de conduire',
    'Entrant Drivers Licence Date': 'Concurrent Date du permis de conduire',
    'Entrant Drivers Licence Place': 'Concurrent Lieu du permis de conduire',
    'Entrant Drivers Licence Country': 'Concurrent Pays du permis de conduire',
    'Entrant Licence Scan': 'Concurrent Scan de la licence',
    'Entrant Licence Number': 'Concurrent Numéro de licence',
    'Entrant Licence Type': 'Concurrent Type de licence',
    'Entrant Clothing Size': 'Concurrent Taille de vêtements',
    'Entrant Team': 'Concurrent Team',
    'Entrant Memo': 'Concurrent Mémo',
    'Rally Event Name': "Course : Nom de l'évènement",
    'Rally Name': 'Course : Nom',
    'Rally Category': 'Course : Catégorie',
    'Rally Logo': 'Course : Logo',
    'Rally Venue': 'Course : Lieu',
    'Rally Start Date': 'Course : Date de début',
    'Rally End Date': 'Course : Date de fin',
    'Rally Timing Web': 'Course : Adresse web des résultats en direct',
    'Rally Timing Organisation': 'Course : Nom des chronométreurs',
    'Rally Timing Contact Person Sex': 'Course : Personne de contact : Sexe',
    'Rally Timing Contact Person Title': 'Course : Personne de contact : Titre',
    'Rally Timing Contact Person First Name': 'Course : Personne de contact : Prénom',
    'Rally Timing Contact Person Last Name': 'Course : Personne de contact : Nom',
    'Rally Timing Contact Person Company': 'Course : Personne de contact : Société',
    'Rally Timing Contact Person Mobile': 'Course : Personne de contact : Mobile',
    'Rally Timing Contact Person Address': 'Course : Personne de contact : Adresse',
    'Rally Timing Contact Person Address 2': 'Course : Personne de contact : Adresse 2',
    'Rally Timing Contact Person Postal Code':
      'Course : Personne de contact : Code Postal',
    'Rally Timing Contact Person City': 'Course : Personne de contact : Localité',
    'Rally Timing Contact Person Country': 'Course : Personne de contact : Pays',
    'Rally Timing Contact Person Fixed': 'Course : Personne de contact : Téléphone fixe',
    'Rally Timing Contact Person Web': 'Course : Personne de contact : Web',
    'Rally Timing Contact Person Picture': 'Course : Personne de contact : Photo',
    'Rally Timing Contact Person Passport Number':
      'Course : Personne de contact : Numéro du passeport',
    'Rally Timing Contact Person Email': 'Course : Personne de contact : Email',
    'Rally Timing Contact Person Passport Delivery Date':
      "Course : Personne de contact : Passeport, Date d'émission",
    'Rally Timing Contact Person Passport Delivery Place':
      "Course : Personne de contact : Passeport, Lieu d'émission",
    'Rally Timing Contact Person Passport Scan':
      'Course : Personne de contact : Passeport, Scan',
    'Rally Timing Contact Person Emergency Contact':
      "Course : Personne de contact : Contact en cas d'urgence",
    'Rally Timing Contact Person Emergency Phone':
      "Course : Personne de contact : Numéro de téléphone en cas d'urgence",
    'Rally Timing Contact Person Emergency Email':
      "Course : Personne de contact : Email en cas d'urgence",
    'Rally Timing Contact Person Place Entry Country':
      "Course : Personne de contact : Lieu d'entrée dans le pays",
    'Rally Timing Contact Person Date Entry Country':
      "Course : Personne de contact : Date d'entrée dans le pays",
    'Rally Timing Contact Person Nationality':
      'Course : Personne de contact : Nationalité',
    'Rally Timing Contact Person Birth Date':
      'Course : Personne de contact : Date de naissance',
    'Rally Timing Contact Person Signature': 'Course : Personne de contact : Signature',
    'Rally Timing Contact Person Drivers Licence Scan':
      'Course : Personne de contact : Scan du permis de conduire',
    'Rally Timing Contact Person Birth Place':
      'Course : Personne de contact : Lieu de naissance',
    'Rally Timing Contact Person Drivers Licence Number':
      'Course : Personne de contact : Permis de conduire, numéro',
    'Rally Timing Contact Person Drivers Licence Date':
      'Course : Personne de contact : Permis de conduire, date',
    'Rally Timing Contact Person Drivers Licence Place':
      'Course : Personne de contact : Permis de conduire, lieu',
    'Rally Timing Contact Person Drivers Licence Country':
      'Course : Personne de contact : Permis de conduire, pays',
    'Rally Timing Contact Person Licence Scan':
      'Course : Personne de contact : Licence, Scan',
    'Rally Timing Contact Person Licence Number':
      'Course : Personne de contact : Licence, Numéro',
    'Rally Timing Contact Person Licence Type':
      'Course : Personne de contact : Licence, Type',
    'Rally Timing Contact Person Clothing Size':
      'Course : Personne de contact : Taille de vêtements.',
    'Rally Timing Contact Person Team': 'Course : Personne de contact : Team',
    'Rally Timing Contact Person Memo': 'Course : Personne de contact : Mémo',
    'Reco Memo': 'Reconaissances : Mémo',
    'Assistance Contact Incharge Sex': 'Assistance : responsable : Sexe',
    'Assistance Contact Incharge Title': 'Assistance : responsable : Titre',
    'Assistance Contact Incharge First Name': 'Assistance : responsable : Prénom',
    'Assistance Contact Incharge Last Name': 'Assistance : responsable : Nom',
    'Assistance Contact Incharge Company': 'Assistance : responsable : Société',
    'Assistance Contact Incharge Address': 'Assistance : responsable : Adresse',
    'Assistance Contact Incharge Address 2': 'Assistance : responsable : Adresse 2',
    'Assistance Contact Incharge Postal Code': 'Assistance : responsable : Code Postal',
    'Assistance Contact Incharge City': 'Assistance : responsable : Localité',
    'Assistance Contact Incharge Country': 'Assistance : responsable : Pays',
    'Assistance Contact Incharge Mobile': 'Assistance : responsable : Mobile',
    'Assistance Contact Incharge Fixed': 'Assistance : responsable : Téléphone fixe',
    'Assistance Contact Incharge Email': 'Assistance : responsable : Email',
    'Assistance Contact Incharge Web': 'Assistance : responsable : Web',
    'Assistance Contact Incharge Picture': 'Assistance : responsable : Photo',
    'Assistance Contact Incharge Passport Number':
      'Assistance : responsable : Numéro du passeport',
    'Assistance Contact Incharge Passport Delivery Date':
      "Assistance : responsable : Passeport, Date d'émission",
    'Assistance Contact Incharge Passport Delivery Place':
      "Assistance : responsable : Passeport, Lieu d'émission",
    'Assistance Contact Incharge Passport Scan':
      'Assistance : responsable : Passeport, Scan',
    'Assistance Contact Incharge Emergency Contact':
      "Assistance : responsable : Contact en cas d'urgence",
    'Assistance Contact Incharge Emergency Phone':
      "Assistance : responsable : Numéro de téléphone en cas d'urgence",
    'Assistance Contact Incharge Emergency Email':
      "Assistance : responsable : Email en cas d'urgence",
    'Assistance Contact Incharge Nationality': 'Assistance : responsable : Nationalité',
    'Assistance Contact Incharge Place Entry Country':
      "Assistance : responsable : Lieu d'entrée dans le pays",
    'Assistance Contact Incharge Date Entry Country':
      "Assistance : responsable : Date d'entrée dans le pays",
    'Assistance Contact Incharge Birth Date':
      'Assistance : responsable : Date de naissance',
    'Assistance Contact Incharge Birth Place':
      'Assistance : responsable : Lieu de naissance',
    'Assistance Contact Incharge Signature': 'Assistance : responsable : Signature',
    'Assistance Contact Incharge Drivers Licence Scan':
      'Assistance : responsable : Scan du permis de conduire',
    'Assistance Contact Incharge Drivers Licence Number':
      'Assistance : responsable : Permis de conduire, numéro',
    'Assistance Contact Incharge Drivers Licence Date':
      'Assistance : responsable : Permis de conduire, date',
    'Assistance Contact Incharge Drivers Licence Place':
      'Assistance : responsable : Permis de conduire, lieu',
    'Assistance Contact Incharge Drivers Licence Country':
      'Assistance : responsable : Permis de conduire, pays',
    'Assistance Contact Incharge Licence Scan':
      'Assistance : responsable : Licence, Scan',
    'Assistance Contact Incharge Licence Number':
      'Assistance : responsable : Licence, Numéro',
    'Assistance Contact Incharge Licence Type':
      'Assistance : responsable : Licence, Type',
    'Assistance Contact Incharge Clothing Size':
      'Assistance : responsable : Taille de vêtements.',
    'Assistance Contact Incharge Team': 'Assistance : responsable : Team',
    'Assistance Contact Incharge Memo': 'Assistance : responsable : Mémo',
    'Organiser Name': 'Organisateur : Nom',
    'Organiser Logo': 'Organisateur : Logo',
    'Organiser Contact Person Sex': 'Organisateur : contact Sexe',
    'Organiser Contact Person Title': 'Organisateur : contact Titre',
    'Organiser Contact Person Last Name': 'Organisateur : contact Prénom',
    'Organiser Contact Person First Name': 'Organisateur : contact Nom',
    'Organiser Contact Person Company': 'Organisateur : contact Société',
    'Organiser Contact Person Address': 'Organisateur : contact Adresse',
    'Organiser Contact Person Address 2': 'Organisateur : contact Adresse 2',
    'Organiser Contact Person Postal Code': 'Organisateur : contact Code Postal',
    'Organiser Contact Person City': 'Organisateur : contact Localité',
    'Organiser Contact Person Country': 'Organisateur : contact Pays',
    'Organiser Contact Person Mobile': 'Organisateur : contact Mobile',
    'Organiser Contact Person Fixed': 'Organisateur : contact Téléphone fixe',
    'Organiser Contact Person Email': 'Organisateur : contact Email',
    'Organiser Contact Person Web': 'Organisateur : contact Web',
    'Organiser Contact Person Picture': 'Organisateur : contact Photo',
    'Organiser Contact Person Passport Number':
      'Organisateur : contact Numéro du passeport',
    'Organiser Contact Person Passport Delivery Place':
      "Organisateur : contact Passeport, Date d'émission",
    'Organiser Contact Person Passport Delivery Date':
      "Organisateur : contact Passeport, Lieu d'émission",
    'Organiser Contact Person Passport Scan': 'Organisateur : contact Passeport, Scan',
    'Organiser Contact Person Emergency Contact':
      "Organisateur : contact Contact en cas d'urgence",
    'Organiser Contact Person Emergency Phone':
      "Organisateur : contact Numéro de téléphone en cas d'urgence",
    'Organiser Contact Person Emergency Email':
      "Organisateur : contact Email en cas d'urgence",
    'Organiser Contact Person Place Entry Country':
      "Organisateur : contact Lieu d'entrée dans le pays",
    'Organiser Contact Person Date Entry Country':
      "Organisateur : contact Date d'entrée dans le pays",
    'Organiser Contact Person Nationality': 'Organisateur : contact Nationalité',
    'Organiser Contact Person Birth Date': 'Organisateur : contact Date de naissance',
    'Organiser Contact Person Birth Place': 'Organisateur : contact Lieu de naissance',
    'Organiser Contact Person Signature': 'Organisateur : contact Signature',
    'Organiser Contact Person Drivers Licence Scan':
      'Organisateur : contact Scan du permis de conduire',
    'Organiser Contact Person Drivers Licence Number':
      'Organisateur : contact Permis de conduire, numéro',
    'Organiser Contact Person Drivers Licence Date':
      'Organisateur : contact Permis de conduire, date',
    'Organiser Contact Person Drivers Licence Place':
      'Organisateur : contact Permis de conduire, lieu',
    'Organiser Contact Person Licence Scan': 'Organisateur : contact Licence, Scan',
    'Organiser Contact Person Drivers Licence Country':
      'Organisateur : contact Permis de conduire, pays',
    'Organiser Contact Person Licence Number': 'Organisateur : contact Licence, Numéro',
    'Organiser Contact Person Licence Type': 'Organisateur : contact Licence, Type',
    'Organiser Contact Person Clothing Size':
      'Organisateur : contact Taille de vêtements.',
    'Organiser Contact Person Team': 'Organisateur : contact Team',
    'Organiser Contact Person Memo': 'Organisateur : contact Mémo',
    'Car Registration Scan': 'Voiture : Scan de la carte grise',
  },
  'General settings': {
    'General settings': 'Paramètres généraux',
    'Email Restrictions': 'Email, limitations',
    SMTP: 'SMTP',
    'Admin Email': 'Email administrateur',
    'Email Restriction for Participant': 'Limitation des emails du participant',
    'Email Restriction for Oragnizer': "Limitation des emails de l'organisateur",
    'Email Restriction for Oragnizer Contact':
      "Restriction d'e-mail pour le contact de l'organisateur",
    'All the participant emails will be forwarded to admin email':
      "Tous les emails destinés aux participants seront transmis à l'Email de l'administrateur",
    'All the Oragniser emails will be forwarded to admin email':
      "Tous les emails destinés à l'organisateur seront transmis à l'Email de l'administrateur",
    'All the Oragniser Contact emails will be forwarded to admin email':
      "Tous les e-mails d'Oragniser seront transférés à l'e-mail de l'administrateur",
    Host: 'Host',
    Port: 'Port',
    Secure: 'Secure',
    'End Date Time is Required': "La date et l'heure de fin sont requis",
    'Start Date Time is Required': "La date et l'heure de début sont requis",
    'Type is Required': 'Le type est requis',
    'Assign Participants To Race': 'Assigner les participants à une course',
    'Choose csv': 'Choisir le fichier csv',
    'Add file': 'Ajouter un fichier',
    Regulations: 'Règlement',
    'Official forms': 'Formulaires officiels',
    'Timing schedule': 'Horaires',
    Roadbook: 'Roadbook',
    other: 'Autres',
    'Event Cloned Successfully': "L'évènement a été clôné avec succès",
    'filter forms': 'Filtrer les formulaires',
    'No Forms Available': "Il n'y a pas de formulaires disponibles",
    'Update Event Type': "Mettre à jour le type d'évènement",
    'email must be a valid email': "l'adresse email doit être valide",
    'Edit Form Header': "Modifier l'entête du formulaire",
    'Pdf download failed': 'Le téléchargement du pdf a échoué',
    'Assign Participants To Rally': 'Assigner des participants à une course',
    'Event Type Updated Successfully': "Le type d'évènement a été mis à jour avec succès",
    'Download Sample CSV': 'Télécharger un exemple de fichier CSV',
    'Choose CSV': 'Choisir un fichier CSV',
    'Form field - Multiple- True/False': 'Champ du formulaire multiple Vrai/Faux',
    'Release Notes': 'Notes de la version',
    'Copilot Nationality': 'Copilote Nationalité',
    'Copilot Passport Delivery Place': 'Copilote Lieu de délivrance du passeport',
    'Date and place of birth': 'Date et lieu de naissance',
    'Phone Number': 'Numéro de téléphone',
    'Postal Code': 'Code postal',
    Surname: 'Nom de famille',
    'Add participants': 'Ajout de participants',
    FormHeader: 'Entête du formulaire',
    to: 'A',
    count: 'nombre',
    page: 'page',
    'Are you sure you want to delete the organiser?':
      "Confirmez-vous la suppression de l'organisateur ?",
    'Filter Forms': 'Filtrer les formulaires',
    'Type is required': 'Le type est requis',
    'start date time is required': "Le jour et l'heure de début est obligatoire",
    'end date time is required': "Le jour et l'heure de fin est obligatoire",
    'organiser is required': "l'organisateur est obligatoire",
    'Form name is required': 'Le nom du fomulaire est oblogatoire',
    'No space and Uppercase letters allowed': 'Les espaces et majuscules sont interdits',
    Radio: 'Radio',
    File: 'Fichier',
    'choose less than 1 MB': "choisir, moins qu'1 Mb",
    'Driving License': 'Permis de conduire numéro',
    'Menu Icon': 'Icone du menu',
    'Participant Created Successfuly': 'Le participant a été créé avec succès',
    'Submitted successfully': 'Soumis avec succès',
    'Zip download failed': 'Le téléchargement du fichier Zip a échoué',
    From: 'à partir de',
    'Empty list could not convert to csv': "La liste vide n'a pu être convertie en CSV",
    'choose an image file less than 1MB': "choisir un fichier d'image inférieur à 1 Mb",
    'Please choose an image file': "Choisir un fichier d'Image",
  },
  formTypes: {
    number: 'Numéro',
    text: 'Texte',
    textarea: 'Zone texte',
    radio: 'Radio',
    checkbox: 'checkbox',
    date: 'date',
    time: 'time',
    datetime: 'datetime',
    file: 'file',
    heading: 'Entête',
    paragraph: 'paragraphe',
    image: 'image',
    line: ' ligne',
    dropdown: 'menu déroulant',
  },
  menuItems: {
    AllCategory: 'Toutes les catégories',
    Pilot: 'Pilote',
    Copilot: 'Copilote',
    Car: 'Voiture',
    Reco: 'Reconaissances',
    Assistance: 'Assistance',
    Entrant: 'Participant',
    Organiser: 'Organisateur',
    Rally: ' Rallye',
    Participant: 'Participant',
    Other: 'Autres',
  },
  // drag: {
  //   createdAt: 'createdAt',
  //   companyname: 'companyname',
  //   email: 'email',
  //   phone: 'phone',
  //   enquiryPhone: 'enquiryPhone',
  //   address1: 'address1',
  //   address2: 'address2',
  //   street: 'street',
  //   city: 'city',
  //   state: 'state',
  //   isActive: 'isActive',
  //   isCancelled: 'isCancelled',
  //   status: 'status',
  // },
  exportDesigner: {
    exportWarning: `Cannot download Pdf with more than ${PDFColumLimit} fields. You can either choose CSV with the same settings or choose fields below ${PDFColumLimit}`,
  },
  message: {
    'at document row': 'at document row',
    'eventName is required': 'eventName est requis',
    'eventName duplicate found': 'eventName doublon trouvé',
    'eventType is required': "type d'événement est requis",
    "Can't submit empty sheet": "Impossible d'envoyer une feuille vide",
    'invalid eventType': "type d'événement invalide",
    'timezone is required': 'le fuseau horaire est requis',
    'timezone mismatch': 'incompatibilité de fuseau horaire',
    'startDateTime is required': 'startDateTime est requis',
    'endDateTime is required': 'endDateTime est requis',
    'startDateTime is greater than endDateTime':
      'startDateTime est supérieur à endDateTime ',
    'lastDate of registarion is required': "la dernière date d'inscription est requise",
    'lastDate of registarion is less than endDateTime':
      "la dernière date d'enregistrement est antérieure à la date et à l'heure de fin ",
    'organizer is required': "l'organisateur est requis",
    'invalid organizer': 'ongeldige organisator',
    // rally
    'rallyName is required': 'RallyName est requis',
    'rallName duplicate found': 'rallName doublon trouvé',
    'invalid eventName': "nom d'événement invalide",
    'rallyType is required': 'Le type de rallye est requis',
    'invalid rallyType': 'type de rallye invalide',
    'Rally startDate must between event startDate/endDate':
      " La date de début du rallye doit être comprise entre la date de début de l'événement et la date de fin",
    'Rally endDate must between event startDate/endDate':
      " La date de fin du rallye doit être comprise entre la date de début de l'événement et la date de fin",
    // organizer
    'password is required': 'Mot de passe requis',
    'password length must be greater than 6 characters':
      'la longueur du mot de passe doit être supérieure à 6 caractères ',
    'username is required': "Nom d'utilisateur est nécessaire",
    'username is already exist': "le nom d'utilisateur existe déjà",
    'name is already exist': 'le nom existe déjà',
  },
  login: {
    'Your account is blocked.Please contact admin for more details':
      "Votre compte est bloqué. Veuillez contacter l'administrateur pour plus de détails",
    'No user found': 'Aucun utilisateur trouvé',
  },
  restore: {
    'Restore Event': "Restaurer l'événement",
    'Restore Organiser': 'Organizer wiederherstellen',
  },
  errors: {
    'Invalid email at document row ': 'E-mail invalide au du document rangée ',
    'Username is required at document row ':
      "Le nom d'utilisateur est requis à la rangée du document ",
    'Password is required at document row ':
      'Le mot de passe est requis à la rangée du document ',
    'FirstName is required at document row ':
      'Prénom est requis à la rangée du document ',
    'Invalid username format': "Format de nom d'utilisateur invalide",
  },
  eventStatus: {
    Preparation: 'Préparation',
    'Registrations open': 'Inscriptions ouvertes',
    'Registrations completed': 'Inscriptions terminées',
    'In progress': 'En cours',
    'Event finished': 'Événement terminé',
    Cancelled: 'Annulé',
  },
  Exporter: {
    Source: 'Source',
    Destination: 'Destination',
    'Rally Exporter': 'Exportateur de Rallye',
    'Event Exporter': "Exportateur d'Événement",
    'Organizer Exporter': "Exportateur d'Organisateur",
    'Organizer Contact Exporter': "Exportateur de Contacts d'Organisateur",
    'Form Fields Exporter': 'Exportateur de Champs de Formulaire',
    'Event Participants Exporter': "Exportateur de Participants d'Événement",
    'Rally Participants Exporter': 'Exportateur de Participants de Rallye',
  },
  AppDropDown: {
    ASCENDING: 'Croissant',
    DESCENDING: 'Décroissant',
  },
  'File Type': {
    'File Type': 'Type de fichier',
    'File Type Management': 'Gestion des types de fichiers',
    'File Type Listing': 'Liste des types de fichiers',
    Delete: 'Êtes-vous sûr de vouloir supprimer le type de fichier ?',
    Deleted: 'Type de fichier supprimé avec succès',
    'File type is required': 'Le type de fichier est requis',
    created: 'Type de fichier créé avec succès',
    updated: 'Type de fichier mis à jour avec succès',
    existingTypeError: "Le type de fichier existe déjà dans l'application",
    addNew: 'Ajouter un nouveau type de fichier',
    update: 'Mettre à jour le type de fichier',
  },
};
