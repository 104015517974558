export const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const baseUrlV2 = process.env.REACT_APP_API_BASE_URL_V2;
export const url = process.env.REACT_APP_URL;

// in byte unit
export const totalFileSizeLimit = 314572800;
export const fileSizeLimit = 157286400;
export const AVATARSIZE = 1;

export const DOCUMENT_TYPES = [
  'image/',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const DECLINED_DOCUMENT_TYPES = ['image/gif'];
export const formTotalFileSizeLimit = 300;
export const formFileSizeLimit = 150;

export const formFieldMainGroups = [
  {
    value: 'Organiser',
    label: 'Organiser',
  },
  {
    value: 'Rally',
    label: 'Rally',
  },
  {
    value: 'Participant',
    label: 'Participant',
  },
  {
    value: 'Entrant',
    label: 'Entrant',
  },
  {
    value: 'Pilot',
    label: 'Pilot',
  },
  {
    value: 'Copilot',
    label: 'Copilot',
  },
  {
    value: 'Car',
    label: 'Car',
  },
  {
    value: 'Reco',
    label: 'Reco',
  },
  {
    value: 'Assistance',
    label: 'Assistance',
  },
  {
    value: 'Customs',
    label: 'Customs',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
export const testURL = 'https://compreg-frontend.vercel.app';
// export const testURL = 'http://localhost:3000';
export const testUser = {
  superAdmin: {
    username: 'compregadmin@gmail.com',
    password: 'admin123',
  },
};

export const PDFColumLimit = 100;

export const FILETYPE = {
  pdf: 'application/pdf',
  image: 'image/jpeg',
  CSVFileType: 'text/csv',
  XLSXFileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  video: 'video/webm',
  png: 'image/png',
  mp4: 'video/mp4',
};
