/* eslint-disable react/no-unstable-nested-components */
import { Icon } from '@iconify/react';
import { t } from 'i18next';
import { useMemo } from 'react';
import CustomTable, { TopActionNormalButtonType } from 'src/Components/Table/Table';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import {
  CommonTranslatedResponseHandler,
  formateDateWithoutTime,
} from 'src/Services/Utils';
import topActions from 'src/Types/Enums/EnumTopActions';
import { DeleteFileType, GetFileTypes } from 'src/Services/Auth/Actions/RallyTypeActions';
import { FileTypeArray } from 'src/Types/FileType';
import AddEditFileType from './AddEditFileType';
import useStyles from './FileTypeListingStyle';

type TopActionButtonType = TopActionNormalButtonType;

function addEditFileType({ updateId, reload, setOpenPopup }) {
  return (
    <AddEditFileType updateId={updateId} reload={reload} setOpenPopup={setOpenPopup} />
  );
}

function FileTypeListing() {
  const modal = useModalDialog();
  const classes = useStyles();
  const confirm: any = useConfirm();

  const AddEditModelSetOpen = useMemo(() => modal(addEditFileType, 'rally-type'), []);

  const showModalPopup = (reload) => {
    AddEditModelSetOpen(true, { reload });
  };
  const showEditModalPopup = (updateId, reload) => {
    AddEditModelSetOpen(true, { updateId, reload });
  };

  const reloadTable = async ({
    sort,
    page,
    rowsPerPage,
    search,
  }): Promise<{
    data: FileTypeArray;
    totalCount: number;
  }> => {
    try {
      const response = await GetFileTypes({
        sortBy: sort.sortBy,
        sort: sort.sort === 'asc' ? 'ASC' : 'DESC',
        page,
        rowsPerPage,
        search,
      });
      const { data } = response;
      const totalCount = response.headers['x-total-count'];
      return {
        data,
        totalCount,
      };
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  };

  const deleteFileTypeById = (deleteId, reload) => {
    confirm({
      description: t('File Type.Delete'),
    }).then(() => {
      const responseHandlerProps = {
        promise: DeleteFileType(deleteId),
        successCb: () => {
          reload();
        },
        successToast: t('File Type.Deleted'),
      };
      CommonTranslatedResponseHandler(responseHandlerProps);
    });
  };

  const columns = [
    {
      title: t('File Type.File Type'),
      field: 'type',
      data: (row) => (
        <div className={classes.wrap}>
          <div>
            <div className={classes.tbodyName}>{row.type}</div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.createdAt)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('rallyTypes.Actions'),
      field: 'actions',
      data: (row, reload) => (
        <div>
          <div className={classes.wrapper}>
            <Icon
              onClick={() => showEditModalPopup(row._id, reload)}
              icon="feather:edit-3"
              className={classes.editIcon}
              style={{ marginRight: '20px' }}
            />
            <Icon
              onClick={() => deleteFileTypeById(row._id, reload)}
              icon="ant-design:delete-twotone"
              className={classes.icon}
              style={{
                cursor: 'pointer',
                marginRight: 'auto',
              }}
            />
          </div>
        </div>
      ),
      isSortable: false,
    },
  ];
  const topActionButton: Array<TopActionButtonType> = [
    {
      type: topActions.button,
      extraProps: {
        name: t('rallyTypes.Add New'),
        onClick: async ({ reload }) => showModalPopup(reload),
      },
    },
  ];
  return (
    <div>
      <CustomTable
        tableName="fileType"
        columns={columns}
        topActionButtons={topActionButton}
        onDoubleClick={(item, reload) => showEditModalPopup(item._id, reload)}
        heading={t('File Type.File Type Listing')}
        reload={reloadTable}
      />
    </div>
  );
}

export default FileTypeListing;
