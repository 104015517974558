import { PDFColumLimit } from 'src/Services/Constants';

export default {
  overview: {
    Overview: 'Übersicht',
    'Now Happening': 'Laufende Veranstaltungen',
    'Latest Events': 'Letzte Veranstaltungen',
    'New Organisers': 'Neue Organisatoren',
    'New Organiser Contacts': 'Neue Veranstalter Kontakte',
    'There are no events today': 'Heute keine Veranstaltungen',
    'No events found': 'Keine Veranstaltungen gefunden',
    'Upcoming Events': 'Kommende Veranstaltungen',
    'Past Events': 'Vergangene Veranstaltungen',
    'Rally Details': 'Detail Rallye',
    'Event Details': 'Veranstaltungsdetails',
    'ORGANISER DETAILS': 'DETAIL ORGANISATOR',
    'New Participants': 'Neue Teilnehmer',
    'PARTICIPANT DETAILS': 'DETAILS DES TEILNEHMERS',
    'Organiser Details': 'Detail Organisator',
    'All Events': 'Alle Veranstaltungen',
    'Status Filter': 'Statusfilter',
    'Table Reports ': 'Tabellenberichte',
  },
  organizers: {
    Organisers: 'Organisatoren',
    Organiser: 'Organisator',
    'Add new Organiser': 'Neuen Organisator hinzufügen',
    'Add New': 'Neu hinzufügen',
    'Organisers Listing': 'Liste der Organisatoren',
    'Company/Full Name': 'Firma/voller Name',
    Contact: 'Kontakt',
    'Registered as': 'Erfasst als',
    'New Organisers Listing': 'Liste der neuen Organisatoren',
    'Created at': 'Erfasst am',
    'Add New Organiser Contact': 'Neune Organisator Kontakt erfassen',
    'Edit Organiser Contact': 'Organisator Kontakt modifizieren',
    'Company Details': 'Detail Firma',
    'Organiser Contact': 'Organisator Kontakt',
    'Organiser Contact Details': 'Contactgegevens organisator',
    Image: 'Bild',
    Name: 'Name',
    Phone: 'Telefon',
    Email: 'Email',
    Actions: 'Aktionen',
    Never: 'Nie',
    'Member since': 'Mitglied seit',
    organizerCreate:
      'Organisator erstellt und Anmeldedaten an Organisator-E-Mail gesendet',
    organizerDelete: 'Organisator erfolgreich gelöscht',
    organizerBlocked: 'Organisator erfolgreich blockiert',
    organizerUpdate: 'Anlass Organisator erfolgreich aktualisiert',
    eventOrganizerCreate: 'Organisator der Veranstaltung erfolgreich erstellt',
    eventOrganizerUpdate: 'Organisator der Veranstaltung erfolgreich aktualisiert',
    eventOrganizerDelete: 'Anlass Organisator erfolgreich gelöscht',
    organizerCreatedSucessfully: 'Organisator erfolgreich erstellt',
    organizerActivatedSuccessfully: 'Organisator erfolgreich aktiviert',
    'Organizer Contact': 'Organisator contactpersoon',
    organizerContactUnBlocked: 'Organisatorischer Kontakt erfolgreich freigeschaltet',
    organizerContactBlocked: 'Organisatorischer Kontakt erfolgreich blockiert',
  },
  formFields: {
    'Select Form Field Type': 'Select Form Field Type',
    'Form Field Management': 'Formularfeld Management',
    'Form Field Listing': 'Liste der Formularfelder',
    'Form Field': 'Formularfeld',
    'Form Type': 'Formulartyp',
    Hint: 'Hinweis',
    Category: 'Kategorie',
    'Add New': 'Neu hinzufügen',
    Actions: 'Aktionen',
    'Select Form Field': 'Formularfeld wählen',
    'Select Category': 'Kategorie wählen',
    'Filter FormFields': 'Formuliervelden filteren',
    Id: 'Ausweis',
    Entrant: 'Teilnehmer',
    Settings: 'Einstellung',
    'Label Field is required': 'Feldtext ist obligatorisch',
    'Id Field is required': 'Diese Feld ID ist obligatorisch',
    'New Form': 'Neues Format',
    'Clone Form': 'Format klonen',
    'Form Field is required': 'Formatfeld notwendig',
    'Category is required': 'Kategorie notwendig',
    'Form Field Group': 'Formatfeld-Gruppe',
    'Form Field Group Listing': 'Liste der Formatfeld-Gruppen',
    ParentName: 'Verwandtschaftname',
    GroupName: 'Gruppenname ',
    'An order number is required': 'Eine Ordnungsnummer wird verlangt',
    'An order number must be an integer': 'Eine Bestellnummer muss eine ganze Zahl sein',
    'An order number must be a positive value':
      'Eine Bestellnummer muss ein positiver Wert sein',
    'Value field is required': 'Wertfeld wird verlangt',
    'ParentName is required': 'Verwandtschaftname wird verlangt',
    'GroupName is required': 'Gruppenname wird verlangt',
    'Select Group': 'Gruppe auswählen',
    ASCENDING: 'Aufsteigend',
    DSCENDING: 'Absteigend',
    CSV: 'CSV',
    PDF: 'PDF',
    'New Form Field Group': 'Neue Formatfeld-Gruppe ',
    Group: 'Groep',
    'Add A New Image': 'Voeg een nieuwe afbeelding toe',
  },
  eventTypes: {
    'Event Type Management': 'Veranstaltungstyp Management',
    'Add New': 'Neu hinzufügen',
    'Event Type Listing': 'Liste der Veranstaltungstypen',
    'Event Type': 'Veranstaltungstyp',
    Actions: 'Aktionen',
  },
  rallyTypes: {
    'Rally Type Management': 'Ralley Typ Management',
    'Add New': 'Neu hinzufügen',
    'Rally Type Listing': 'Lister der Rallye Typen',
    'Rally Type': 'Ralley Typ',
    Actions: 'Aktionen',
    'Add New Rally Type': 'Rallye/Renn Typ hinzufügen',
  },
  generalSettings: {
    'General Settings': 'General Settings',
  },
  forms: {
    'Organiser type': 'Organisatoren Typen',
    'Organiser Contact': 'Oranisatoren Kontakt',
    'Select Type': 'Type wählen',
    Select: 'Wälen',
    'Enquiry Contact': 'Anfrage Kontakt',
    'Enquiry Email': 'Anfrage Email',
    Company: 'Firma/voller Name',
    Individual: 'Individuell',
    'Company Name': 'Firmenname',
    'Full Name': 'Voor-en achternaam',
    'First Name': 'Vorname',
    'Last Name': 'Achternaam',
    Email: 'Email',
    Phone: 'Telefon',
    'Address Line 1': 'Adresslinie 1',
    Street: 'Strasse',
    City: 'Ort',
    State: 'Staat',
    Country: 'Land',
    Postcode: 'PLZ',
    'Facebook URL': 'Facebook URL',
    'Instagram URL': 'Instagram URL',
    Submit: 'Erfassen',
    Actions: 'Aktionen',
    Active: 'Aktiv',
    inactive: 'Inaktiv',
    Delete: 'Löschen',
    Completed: 'Fertiggestellt',
    completed: 'Fertiggestellt',
    'Filter Options': 'Filter Optionen',
    'Last login': 'Letztes Login',
    Designation: 'Bezeichnung',
    Memo: 'Memo',
    'Start Date': 'Start Datum',
    'End Date': 'End Datum',
    Import: 'Import',
    Status: 'Status',
    'Event Name': 'Veranstaltungsname',
    'Event Type': 'Veranstaltungstyp',
    'Event Start Date': 'Begindatum evenement',
    'Event End Date': 'Einddatum evenement',
    'Rally Start Date': 'Startdatum rally',
    'Rally End Date': 'Einddatum rally',
    Venue: 'Ort',
    'Last Date of Registration': 'Letzte Registrationsdatum',
    'Start Date of Registration': 'Startdatum van registratie',
    Website: 'Website',
    'Add Event Organiser': 'Organisation Veranstalter hinzufügen',
    'Add Rally': 'Rallye hinzufügen',
    'Edit Rally': 'Rallye aktualisieren',
    Rally: 'Rallye',
    'Manage Forms': 'Formularverwaltung',
    Participants: 'Teilnehmer',
    'Rally Name': 'Rallye Name',
    'Rally Type': 'Rallye Typ',
    'Maximum No of Participants': 'Maximale Teilnehmerzahl',
    'Last Date & Time of Registration': 'Datum/Zeit letzte Registration',
    'Address Line 2': 'Adresslinie 2',
    'Rally Forms': 'Anmeldeformular',
    'Racing No': 'Rennen Nr.',
    'Event Details': 'Veranstaltungsdetails',
    'Add existing participant': 'Bestehenden Teilnehmer hinzufügen',
    'Add New Participant': 'Nieuwe deelnemer toevoegen',
    'Add Participant': 'Teilnehmer hinzufügen',
    Pilot: 'Pilot',
    Contact: 'Kontakt',
    Edit: 'Ändern',
    'Select a rally': 'Rallye auswählen',
    'Website URL': 'URL Web Seite',
    Password: 'Passwort',
    Username: 'Teilnehmer',
    Deleted: 'Verwijderd',
    'Event Organiser': 'Organisator der Veranstaltung',
    'Enquiry Number': 'Anfrage Nummer',
    'Enroll Now': 'Jetzt einschreiben',
    pending: 'Pendent',
    'Form Name': 'Formularname',
    'New form added successfully': 'Format erfolgreich eingefügt',
    'Form updated successfully': 'Formular erfolgreich aktualisiert',
    'Form Clone Warning':
      'Wenn Sie das Formular bereits ausgefüllt haben, werden dessen Informationen während dem duplizieren gelöscht',
    'Form Submitted Successfully': 'Das Formular wurde erfolgreich hinzugefügt',
    'Form Delete Warning': 'Weet u zeker dat u het formulier wilt verwijderen?',
    'Form Deleted Successfully': 'Formular erfolgreich gelöscht',
    'Event Forms': 'Evenementformulieren',
    'Uploaded Files': 'Geüploade bestanden',
    'No Forms Available': 'Geen formulieren beschikbaar',
    'View Event': 'Evenement bekijken',
    'Text copied to clipboard': 'Text zum Clipboard kopiert',
    'Rally Cloned Successfully': 'Rallye erfolgreich dupliziert',
    'Clone Form From Previous Rally': 'Formular von vorherigem Ralley kopieren',
    'Form Preview': 'Formular Vorschau',
    Clone: 'Duplizieren',
    'Form cloned successfully': 'Formular erfolgreich dupliziert',
    Label: 'Text',
    Hint: 'Hinweis',
    'Add Participants': 'Teilnehmer hinzufügen',
    Type: 'Typ',
    Forms: 'Formulare',
    'Update Form Field': 'Formularfeld aktualisieren',
    "You can't save an empty form": 'Sie können ein leeres Format nicht speichern',
    SUBMIT: 'ERFASSEN',
    'Add File': 'Datei hinzufügen',
    'View Form': 'Formular anzeigen',
    'Cannot submit an empty form': 'Ein leeres Format kann nicht eingereicht werden',
    'Pass atleast one editable field. Then save':
      'Übergehe mindestens ein editierbares Feld. Dann speichern',
    'Form submission failed': 'Formateinreichung fehlgeschlagen',
    'Upload files under 50mb': 'Datei unter 50MB hochladen',
    'File Upload': 'Dateil hochladen',
    Block: 'Sperren',
    Unblock: 'Entsperren',
    'All forms': 'Alle Formen',
    'All Participants': 'Alle Teilnehmer',
    EnrollStatus: 'EnrollStatus',
    Upload: 'Hochladen',
    'Form Listing': 'Formularliste',
    Form: 'Formular',
    'To adjust tables': 'Tabellen anzupassen',
    'Set headers for each page': 'Legen Sie Kopfzeilen für jede Seite fest',
  },
  users: {
    'All Users Listing': 'All Users Listing',
    'All Users': 'All Users',
    Users: 'Teilnehmer',
    'Add New Participants': 'Neue Teilnehmer hinzufügen',
    'Participants Listing': 'Teilnehmerliste',
    Profile: 'Profil',
    'Personal Details': 'Persönliche Details',
    'Hello,': 'Guten Tag,',
    Welcome: 'Welkom',
    'Member since': 'Mitglied seit',
    Events: 'Veranstaltungen',
    Rallies: 'Rallyes',
    Participants: 'Teilnehmer',
    'Last login on': 'Letztes Login am',
    'Participant Profile': 'Deelnemersprofiel',
    'Organiser Profile': 'Organisator Profil',
    'Users Participation Declined': 'Benutzerbeteiligung abgelehnt',
    'Declined by Organiser': 'Durch Organisator abgelehnt',
    'Organiser Imported': 'Erfolgreich importiert',
    'Super Admin': 'höchster Vorgesetzter',
    'Invite Action': 'Aktion einladen',
    'Import Action': 'Aktion importieren',
    Invite: 'Einladen',
    Restore: 'Herstellen',
    'Participant Imported Successfully': 'Teilnehmer erfolgreich importiert',
    'Organiser Imported Successfully': 'Organisator erfolgreich importiert',
    Role: 'Rolle',
  },
  events: {
    Events: 'Veranstaltungen',
    Event: 'Veranstaltungen',
    'Event Imported': 'Anlass importiert',
    'Event Details': 'Veranstaltungsdetails',
    'Add New': 'Neu hinzufügen',
    'Add new Events': 'Neue Veranstaltung hinzufügen',
    'Event Place': 'Veranstaltungsort',
    'Filter Events': 'Filter Veranstaltungen',
    'Events Listing': 'Veranstaltungsliste',
    'Add Event Organiser': 'Veranstaltungsorganisator hinzufügen',
    Memo: 'Memo',
    Website: 'Website',
    'Facebook Url': 'Facebook Url',
    'Instagram Url': 'Instagram Url',
    'Event Image': 'Bild der Veranstaltung',
    'Rally Listing': 'Rallye Liste',
    'Latest Events': 'Letzte Veranstaltungen',
    'No events found': 'Keine Veranstaltungen gefunden',
    'Manage Forms': 'Formularverwaltung',
    'Manage Participants': 'Teilnehmerverwaltung',
    'Fill Up The Below Forms To Participate':
      'Fülle untenstehende Formulare aus, um teilzunehmen',
    'Filter Participants': 'Filter Teilnehmer',
    'Download List': 'Liste runterladen',
    Download: 'Runterladen',
    'All Participants': 'Alle Teilnehmer',
    'View Details': 'Detials anzeigen',
    Rallies: 'Rallyes',
    'Download PDF': 'PDF runterladen',
    Export: 'Exporteren',
    'SUBMITTED FORMS': 'EFASSTE FORMULARE',
    'PARTICIPANT DETAILS': 'DETAILS DES TEILNEHMERS',
    'View profile': 'Profil anzeigen',
    'Racing Number': 'rennen nr.',
    'Clone Form From Previous Rally': 'Formular von vorherigem Ralley kopieren',
    'Select event Name': 'Veranstaltungsname wählen',
    'Select form': 'Formular wählen',
    'Form name': 'Formularname',
    'Source form name': 'Naam bronformulier',
    'New form name': 'Nieuwe formuliernaam',
    Approved: 'Geehmigt',
    Declined: 'Afgewezen',
    Approve: 'Genehmigen',
    Decline: 'Abfall',
    Reinstate: 'Wiedereinsetzen',
    Cancelled: 'Annuliert',
    Completed: 'Fertiggestellt',
    'Registrations Completed': 'Anmeldungen Fertiggestellt',
    'Registration Closed': 'Anmeldung abgeschlossen',
    'Registration Progressing': 'Anmeldung im Gange',
    Others: 'Andere',
    'Clone Form': 'Formular kopieren',
    Details: 'Details',
    Forms: 'Formulare',
    'Organized By': 'Veranstaltungen',
    Invalid: 'Non valide',
    'View details': 'Detials anzeigen',
    'Participant Status': 'Status des Teilnehmers',
    'In Progress': 'Am verarbeiten',
    "Participant's Event Status": "Statut de l'événement du participant",
    'Enroll to Participate': "S'inscrire pour participer",
    'Pending approval': 'En attente de validation',
    Active: 'Actief',
    'Edit form fields': 'Formularfeld ändern',
    'Update Event Type': 'Veranstaltungstyp ändern',
    'Update Rally Type': 'Rallye Typ ändern',
    count: 'count',
    rallies: 'rallies',
    participants: 'Teilnehmer',
    forms: 'forms',
    'Event Cloned Successfully': 'Veranstaltung erfolgreich dupliziert',
    'Event Clone failed': 'Anlass klonen fehlgeschlagen',
    eventCreate: 'Veranstaltung erfolgreich erstellt',
    eventUpdate: 'Veranstaltung erfolgreich aktualisiert',
    eventDelete: 'Veranstaltung erfolgreich gelöscht',
    eventCancelled: 'Veranstaltung erfolgreicht gelöscht',
    eventShared: 'Event Shared Successfully',
    userEventExist:
      'Dieser Benutzer existiert nicht in dieser Compreg Community. Füge einen neuen Benutzer hinzu und teile anschliessend diese Veranstaltungen',
    Date: 'Datum',
    'File Size': 'Dateigröße',
    'File Name': 'Dateinamen',
    'File Type': 'Dateityp',
    'Drag or Drop your files here': 'Ziehen Sie Ihre Dateien per Drag-and-Drop hierher',
    'Date should not be before': 'Das Datum darf nicht davor liegen',
    'Date should not be after': 'Datum sollte nicht nach sein',
    'Races / Rallies': 'Rennen / Rallyes',
    'Pass Event': 'Pass Event',
    Registrations: 'Anmeldungen',
    Open: 'Offen',
    Preparation: 'Vorbereitung',
    'Choose different events for Source and Target':
      'Choose different events for Source and Target',
    'Participants invited Successfully': 'Teilnehmer erfolgreich eingeladen',
    'Choose an event!!': 'Veranstaltung auswählen!',
    Source: 'Quelle',
    Destination: 'Ziel',
    Order: 'Bestellnummer',
    'Add New Event Type': 'Voeg een nieuw gebeurtenistype toe',
  },
  addNewForm: {
    'Add New Form': 'Neues Formular hinzufügen',
    'Clone Form': 'Formular kopieren',
    'Save & Preview': 'Speichern & Vorschau',
    Pilot: 'Pilot',
    'Co-Pilot': 'Co-Pilot',
    Car: 'Fahrzeug',
    'Reco (Reconotering)': 'Reco (Reconotering)',
    'Assistance (Service)': 'Assistance (Service)',
    'Enter Form Name': 'Formularname eingeben',
    'Edit Form': 'Formular ändern',
    'Update & Preview': 'Aktualisieren & Voschau',
    Others: 'Andere',
    Entrant: 'Teilnehmer',
    FormHeader: 'Form Header',
    Organiser: 'Organisatoren',
    Participant: 'Teilnehmer',
    Rally: 'Rallye',
    Organisers: '',
    'Preview as Participant': 'Vorschau als Teilnehmer',
  },
  signUp: {
    'First Name': 'Vorname',
    'Last Name': 'Achternaam',
    username: 'Benutzername',
    USERNAME: 'BENUTZERNAME',
    PASSWORD: 'PASSWORT',
    Login: 'Anmeldung',
    'Forgot password?': 'Passwort vergessen?',
    'Don’t have an account?': 'Sie haben noch kein Konto?',
    'Sign up': 'Anmeldung',
    'RESET PASSWORD': 'PASSWORT ZURüCKSETZEN',
    'SEND RESET PASSWORD LINK': 'LINK FÜR PASSWORT ZURÜCKSETZEN SENDEN',
    'Sign in': 'Anmelden',
    'Change Username': 'Benutzernamen ändern',
    'Change Password': 'Passwort ändern',
    Signout: 'Abmelden',
    'Username*': 'Benutzer*',
    Username: 'Benutzer',
    'Old Password': 'Altes Passwort',
    'New Password': 'Neues Passwort',
    'New Password*': 'Neues Passwort*',
    'New Password Confirmation': 'Bestätigung Passwort',
    Register: 'Anmelden',
    Telephone: 'Telefon',
    'Confirm Password*': 'Bevestig Wachtwoord*',
    'Confirm Password': 'Bevestig Wachtwoord',
    Back: 'Zurück',
    Next: 'Weiter',
    'ADD SOCIAL MEDIA': 'SOCIAL MEDIA EINFÜGEN',
    'SKIP & COMPLETE REGISTRATION': 'ÜBERSPRINGEN & ANMELDUNG ABSCHLIESSEN',
    'SAVE & Complete registration': 'SPEICHERN & ANMELDUNG ABSCHLIESSEN',
    'Please change username': 'Bitte Benutzernamen wechseln',
    'Old password does not match our records': 'Altes Passwort ist nicht korrekt',
    'Passwords are same': 'Passworter ist identisch',
    'Password Updated Successfully, Please Login again to Continue':
      'Passwort erfolgreich geändert, bitte melden Sie sich neu an',
    'Switch Profile': 'Profil wechseln',
    organizer: 'Organisatoren',
    eventOrganizer: 'Evenement organisator',
    Participant: 'Teilnehmer',
  },
  others: {
    'Add pin': 'Pin hinzufügen',
    'Search…': 'Suchen…',
    'Upload CSV': 'CSV Datei hochladen',
    'Sample CSV': 'CSV Musterdatei',
    'Export CSV': 'CSV Datei exportiren',
    Yes: 'Ja',
    Cancel: 'Annullieren',
    Share: 'Teilen',
    'Copy Link': 'Link kopieren',
    'Enter username': 'Benutzername eingeben',
    Invite: 'Einladen',
    Copy: 'Kopie',
    Optional: 'Optional',
    'Event Status': 'Status der Veranstaltung',
    by: 'Durch',
    'Import CSV': 'CSV Datei importieren',
    'No items found': 'Keine Elemente gefunden',
    from: 'Van',
    to: 'to',
    count: 'Zähler',
    page: 'page',
    'View Rallys': "Bekijk rally's",
    'View Rally': 'Bekijk rally',
    'No item found': 'Keine Elemente gefunden',
    'Nothing to show': 'Niets om te laten zien',
    'SELECT ALL': 'Alles auswählen',
    'Select All': 'Alles auswählen',
    'Unselect All': 'Alles wiederufen',
    'DESELECT ALL': 'Alles abwählen',
    'Rows per page': 'Linien pro Seite',
    Change: 'Ändern',
    'Something went wrong': 'Ein Fehler ist aufgetreten',
    'Please login to view the forms': 'Please login to view the forms',
    'Table Reports Listing': 'Tabellenberichtsliste',
    'Resource Name': 'Ressourcenname',
    'Report Name': 'Berichtsname',
    'Table Reports': 'Tabellenberichte',
    'Save as Table report': 'Als Tabellenbericht speichern',
    'Report saved successfully': 'Bericht erfolgreich gespeichert',
    'Report updated successfully': 'Bericht erfolgreich aktualisiert',
    'Choose File': 'Kies bestand',
    'Aspect ratio': 'Beeldverhouding',
    'Report Name field is required': 'Das Feld Berichtsname ist erforderlich',
    'Filter all': 'Alles filtern',
    Filter: 'Filter',
    "The participant has been cancelled.Can't be share the rallys":
      'Der Teilnehmer wurde abgesagt. Die Rallyes können nicht geteilt werden',
    Deselect: 'Abwählen',
    items: 'elemente',
    'Save Changes': 'Wijzigingen opslaan',
  },
  profile: {
    'Are you sure you want to sign out?': 'Weet u zeker dat u zich wilt afmelden?',
  },
  participant: {
    'Participant Approved': 'Teilnehmer akzeptiert',
    'Participant Declined': 'Deelnemer geweigerd',
    'View Registration': 'Registratie bekijken',
    created: 'Teilnehmer erstellt und Login Info wurde an seine Email Adresse verschickt',
    deleted: 'Der Benutzer wurde erfolgreich gelöscht',
    updated: 'Der Benutzer wurde erfolgreich aktualisiert',
    participantCreatedSuccessfuly: 'Teilnehmer erfolgreich erstellt',
    'Participant Name': 'Naam deelnemer',
    'Select Rally': 'Selecteer Rally',
    Save: 'Redden',
  },
  popup: {
    Yes: 'Ja',
    Cancel: 'Annullieren',
    'Are you sure you want to delete the participant?':
      'Möchten Sie den Teilnehmer wirklich löschen?',
    'You have been enrolled successfully. Please start filling the event forms':
      'Je bent succesvol ingeschreven. Begin a.u.b. met het invullen van de evenementenformulieren',
    'Are you sure you want to cancel the participant?':
      'Wollen Sie diesen Teilnehmer tatsächlich löschen?',
    'Are you sure you want to approve the participant?':
      'Wollen Sie diesen Teinehmer tatsächlich zulassen?',
    'Are you sure you want to unblock the organiser?':
      'Weet u zeker dat u de organisator wilt deblokkeren?',
    'Are you sure you want to block the organiser?':
      'Wollen Sie den Organisator tatsächlich sperren?',
    'Are you sure you want to delete the event?':
      'Wollen Sie diese Veranstaltung tatsächlich löschen?',
    'This is an irreversible action.': 'Kann nicht rückgängig gemacht werden!',
    'Are you sure you want to cancel the event?':
      'Wollen Sie diese Veranstaltung tatsächlich löschen?',
    'Do you want to delete ?': 'Wollen Sie tatsächlich löschen?',
    'Are you sure you want to delete the organiser?':
      'Möchten Sie den Organisator wirklich löschen?',
    'Are you sure you want to delete the event organiser?':
      'Weet je zeker dat je de organisator van het evenement wilt verwijderen?',
    '+Add New Contact': '+Kontakt einfügen',
    'Are you sure you want to cancel the rally?':
      'Wollen Sie dieses Rallye tatsächlich löschen?',
    'Are you sure you want to delete the rally?':
      'Wollen Sie dieses Rallye tatsächlich löschen?',
    'There is a mismatch between the event date and rally date. Please manually change the rally date':
      'Anlassdatum und Rallydatum stimmen nicht überein. Bitte Rallydatum manuell änden.',
    'Are you sure you want to delete the organizer?':
      'Wollen Sie den Organisator tatsächlich sperren?',
    'Participant created and login credentials are sent to the participant email':
      'Teilnehmer erstellt und Login Info wurde an seine Email Adresse verschickt',
    'You cannot participate in this event either your are super admin or organiser':
      'Sie können nicht Teilnehmer dieser Veranstaltung sein, entweder sind sie Super Admin oder Organisator',
    'You cannot participant in this event either your are super admin or organiser':
      'Sie können an diese Anlass teilnehmern, entweder sind Sie Super-Admin oder Organisator',
    'Unsaved changes will be discarded': 'Nicht gespeicherte Änderungen werden verworfen',
    'Are you sure you want to change the block status?':
      'Möchten Sie den Sperrstatus wirklich ändern?',
    'Are you sure you want to delete the form fields?':
      'Möchten Sie die Formularfelder wirklich löschen?',
    'You cannot participate in this rally / event':
      'Sie können an dieser Rallye/Veranstaltung nicht teilnehmen',
    'This event organizer is assigned to an event. Are you sure you want to delete?':
      'Deze evenementorganisator is toegewezen aan een evenement. Weet je zeker dat je wilt verwijderen?',
    'It will take time, continue in background':
      'Het zal even duren, doorgaan op de achtergrond',
    'Restore event': 'Evenement herstellen',
    "It's an approved participant.": 'Es handelt sich um einen zugelassenen Teilnehmer.',
    'Are you sure you want to delete the Form Field Group?':
      'Sind Sie sicher, dass Sie die Formularfeldgruppe löschen möchten?',
    'You have been enrolled successfully. Please see the event details from participant dashboard':
      'U bent succesvol ingeschreven. Bekijk de evenementdetails op het deelnemersdashboard',
    eventRegistrationClosed: 'Evenementregistratie is gesloten',
    eventRegistrationNotOpen: 'Registratie voor evenementen is nog niet geopend',
    eventFinished: 'Het evenement is succesvol afgerond',
    eventCancelled: 'Het evenement is geannuleerd',
    'Backup event': 'Backup-Ereignis',
    'Backup Organiser': 'Backup-Organizer',
    'Are you sure you want to update Rally details?':
      'Sind Sie sicher, dass Sie die Rallye-Details aktualisieren möchten?',
  },
  validationErrors: {
    'First name is Required': 'Vorname ist obligatorisch',
    'Username is Required': 'Benutzername ist erforderlich',
    'Password is Required': 'Passwort ist obligatorisch',
    'Confirm Password is Required': 'Bestätigung des Passwortes ist obligatorisch',
    'Invalid email': 'Email ungültig',
    'Type is Required': 'Typ wird benötigt',
    'Company name is Required': 'Firmenname wird benötigt',
    'Event name is Required': 'Veranstaltungsname wird benötigt',
    'Start Date Time is Required': 'Startdatum wird benötigt',
    'End Date Time is Required': 'Enddatum wird benötigt',
    'Start Date of Registration is Required': 'Startdatum van registratie is vereist',
    'Last Date of Registration is Required': 'Laatste registratiedatum is vereist',
    'Organiser is Required': 'Organisator ist obligatorisch',
    'Rally Type is Required': 'Rallye Typ ist obligatorisch',
    'Rally name is Required': 'Rallye Name ist obligatorisch',
    'Form name is required': 'Formatname wird benötigt',
    'Event Type is Required': 'Veranstaltungstyp ist obligatorisch',
    'Username already exist': 'Benutzername existiert bereits',
    'Too Short!': 'Zu kurz!',
    'Too Long!': 'Zu lang!',
    'choose total file size lessthan': 'Wählen Sie die Gesamtdateigröße kleiner als',
    'Choose individual file size less than': 'Wählen Sie eine Dateigröße kleiner als',
    'Passwords must match': 'Passwörter müssen übereinstimmen',
    'Admin email is required': 'Admin email ist obligatorisch',
    'First name is not allowed to be empty': 'Vorname darf nicht leer sein',
    'The firstname cannot include leading and trailing spaces':
      'Der Vorname darf keine führenden und abschließenden Leerzeichen enthalten',
    'Status Type is Required': 'Statustyp verlangt',
    'Source event is required': 'Quellenveranstaltung wird verlangt',
    'Target event is required': 'Zielveranstaltung wird verlangt',
    'End date cant be before Start date': 'Enddatum kann nicht vor dem Startdatum sein',
    'Rally name is too Short!': 'Name des Rallye/Rennen ist zu kurz',
    'file is not in the file size limit, Pleas choose less than':
      'Datei liegt nicht innerhalb der Dateigrößenbeschränkung, bitte wählen Sie kleiner als',
    'total file size, Pleas choose  less than':
      'Gesamtdateigröße, Bitte wählen Sie weniger als',
    'The username cannot include leading and trailing spaces':
      'Der Benutzername darf keine führenden und abschließenden Leerzeichen enthalten',
    'The password cannot include leading and trailing spaces':
      'Das Passwort darf keine führenden und nachgestellten Leerzeichen enthalten',
    'The Email cannot include leading and trailing spaces':
      'Die E-Mail darf keine führenden und nachgestellten Leerzeichen enthalten',
    'use at least one alphabet': 'Verwenden Sie mindestens ein Alphabet',
    'The lastname cannot include leading and trailing spaces':
      'Der Nachname darf keine führenden und abschließenden Leerzeichen enthalten',
    'Phone Number cannot include leading and trailing spaces':
      'Die Telefonnummer darf keine führenden und nachgestellten Leerzeichen enthalten',
    'Invalid Phone Number': 'Ungültige Telefonnummer',
    'The Email cannot include uppercase alphabets':
      'Die E-Mail darf keine Großbuchstaben enthalten',
    'The username cannot include uppercase alphabets':
      'Der Benutzername darf keine Großbuchstaben enthalten',
    'Select a participant': 'Wählen Sie einen Teilnehmer aus',
    'Invalid Status': 'Ongeldige status',
    'Event name is too Short!': 'Evenementnaam is te kort!',
    'choose a valid event status': 'kies een geldige gebeurtenisstatus',
  },
  filterForms: {
    'Filter Forms': 'Filter Forms',
  },
  toast: {
    'Start Date of Registration must be less than or equal to Start Date':
      'De startdatum van registratie moet kleiner zijn dan of gelijk zijn aan de startdatum',
    'Last Date of Registration must be greater than or equal to Start Date of Registration':
      'De laatste registratiedatum moet groter zijn dan of gelijk zijn aan de startdatum van registratie',
    'Last Date of Registration must be less than or equal to End Date':
      'Das letzte Registrierungsdatum muss kleiner oder gleich dem Enddatum sein',
    'End Date must be greater than or equal to Start Date':
      'Enddatum muss grösser oder gleich dem Startdatum sein',
    'Cant submit empty email draft': 'Kann leeren Email-Entwurf nicht unterbreiten',
    'Enroll Decline, This rally is Cancelled':
      'Inschrijving geweigerd, deze rally is geannuleerd',
    'Enroll Decline, This rally is completed':
      'Inschrijving geweigerd, Diese Rallye ist abgeschlossen',
    "This user has been blocked.Can't be share the events":
      'Dieser Benutzer ist gesperrt, kann Veranstaltungen nicht teilen',
    'Denied for Cancelled Event ': 'Geweigerd wegens geannuleerd evenement',
    'Language Changed Successfully': 'Sprache erfolgreich geändert',
    'Organiser Contact created successfully, credentials will email to you':
      'Organisatorkontakt erfolgreich erstellt, Zugangsdaten werden Ihnen per E-Mail zugesendet',
    'Organiser Contact updated successfully':
      'Organisatorkontakt erfolgreich aktualisiert',
    'Username already exist': 'Benutzername existiert bereits',
    'Form Field Group Added Successfully': 'Formularfeldgruppe erfolgreich hinzugefügt',
    'Sucessfully updated': 'Erfolgreich aktualisiert',
    'Enroll in Event First': 'Schrijf je eerst in voor Evenement',
    'rally start date must be after or same as the event start date':
      'de startdatum van de rally moet na of gelijk zijn aan de startdatum van het evenement',
    'rally end date must be before or same as the event end date':
      'De einddatum van de rally moet voor of gelijk zijn aan de einddatum van het evenement',
    'Past date is not allowed': 'Verleden datum is niet toegestaan',
    'restore failed': 'herstellen mislukt',
    'This participant active in event':
      'Dieser Teilnehmer ist in der Veranstaltung aktiv.',
    'This participant may active in event':
      'Deze deelnemer kan actief zijn in het evenement',
    '"email" must be a valid email': 'E-Mail muss eine gültige E-Mail sein',
    'Unfortunately, the cancelled cannot be edited':
      'Helaas kan de geannuleerde niet worden bewerkt',
    'You were declined by Organiser': 'Je bent geweigerd door de Organisator',
    'Your approval is in pending': 'Uw goedkeuring is in behandeling',
    'View event details from participant dashboard':
      'Bekijk evenementdetails vanaf het deelnemersdashboard',
    "It's not possible to delete this organiser since there are events to be organised":
      'Het is niet mogelijk om deze organisator te verwijderen, omdat er evenementen moeten worden georganiseerd',
    'file is not acceptable': 'bestand is niet acceptabel',
    "The chosen event's start date has been reached":
      'De startdatum van het gekozen evenement is bereikt',
    "The chosen event's registration start date has not yet been reached":
      'De startdatum van de registratie van het gekozen evenement is nog niet bereikt',
    "The chosen event's registration end date has been reached":
      'De einddatum voor registratie van het gekozen evenement is bereikt',
    "The chosen event's registration deadline has not yet been reached":
      'De registratiedeadline van het gekozen evenement is nog niet bereikt',
    "The chosen event's start date has not yet been reached":
      'De startdatum van het gekozen evenement is nog niet bereikt',
    "The chosen event's end date has been reached":
      'De einddatum van het gekozen evenement is bereikt',
    "The chosen event's end date has not yet been reached":
      'De einddatum van het gekozen evenement is nog niet bereikt',
    'Are you sure you want to cancel the event?':
      'Weet je zeker dat je het evenement wilt annuleren?',
    'You do not have the authorization to access this':
      'U heeft geen toestemming om hier toegang toe te krijgen',
    'Please choose valid event status': 'Kies een geldige gebeurtenisstatus',
    'The event will be cancelled upon submission':
      'Na indiening wordt het evenement geannuleerd',
  },
  user: {
    loginMessage: 'Erfolgreich angemeldet',
    signup: 'Benutzer erfolgreich erfasst',
    alreadyExist: 'Dieser Benutzer existiert bereits',
    passWordResetLink: 'Link für Passwort zurücksetzen an Ihr Email Konto verschickt',
    passwordUpdate: 'Passwort erfolgreich geändert, bitte melden Sie sich neu an',
    userNotfound: 'Keinen Benutzer gefunden',
    userNameNotExist: 'Ein Benutze mit diesem Namen existiert nicht',
    userNameUpdated: 'Benutzername aktualisiert',
    userNameExist: 'Dieser Benutzer existiert bereits',
    passwordChanged: 'Ihr Passwort wurde geändert',
    userDeleted: 'Der Benutzer wurde erfolgreich gelöscht',
    userUpdated: 'Der Benutzer wurde erfolgreich aktualisiert',
  },
  rally: {
    rallyCreate: 'Rallye erfolgreich erstellt',
    rallyUpdate: 'Rallye erfolgreich modifiziert',
    rallyDelete: 'Rallye erfolgreich gelöscht',
    rallyCancelled: 'Rallye erfolgreicht annulliert',
    rallyShared: 'Rallye erfolgreicht geteilt',
    userExistRally:
      'Dieser Benutzer existiert nicht in dieser Compreg Community. Füge einen neuen Benutzer hinzu und teile anschliessend diese Ralley',
    rallyCloned: 'Rallye erfolgreich dupliziert',
    'Select atleast one Race': 'Wähle mindestens ein Rennen',
    'Select atleast one rally': 'Wähle mindestens ein Rally',
    'Select Active participant': 'Wähle aktiven Teilnehmer',
  },
  eventParticipant: {
    participantApproved: 'Der Teilnehmer wurde erfolgreich zugelassen',
    formSubmitted: 'Das Formular wurde erfolgreich hinzugefügt',
    formUpdated: 'Das Formular wurde erfolgreich aktualisiert',
    racingNumberUpdate: 'Nummer des Rennens wurde geändert',
    folderNumberUpdate: 'Dossiernummer wurde geändert',
  },
  eventForm: {
    formUpdated: 'Form updated added successfully',
    formCloned: 'Formular erfolgreich dupliziert',
    formDeleted: 'Formular erfolgreich gelöscht',
  },
  formField: {
    alreadyExist: 'Existiert bereits',
    created: 'Formularfeld wurde erfolgreich erstellt',
    updated: 'Formularfeld wurde erfolgreich aktualisiert',
    deleted: 'Das Formularfeld wurde erfolgreich gelöscht',
  },
  eventType: {
    created: 'Veranstaltungstyp wurde erfolgreich erfasst',
    updated: 'Veranstaltungstyp wurde erfolgreich aktualisiert',
    deleted: 'Veranstaltungstyp wurde erfolgreich gelöscht',
    existingTypeError: 'Der Ereignistyp ist bereits in der Anwendung vorhanden.',
  },
  rallyType: {
    created: 'Rallye Typ wurde erfolgreich erfasst',
    updated: 'Rallye Typ wurde erfolgreich aktualisiert',
    deleted: 'Rallye Typ wurde erfolgreich gelöscht',
    existingTypeError: 'Der Rally-Typ ist bereits in der Anwendung vorhanden.',
  },
  other: {
    error: 'Ein Fehler ist aufgetreten',
    'Sort By': 'Sorteren op',
    'Sort Order': 'Sorteervolgorde',
    'Download Type': 'Downloadtype',
    orientation: 'Oriëntatie',
  },
  mail: {
    signup: 'Willkommen bei COMPREG',
    reset: 'Passwort zurücksetzen',
    organizer: 'COMPREG Neues Konto für Organisator',
    event: 'Einladung zu',
    participant: 'Compreg Neues Konto für Teilnehmer',
  },
  fields: {
    'Pilot First Name': 'Pilot First Name',
    'Pilot Nationality': 'Pilot, Nationalität',
    'Pilot Birth Date': 'Pilot, Geburtsdatum',
    'Pilot Birth Place': 'Pilot, Geburtsort',
    'Pilot Emergency Contact': 'Copilot, Nummer für Notfall',
    'Pilot Drivers License Number': 'Copilot, Nummer Führerschein',
    'pilot_drivers_license_date.Pilot Drivers License Date': 'Pilot Drivers License Date',
    'Pilot Drivers License Place': 'Pilot, Datum Führerschein',
    'Pilot Drivers License Country': 'Pilot, Lizenzland',
    'Pilot Passport Number': 'Pilot, Pass Nummer',
    'Pilot Passport Delivery Date': 'Copilot, Datum Pass',
    'Pilot Passport Delivery Place': 'Pilot, Ausstellort Pass',
    'Pilot License': 'Copilot, Lizenznummer',
    'Pilot Team': 'Pilot Team',
    'Pilot Clothing Size': 'Pilot Kleidergrösse',
    'Pilot Signature': 'Pilot Unterschrift',
    Copilot: 'Copilot',
    'Copilot Nationality': 'Copilot Nationality',
    'Copilot Birth Date': 'Copilot, Geburtsdatum',
    'Copilot Birth Place': 'Copilot, Geburtsort',
    'Copilot Emergency Contact': 'Copilot, Nummer für Notfall',
    'Copilot Drivers License Number': 'Copilot, Nummer Führerschein',
    'Copilot Drivers LicenseDate': 'Copilot, Ausstelldatum des Führerscheins',
    'Copilot Drivers License Place': 'Copilot, Ausstellort Führerschein',
    'Copilot Drivers License Country': 'Copilot, Land des Führerschein',
    'Copilot Passport Number': 'Copilot, Nummer Pass',
    'Copilot Passport Delivery Date': 'Copilot, Datum Pass',
    'Copilot Passport Delivery Place': 'Copilot Passport Delivery Place',
    'Copilot License': 'Copilot, Lizenznummer',
    'Copilot Team': 'Copilot Team',
    'Copilot Clothing Size': 'Copilot Kleidergrösse',
    'Copilot Signature': 'Copilot Unterschrift',
    'Car Brand': 'Fahrzeugmarke',
    'Car Model': 'Fahrzeugmodell',
    'Car Group': 'Fahrzeuggruppe',
    'Car Class': 'Fahrzeugklasse',
    'Car Registration': 'Fahrzeugzulassung',
    'Car Displacement': 'Fahrzeug Hubraum',
    'Car Homologation  Number': 'Fahrzeug Homologationsnummer',
    'Car Historic Technical Passport': 'Historischer Fahrzeugpass',
    'Car Owner Name': 'Fahrzeug, Besitzername',
    'Car Owner Loan Aprroval Date': 'Fahrzeug Darlehensdatum',
    'Car Technical Check OK': 'Wagen technische Kontrolle OK',
    'Car Chassis Number': 'Wagen Chassis Nummer',
    'Car Engine Number': 'Wagen Motor Nummer',
    'Car Color': 'Wagen Farbe',
    'Car Picture': 'Wagen Foto',
    'Reco Car Brand': 'Rekog Fahrzeugmarke',
    'Reco Car Model': 'Rekog Fahrzeugmarke',
    'Reco Car Registration': 'Rekog Registrationsnummer',
    'Reco Car Control Plate': 'Rekog Fahrzeugschild',
    'Reco Car Color': 'Rekog Fahrzeugfarbe',
    'Assistance Contact In Charge': 'Assistenz beauftragter Kontakt',
    'Assistance Requirements Width': 'Assistenz Bedarf Breit',
    'Assistance Requirements Length': 'Assistenz Bedarf Länge',
    'Assistance Requirements Height': 'Assistenz Bedarf Höhe',
    'Assistance Memo': 'Assistenz Memo',
    Heading: 'Titel',
    Paragraph: 'Paragraph',
    Address: 'Adress',
    City: 'Ort',
    Country: 'Country',
    'Date and place of birth': 'Date and place of birth',
    Email: 'Email',
    Name: 'Name',
    Nationality: 'Nationalität',
    'Phone Number': 'Telefonnummer',
    'Postal Code': 'PLZ',
    Surname: 'Surname',
    'First Name': 'Vorname Name',
    'Passport Number': 'Pass Nummer',
    Company: 'Firma',
    Title: 'Titel',
    Mobile: 'Mobil',
    'Car Period': 'Wagen Periode',
    'Car Year': 'Wagen Jahr',
    'Generic Checkbox': 'Ursprung Checkbox',
    'Generic Date': 'Ursprung Datum',
    'Pilot Licence Number': 'Pilot Lizenz Nummer',
    'Pilot Licence Scan': 'Pilot Lizenz Scan',
    'Pilot Memo': 'Pilot Memo',
    'Copilot City': 'Copilot Ort',
    'Copilot Country': 'Copilot Land',
    'Copilot Email': 'Copilot Email',
    'Copilot Fixed': 'Copilot Fixed',
    'Pilot Sex': 'Pilot Geschlecht',
    'Pilot Email': 'Pilot Email',
    'Generic Amount': 'Allgemeiner Betrag',
    'Copilot Sex': 'Copilot Geschlecht',
    'Pilot Company': 'Pilot Firma',
    'Pilot Title': 'Pilot Titel',
    'Pilot Address': 'Pilot Adresse',
    'Pilot Address 2': 'Pilot Adresse 2',
    'Pilot City': 'Pilot Ort',
    'Pilot Country': 'Pilot Land',
    'Pilot Fixed': 'Pilot fixiert',
    'Copilot Picture': 'Copilot Bild',
    'Copilot Postal Code': 'Copilot Postleitzahl',
    'Copilot Web': 'Copilot Web',
    'Pilot Mobile': 'Pilot Mobilnummer',
    'Pilot Picture': 'Pilot Bild',
    'Pilot Postal Code': 'Pilot Postleitzahl',
    'Pilot Date Entry Country': 'Pilot Einreisedatum Land',
    'Pilot Emergency Email': 'Pilot Notfall Email',
    'Pilot Emergency Phone': 'Pilot Notfall Telefon',
    'Pilot Passport Scan': 'Pilot Pass Scan',
    'Pilot Place Entry Country': 'Pilot Einreiseort Land',
    'Pilot Drivers Licence Country': 'Pilot Führererschein Land',
    'Pilot Drivers Licence Date': 'Pilot Führerschein Datum',
    'Pilot Drivers Licence Number': 'Pilot Führerschein Nummer',
    'Pilot Drivers Licence Place': 'Pilot Führerschein Ort',
    'Pilot Drivers Licence Scan': 'Pilot Führerschein Scan',
    'Copilot Passport Scan': 'Copilot Pass Scan',
    'Copilot Place Entry Country': 'Copilot Einreiseort Land',
    'Copilot Drivers Licence Country': 'Copilot Führerschein Land',
    'Copilot Drivers Licence Date': 'Copilot Führerschein Datum',
    'Copilot Drivers Licence Number': 'Copilot Führerschein Nummer',
    'Copilot Drivers Licence Place': 'Copilot Führerschein Ort',
    'Copilot Drivers Licence Scan': 'Copilot Führerschein Scan',
    'Copilot Licence Number': 'Copilot Lizenz Nummer',
    'Copilot Licence Scan': 'Copilot Lizenz Scan',
    'Copilot Licence Type': 'Copilot Lizenz Art',
    'Entrant Sex': 'Teilnehmer Geschlecht',
    'Entrant Title': 'Teilnehmer Titel',
    'Entrant First Name': 'Teilnehmer Vorname',
    'Entrant Last Name': 'Teilnehmer Name',
    'Entrant Company': 'Teilnehmer Firma',
    'Entrant Address': 'Teilnehmer Adresse',
    'Entrant Address 2': 'Teilnehmer Adresse 2',
    'Entrant Postal Code': 'Teilnehmer Postleitzahl',
    'Entrant City': 'Teilnehmer Stadt',
    'Entrant Country': 'Teilnehmer Land',
    'Entrant Mobile': 'Teilnehmer Mobilnummer',
    'Entrant Fixed': 'Teilnehmer Festnetz',
    'Entrant Email': 'Teilnehmer Email',
    'Entrant Web': 'Teilnehmer Web',
    'Entrant Picture': 'Teilnehmer Bild',
    'Entrant Passport Number': 'Teilnehmer Pass Nummer',
    'Entrant Passport Delivery Date': 'Teilnehmer Pass Ausstelldatum',
    'Entrant Passport Delivery Place': 'Teilnehmer Pass Ausstellort',
    'Entrant Passport Scan': 'Teilnehmer Pass Scan',
    'Entrant Emergency Contact': 'Teilnehmer Notfall Kontakt',
    'Entrant Emergency Phone': 'Teilnehmer Notfall Telefon',
    'Entrant Emergency Email': 'Teilnehmer Notfall Email',
    'Entrant Place Entry Country': 'Teilnehmer Einreiseort Land',
    'Entrant Date Entry Country': 'Teilnehmer Einreisedatum Land',
    'Entrant Nationality': 'Teilnehmer Nationalität',
    'Entrant Birth Date': 'Teilnehmer Geburtsdatum',
    'Entrant Birth Place': 'Teilnehmer Geburtsort',
    'Entrant Signature': 'Teilnehmer Unterschrift',
    'Entrant Drivers Licence Scan': 'Teilnehmer Führerschein Scan',
    'Entrant Drivers Licence Number': 'Teilnehmer Führerschein Nummer',
    'Entrant Drivers Licence Date': 'Teilnehmer Führerschein Datum',
    'Entrant Drivers Licence Place': 'Teilnehmer Führerschein Ort',
    'Entrant Drivers Licence Country': 'Teilnehmer Führerschein Land',
    'Entrant Licence Scan': 'Teilnehmer Lizenz Scan',
    'Entrant Licence Number': 'Teilnehmer Lizenz Nummer',
    'Entrant Licence Type': 'Teilnehmer Lizenz Typ',
    'Entrant Clothing Size': 'Teilnehmer Kleidergrösse',
    'Entrant Team': 'Teilnehmer Team',
    'Entrant Memo': 'Teilnehmer Memo',
    'Rally Event Name': 'Rally Anlass Name',
    'Rally Name': 'Rally Name',
    'Rally Category': 'Rally Kategorie',
    'Rally Logo': 'Rally Logo',
    'Rally Venue': 'Rally Ort',
    'Rally Start Date': 'Rally Startdatum',
    'Rally End Date': 'Rally Enddatum',
    'Rally Timing Web': 'Rally Timing Web',
    'Rally Timing Organisation': 'Rally Timing Organisation',
    'Rally Timing Contact Person Sex': 'Rally Timing Geschlecht Kontaktperson',
    'Rally Timing Contact Person Title': 'Rally Timing Titel Kontaktperson',
    'Rally Timing Contact Person First Name': 'Rally Timing Vorname Kontaktperson',
    'Rally Timing Contact Person Last Name': 'Rally Timing Name Kontaktperson',
    'Rally Timing Contact Person Company': 'Rally Timing Firma Kontaktperson',
    'Rally Timing Contact Person Mobile': 'Rally Timing Mobilnr Kontaktperson',
    'Rally Timing Contact Person Address': 'Rally Timing Adresse Kontaktperson',
    'Rally Timing Contact Person Address 2': 'Rally Timing Adresse 2 Kontaktperson',
    'Rally Timing Contact Person Postal Code': 'Rally Timing Postleitzahl Kontaktperson',
    'Rally Timing Contact Person City': 'Rally Timing Ort Kontaktperson',
    'Rally Timing Contact Person Country': 'Rally Timing Land Kontaktperson',
    'Rally Timing Contact Person Fixed': 'Rally Timing Festnetz Kontaktperson',
    'Rally Timing Contact Person Web': 'Rally Timing Webseite Kontaktperson',
    'Rally Timing Contact Person Picture': 'Rally Timing Bild Kontaktperson',
    'Rally Timing Contact Person Passport Number':
      'Rally Timing Passnummer Kontaktperson',
    'Rally Timing Contact Person Email': 'Rally Timing Email Kontaktperson',
    'Rally Timing Contact Person Passport Delivery Date':
      'Rally Timing Pass Ausstelldatum Kontaktperson',
    'Rally Timing Contact Person Passport Delivery Place':
      'Rally Timing Pass Ausstellort Kontaktperson',
    'Rally Timing Contact Person Passport Scan': 'Rally Timing Pass Scan Kontaktperson',
    'Rally Timing Contact Person Emergency Contact':
      'Rally Timing Notfallkontakt Kontaktperson',
    'Rally Timing Contact Person Emergency Phone':
      'Rally Timing Notfallnummer Kontaktperson',
    'Rally Timing Contact Person Emergency Email':
      'Rally Timing Notfall Email Kontaktperson',
    'Rally Timing Contact Person Place Entry Country':
      'Rally Timing Einreiseort Land Kontaktperson',
    'Rally Timing Contact Person Date Entry Country':
      'Rally Timing Einreisedatum Land Kontaktperson',
    'Rally Timing Contact Person Nationality': 'Rally Timing Nationalität Kontaktperson',
    'Rally Timing Contact Person Birth Date': 'Rally Timing Geburtsdatum Kontaktperson',
    'Rally Timing Contact Person Signature': 'Rally Timing Unterschrift Kontaktperson',
    'Rally Timing Contact Person Drivers Licence Scan':
      'Rally Timing Führerschein Scan Kontaktperson',
    'Rally Timing Contact Person Birth Place': 'Rally Timing Geburtsort Kontaktperson',
    'Rally Timing Contact Person Drivers Licence Number':
      'Rally Timing Führerschein Nummer Kontaktperson',
    'Rally Timing Contact Person Drivers Licence Date':
      'Rally Timing Führerschein Datum Kontaktperson',
    'Rally Timing Contact Person Drivers Licence Place':
      'Rally Timing Führerschein Ort Kontaktperson',
    'Rally Timing Contact Person Drivers Licence Country':
      'Rally Timing Führerschein Land Kontaktperson',
    'Rally Timing Contact Person Licence Scan': 'Rally Timing Lizenz Scan Kontaktperson',
    'Rally Timing Contact Person Licence Number':
      'Rally Timing Lizenznummer Kontaktperson',
    'Rally Timing Contact Person Licence Type': 'Rally Timing Lizenztyp Kontaktperson',
    'Rally Timing Contact Person Clothing Size':
      'Rally Timing Kleidergrösse Kontaktperson',
    'Rally Timing Contact Person Team': 'Rally Timing Team Kontaktperson',
    'Rally Timing Contact Person Memo': 'Rally Timing Memo Kontaktperson',
    'Reco Memo': 'Rekog Memo',
    'Assistance Contact Incharge Sex': 'Verantwortlicher Helfer Kontakt Geschlecht',
    'Assistance Contact Incharge Title': 'Verantwortlicher Helfer Kontakt Titel',
    'Assistance Contact Incharge First Name': 'Verantwortlicher Helfer Kontakt Vorname',
    'Assistance Contact Incharge Last Name': 'Verantwortlicher Helfer Kontakt Name',
    'Assistance Contact Incharge Company': 'Verantwortlicher Helfer Kontakt Firma',
    'Assistance Contact Incharge Address': 'Verantwortlicher Helfer Kontakt Adresse',
    'Assistance Contact Incharge Address 2': 'Verantwortlicher Helfer Kontakt Adresse 2',
    'Assistance Contact Incharge Postal Code':
      'Verantwortlicher Helfer Kontakt Postleitzahl',
    'Assistance Contact Incharge City': 'Verantwortlicher Helfer Kontakt Ort',
    'Assistance Contact Incharge Country': 'Verantwortlicher Helfer Kontakt Land',
    'Assistance Contact Incharge Mobile': 'Verantwortlicher Helfer Kontakt Mobilnummer',
    'Assistance Contact Incharge Fixed': 'Verantwortlicher Helfer Kontakt Festnetz',
    'Assistance Contact Incharge Email': 'Verantwortlicher Helfer Kontakt Email',
    'Assistance Contact Incharge Web': 'Verantwortlicher Helfer Kontakt Web',
    'Assistance Contact Incharge Picture': 'Verantwortlicher Helfer Kontakt Bild',
    'Assistance Contact Incharge Passport Number':
      'Verantwortlicher Helfer Kontakt Pass Nummer',
    'Assistance Contact Incharge Passport Delivery Date':
      'Verantwortlicher Helfer Kontakt Pass Ausstelldatum',
    'Assistance Contact Incharge Passport Delivery Place':
      'Verantwortlicher Helfer Kontakt Pass Ausstellort',
    'Assistance Contact Incharge Passport Scan':
      'Verantwortlicher Helfer Kontakt Pass Scan',
    'Assistance Contact Incharge Emergency Contact':
      'Verantwortlicher Helfer Kontakt Notfall Kontakt',
    'Assistance Contact Incharge Emergency Phone':
      'Verantwortlicher Helfer Kontakt Notfall Telefon Nr',
    'Assistance Contact Incharge Emergency Email':
      'Verantwortlicher Helfer Kontakt Notfall Email',
    'Assistance Contact Incharge Nationality':
      'Verantwortlicher Helfer Kontakt Nationalität',
    'Assistance Contact Incharge Place Entry Country':
      'Verantwortlicher Helfer Kontakt Einreseort Land',
    'Assistance Contact Incharge Date Entry Country':
      'Verantwortlicher Helfer Kontakt Einreisedatum Land',
    'Assistance Contact Incharge Birth Date':
      'Verantwortlicher Helfer Kontakt Geburtsdatum',
    'Assistance Contact Incharge Birth Place':
      'Verantwortlicher Helfer Kontakt Geburtsort',
    'Assistance Contact Incharge Signature':
      'Verantwortlicher Helfer Kontakt Unterschrift',
    'Assistance Contact Incharge Drivers Licence Scan':
      'Verantwortlicher Helfer Kontakt Führerschein Scan',
    'Assistance Contact Incharge Drivers Licence Number':
      'Verantwortlicher Helfer Kontakt Führerschein Nummer',
    'Assistance Contact Incharge Drivers Licence Date':
      'Verantwortlicher Helfer Kontakt Führerschein Datum',
    'Assistance Contact Incharge Drivers Licence Place':
      'Verantwortlicher Helfer Kontakt Führerschein Ort',
    'Assistance Contact Incharge Drivers Licence Country':
      'Verantwortlicher Helfer Kontakt Führerschein Land',
    'Assistance Contact Incharge Licence Scan':
      'Verantwortlicher Helfer Kontakt Lizenz Scan',
    'Assistance Contact Incharge Licence Number':
      'Verantwortlicher Helfer Kontakt Lizenz Nummer',
    'Assistance Contact Incharge Licence Type':
      'Verantwortlicher Helfer Kontakt Lizenztyp',
    'Assistance Contact Incharge Clothing Size':
      'Verantwortlicher Helfer Kontakt Kleidergrösse',
    'Assistance Contact Incharge Team': 'Verantwortlicher Helfer Kontakt Team',
    'Assistance Contact Incharge Memo': 'Verantwortlicher Helfer Kontakt Memo',
    'Organiser Name': 'Organisator Name',
    'Organiser Logo': 'Organisator Logo',
    'Organiser Contact Person Sex': 'Organisator Geschlecht Kontaktperson',
    'Organiser Contact Person Title': 'Organisator Titel Kontaktpeson',
    'Organiser Contact Person Last Name': 'Organisator Name Kontaktperson',
    'Organiser Contact Person First Name': 'Organisator Vorname Kontaktperson',
    'Organiser Contact Person Company': 'Organisator Firma Kontaktperson',
    'Organiser Contact Person Address': 'Organisator Adresse Kontaktperson',
    'Organiser Contact Person Address 2': 'Organisator Adresse 2 Kontaktperson',
    'Organiser Contact Person Postal Code': 'Organisator Postleitzahl Kontaktperson',
    'Organiser Contact Person City': 'Organisator Ort Kontkatperson',
    'Organiser Contact Person Country': 'Organisator Land Kontaktperson',
    'Organiser Contact Person Mobile': 'Organisator Mobilnummer Kontaktperson',
    'Organiser Contact Person Fixed': 'Organisator Fixnetz Kontaktperson',
    'Organiser Contact Person Email': 'Organisator Email Kontaktperson',
    'Organiser Contact Person Web': 'Organisato Web Kontaktperson',
    'Organiser Contact Person Picture': 'Organisator Bild Kontaktperson',
    'Organiser Contact Person Passport Number': 'Organisator Pass Nummer Kontaktperson',
    'Organiser Contact Person Passport Delivery Place':
      'Organisator Pass Ort Kontaktperson',
    'Organiser Contact Person Passport Delivery Date':
      'Organisator Pass Datum Kontaktperson',
    'Organiser Contact Person Passport Scan': 'Organisator Pass Scan Kontaktperson',
    'Organiser Contact Person Emergency Contact':
      'Organisator Notfallkontakt Kontaktperson',
    'Organiser Contact Person Emergency Phone': 'Organisator Notfallnummer Kontaktperson',
    'Organiser Contact Person Emergency Email': 'Organisator NotfalEmail Kontaktperson',
    'Organiser Contact Person Place Entry Country':
      'Organisator Einreiseort Land Kontaktperson',
    'Organiser Contact Person Date Entry Country':
      'Organisator Einresedatum Land Kontaktperson',
    'Organiser Contact Person Nationality': 'Organisator Nationalität Kontaktperson',
    'Organiser Contact Person Birth Date': 'Organisator Geburtsdatum Kontaktperson',
    'Organiser Contact Person Birth Place': 'Organisator Geburtsort Kontaktperson',
    'Organiser Contact Person Signature': 'Organisator Unterschrift Kontaktperson',
    'Organiser Contact Person Drivers Licence Scan':
      'Kontaktperson Organisator Scan Führerschein',
    'Organiser Contact Person Drivers Licence Number':
      'Organisator Führerscheinnummer Kontaktperson',
    'Organiser Contact Person Drivers Licence Date':
      'Organisator Führerscheindatum Kontaktperson',
    'Organiser Contact Person Drivers Licence Place':
      'Organisator Führerscheinort Kontaktperson',
    'Organiser Contact Person Licence Scan': 'Organisator Lizenz Scan Kontaktperson',
    'Organiser Contact Person Drivers Licence Country':
      'Organisator Führerschein Land Kontaktperson',
    'Organiser Contact Person Licence Number': 'Organisator Lizenznummer Kontaktperson',
    'Organiser Contact Person Licence Type': 'Organisator Lizenztyp Kontaktperson',
    'Organiser Contact Person Clothing Size': 'Organisator Kleidergrösse Kontaktperson',
    'Organiser Contact Person Team': 'Organisator Team Kontaktperson',
    'Organiser Contact Person Memo': 'Organisator Memo Kontaktperson',
    'Car Registration Scan': 'Fahrzeugzulassung Scan',
  },
  'General settings': {
    'General settings': 'Generelle Einstellungen',
    'Email Restrictions': 'Email Einschränkungens',
    SMTP: 'SMTP',
    'Admin Email': 'Email Administrator',
    'Email Restriction for Participant': 'Email Einschränkung für Teilnehmer',
    'Email Restriction for Oragnizer': 'Email Einschränkung für Organisator',
    'Email Restriction for Oragnizer Contact':
      'E-Mail-Einschränkung für den Organisator-Kontakt',
    'All the participant emails will be forwarded to admin email':
      'Alle Teilnehmer-Emails werden an den Email-Admin geschickt',
    'All the Oragniser Contact emails will be forwarded to admin email':
      'Alle Organisatoren-Emails werden an den Email-Admin geschickt',
    'All the Oragniser emails will be forwarded to admin email':
      'Alle E-Mails des Organisators werden an die E-Mail-Adresse des Administrators weitergeleitet',
    Host: 'Host',
    Port: 'Port',
    Secure: 'Secure',
    'End Date Time is Required': 'End Datum Zeit wird verlangt',
    'Start Date Time is Required': 'Start Datum Zeit wird verlangt',
    'Type is Required': 'Typ wird verlangtt',
    'Assign Participants To Race': 'Teilnehmer zu Rennen zuordnen',
    'Choose CSV': 'CSV wählen',
    'Add file': 'Datei einfügen',
    Regulations: 'Regeln',
    'Official forms': 'Offizielle Formate',
    'Timing schedule': 'Zeitplan',
    Roadbook: 'Roadbook',
    other: 'andere',
    'Event Cloned Successfully': 'Anlass erfolgreich kopiert',
    'filter forms': 'Formate filtern',
    'No Forms Available': 'Keine Formate verfügbar',
    'Update Event Type': 'Anlass-Typ aktualisieren',
    'email must be a valid email': 'Email muss eine gültige Email sein',
    'Edit Form Header': 'Formattitel aktualisieren',
    'Pdf download failed': 'Pdf runterladen fehlgeschlagen',
    'Assign Participants To Rally': 'Teilnehmer zum Rally zulassen',
    'Event Type Updated Successfully': 'Anlass-Typ erfolgreich aktualisiert',
    'Download Sample CSV': 'Muster CSV runterladen',
    'Form field - Multiple- True/False': 'Formatfeld - Mehrfach - Wahr/Falsch',
    'Release Notes': 'Notizen freigeben',
    'Copilot Nationality': 'Copilot Nationalität',
    'Copilot Passport Delivery Place': 'Copilot Pass Ausstellungsort',
    'Date and place of birth': 'Geburtsdatum und Ort',
    'Phone Number': 'Telefonnummer',
    'Postal Code': 'Postleitzahl',
    Surname: 'Übernahme',
    'Add participants': 'Teilnehmer zufügen',
    FormHeader: 'FormatTitel',
    to: 'zu',
    count: 'Zähler',
    page: 'Seite',
    'Wollen Sie den Organisator wirklich löschen?':
      'Wollen Sie diesen Organisator wirklich löschen?',
    'Filter Forms': 'Formate filtern',
    'Type is required': 'Typ wird benötigt',
    'start date time is required': 'Startdatum wird benötigt',
    'end date time is required': 'Enddatum wird benötigt',
    'organiser is required': 'Organisator wird benötigt',
    'Form name is required': 'Formatname wird benötigt',
    'No space and Uppercase letters allowed':
      'Keine Leerschläge und Grossbuchstabel erlaubt',
    Radio: 'Radio',
    File: 'Datei',
    'choose less than 1 MB': 'wähle kleiner als 1 MB',
    'Driving License': 'Führerschein',
    'Menu Icon': 'Menu Icon',
    'Participant Created Successfuly': 'Teilnehmer erfolgreich erstellt',
    'Participant details updated successfully':
      'Details für Teilnehmer erfolgreich aktualisiert',
    'Submitted successfully': 'Erfolgreich eingereicht',
    'Zip download failed': 'ZIP runterladen fehlgeschlagen',
    From: 'Van',
    'choose an image file less than 1MB': 'Bilddatei kleiner als 1MB auswählen',
    'Please choose an image file': 'Bilddatei auswählen',
    'Empty list could not convert to csv':
      'Leere Liste konnte nicht in CSV konvertiert werden',
  },
  formTypes: {
    number: 'Nummer',
    text: 'Text',
    textarea: 'Textzone',
    radio: 'Radio',
    checkbox: 'checkbox',
    date: 'Datum',
    time: 'time',
    datetime: 'datetime',
    file: 'file',
    heading: 'Titel',
    paragraph: 'paragraph',
    image: 'Bild',
    line: 'linie',
    dropdown: 'laten vallen',
  },
  menuItems: {
    AllCategory: 'Alle Kategorien',
    Pilot: 'Pilot',
    Copilot: 'Co-Pilot',
    Car: 'Fahrzeug',
    Reco: 'Reco',
    Assistance: 'Assistance',
    Entrant: 'Teilnehmer',
    Organiser: 'Organisator ',
    Rally: 'Rallye',
    Participant: 'Teilnehmer',
    Other: 'Andere',
  },
  // drag: {
  //   createdAt: 'createdAt',
  //   companyname: 'companyname',
  //   email: 'email',
  //   phone: 'phone',
  //   enquiryPhone: 'enquiryPhone',
  //   address1: 'address1',
  //   address2: 'address2',
  //   street: 'street',
  //   city: 'city',
  //   state: 'state',
  //   isActive: 'isActive',
  //   isCancelled: 'isCancelled',
  //   status: 'status',
  // },
  exportDesigner: {
    exportWarning: `Cannot download Pdf with more than ${PDFColumLimit} fields. You can either choose CSV with the same settings or choose fields below ${PDFColumLimit}`,
  },
  message: {
    'at document row': 'at document row',
    'eventName is required': 'eventName ist erforderlich',
    'eventName duplicate found': 'eventName-Duplikat gefunden',
    'eventType is required': 'eventType ist erforderlich',
    "Can't submit empty sheet": 'Leeres Blatt kann nicht gesendet werden',
    'invalid eventType': 'ungültiger Ereignistyp',
    'timezone is required': 'Zeitzone ist erforderlich',
    'timezone mismatch': 'Zeitzonenkonflikt',
    'startDateTime is required': 'startDateTime ist erforderlich',
    'endDateTime is required': 'endDateTime ist erforderlich',
    'startDateTime is greater than endDateTime':
      'startDateTime ist größer als endDateTime ',
    'lastDate of registarion is required':
      'letztes Datum der Registrierung ist erforderlich',
    'lastDate of registarion is less than endDateTime':
      'Letztes Registrierungsdatum ist kleiner als End DateTime ',
    'organizer is required': 'Veranstalter erforderlich',
    'invalid organizer': 'Non valide Veranstalter',
    // rally
    'rallyName is required': 'rallyName ist erforderlich',
    'rallName duplicate found': 'rallName-Duplikat gefunden',
    'invalid eventName': 'ungültiger Ereignisname',
    'rallyType is required': 'RallyType ist erforderlich',
    'invalid rallyType': 'ungültiger RallyType',
    'Rally startDate must between event startDate/endDate':
      ' Startdatum der Rallye muss zwischen Startdatum/Enddatum des Events liegen',
    'Rally endDate must between event startDate/endDate':
      ' Rally endDate muss zwischen event startDate/endDate liegen',
    // organizer
    'password is required': 'Passwort ist obligatorisch',
    'password length must be greater than 6 characters':
      'Die Passwortlänge muss mehr als 6 Zeichen betragen ',
    'username is required': 'Benutzername ist erforderlich',
    'username is already exist': 'Benutzername existiert bereits',
    'name is already exist': 'Name ist bereits vorhanden',
  },
  login: {
    'Your account is blocked.Please contact admin for more details':
      'Ihr Konto ist gesperrt. Bitte kontaktieren Sie den Administrator für weitere Details',
    'No user found': 'Kein Benutzer gefunden',
  },
  restore: {
    'Restore Event': 'Evenement herstellen',
    'Restore Organiser': `Restaurer l'organisateur`,
  },
  errors: {
    'Invalid email at document row ': 'Ungültige E-Mail in der Dokumentzeile ',
    'Invalid username format': 'Ungültiges Benutzernameformat',
    'Username is required at document row ': 'Benutzername wird benötigt in Dokumentze ',
    'Password is required at document row ':
      'Benutzername erforderlich at dokument Reihe ',
    'FirstName is required at document row ':
      'Vorname erforderlich ist at dokument Reihe ',
  },
  eventStatus: {
    Preparation: 'Voorbereiding',
    'Registrations open': 'Inschrijvingen geopend',
    'Registrations completed': 'Inschrijvingen voltooid',
    'In progress': 'Bezig',
    'Event finished': 'Evenement afgelopen',
    Cancelled: 'Geannuleerd',
  },
  Exporter: {
    Source: 'Bron',
    Destination: 'Bestemming',
    'Rally Exporter': 'Rally-exporteur',
    'Event Exporter': 'Evenement-exporteur',
    'Organizer Exporter': 'Organisator-exporteur',
    'Organizer Contact Exporter': 'Organisatorcontact-exporteur',
    'Form Fields Exporter': 'Formulier Velden-exporteur',
    'Event Participants Exporter': 'Deelnemers-exporteur voor evenementen',
    'Rally Participants Exporter': "Deelnemers-exporteur voor rally's",
  },
  AppDropDown: {
    ASCENDING: 'Aufsteigend',
    DESCENDING: 'Absteigend',
  },
  'File Type': {
    'File Type': 'Dateityp',
    'File Type Management': 'Dateitypverwaltung',
    'File Type Listing': 'Auflistung der Dateitypen',
    Delete: 'Möchten Sie den Dateityp wirklich löschen?',
    Deleted: 'Dateityp erfolgreich gelöscht',
    'File type is required': 'Dateityp ist erforderlich',
    created: 'Dateityp erfolgreich erstellt',
    updated: 'Dateityp erfolgreich aktualisiert',
    existingTypeError: 'Der Dateityp ist bereits in der Anwendung vorhanden',
    addNew: 'Neuen Dateityp hinzufügen',
    update: 'Dateityp aktualisieren',
  },
};
