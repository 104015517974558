/* eslint-disable no-param-reassign */
import moment from 'moment';

import { validateDate } from 'src/Services/Auth/Validations';
import { AttachmentType } from 'src/Types/AttchmentType';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import { EventType } from 'src/Types/EventType';
import { v4 as uuidv4 } from 'uuid';

export type statusType = {
  value: string;
  label: string;
};
export const statusTypes: Array<statusType> = [
  {
    value: EnumEventStatus.Preparation,
    label: EnumEventStatus.Preparation,
  },
  {
    value: EnumEventStatus.RegistrationsOpen,
    label: EnumEventStatus.RegistrationsOpen,
  },
  {
    value: EnumEventStatus.RegistrationsCompleted,
    label: EnumEventStatus.RegistrationsCompleted,
  },
  {
    value: EnumEventStatus.InProgress,
    label: EnumEventStatus.InProgress,
  },
  {
    value: EnumEventStatus.EventFinished,
    label: EnumEventStatus.EventFinished,
  },
  {
    value: EnumEventStatus.Cancelled,
    label: EnumEventStatus.Cancelled,
  },
];

const disabledEventStatus = [
  EnumEventStatus.Cancelled,
  EnumEventStatus.EventFinished,
  EnumEventStatus.InProgress,
  EnumEventStatus.RegistrationsCompleted,
];

export function firstLetterToUpperCase(input: string) {
  return input
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
}

export const isEventStatusDisabled = (newEvent, eventStatus) => {
  if (newEvent) {
    return disabledEventStatus.includes(
      EnumEventStatus[firstLetterToUpperCase(eventStatus)],
    );
  }
  return false;
};

export const preProcessData = (data: EventType) => {
  if (data.startDateTime) {
    data.startDateTime = moment(data.startDateTime).startOf('day');
  }
  if (data.endDateTime) {
    data.endDateTime = moment(data.endDateTime).endOf('day');
  }
  if (data.startDateOfReg) {
    data.startDateOfReg = moment(data.startDateOfReg).startOf('day');
  }
  if (data.lastDateOfReg) {
    data.lastDateOfReg = moment(data.lastDateOfReg).endOf('day');
  }
  if (data.enquiryEmail) {
    data.enquiryEmail = data.enquiryEmail.toLowerCase();
  }
  if (!data.organizer) {
    delete data.organizer;
  }
};

export const prepareFormData = (data: EventType, image: any, files: AttachmentType[]) => {
  const formData = new FormData();
  const date = new Date().toLocaleDateString();
  Object.keys(data).map((k) => {
    if (data[k].format) {
      formData.append(k, data[k].format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    } else {
      formData.append(k, data[k]);
    }
    return k;
  });

  if (image.binary) {
    formData.append('eventImage', image.binary, image.fileName);
  }
  if (files) {
    const attachmentString: Array<AttachmentType> = [];
    const infoString: Array<AttachmentType> = [];
    // eslint-disable-next-line array-callback-return
    files.map((el: AttachmentType) => {
      if (el.files) {
        const uuid = `attachment_${uuidv4()}`;
        attachmentString.push(el);
        formData.append(uuid, el.files);
        infoString.push({
          dropdown: el.dropdown,
          uuid,
          name: el.name,
          size: el.size,
          type: el.type,
          date,
        });
      }
    });
    formData.append('info', JSON.stringify(infoString));
  }
  return formData;
};

function validateEvent(data: EventType, t: Function) {
  if (!validateDate(data, t)) {
    return false;
  }
  return true;
}

export default validateEvent;
