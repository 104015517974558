import axios from 'axios';
import { sortingOptionFornest, sortingOptions } from 'src/Services/Utils';
import store from '../../../Store';
import { baseUrl, baseUrlV2 } from '../../Constants';

export const getToken = () => {
  const { token } = store.store.getState().session;
  return token;
};

export const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export const GetRallyTypes = ({
  sortBy = 'type',
  sort = 'asc',
  page = 0,
  rowsPerPage = 10,
  search = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage,
  });
  return axios.get(`${baseUrl}/api/rally/types/rally-types?${options}`, getHeaders());
};

export const DeleteRallyType = (id) =>
  axios.delete(`${baseUrl}/api/rally/delete/rally-type/${id}`, getHeaders());

export const AddRallyType = (data) =>
  axios.post(`${baseUrl}/api/rally/create/rally-type`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const UpdateRallyType = (data, id) =>
  axios.put(`${baseUrl}/api/rally/update/rally-type/${id}`, data, getHeaders());

export const FindRallyTypeById = (id) =>
  axios.get(`${baseUrl}/api/rally/rally-type/${id}`, getHeaders());

export const GetFileTypes = ({
  sortBy = 'createdAt',
  sort = 'ASC',
  page = 0,
  rowsPerPage = 10,
  search,
}) => {
  const options = sortingOptionFornest({
    sortBy,
    sort,
    search: search === undefined ? '' : search,
    page,
    rowsPerPage,
    field: 'key',
  });
  return axios.get(`${baseUrlV2}/api/v2/file-type?&${options}`, getHeaders());
};
export const FindFileTypeById = (id) =>
  axios.get(`${baseUrlV2}/api/v2/file-type/${id}`, getHeaders());

export const AddFileType = (data) =>
  axios.post(`${baseUrlV2}/api/v2/file-type`, data, getHeaders());

export const UpdateFileType = (id, data) =>
  axios.patch(`${baseUrlV2}/api/v2/file-type/${id}`, data, getHeaders());

export const DeleteFileType = (id) =>
  axios.delete(`${baseUrlV2}/api/v2/file-type/${id}`, getHeaders());
