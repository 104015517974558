import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { fileSizeLimit } from 'src/Services/Constants';
import { toast } from 'react-toastify';
import displayFileSize, {
  checkTotalFileSize,
} from 'src/Components/Utils/DisplayFileSize';
import uploadImg from './ImageConfig/cloud-upload-regular-240.png';
import './AddFileInput.css';

function DragDrop({ uploadFile, setUploadFile, setTotalFileSize }) {
  const { t } = useTranslation();
  const wrapperRef = useRef<any>(null);
  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (event) => {
    const dropingFiles = [...event.target.files];
    const dropedFiles: Array<any> = [];
    // eslint-disable-next-line array-callback-return
    dropingFiles.map((file) => {
      const { size } = file;
      if (size > fileSizeLimit) {
        toast.warning(
          `${displayFileSize(file.size)} ${file.name} ${t(
            `validationErrors.file is not in the file size limit, Pleas choose less than`,
          )} ${displayFileSize(fileSizeLimit)}...!!`,
          {
            position: 'top-center',
          },
        );
        return;
      }
      const fileData = {
        files: file,
        BlobURL: URL.createObjectURL(file),
        type: file.type,
        name: file.name,
        size,
        _id: uuidv4(),
        dropdown: 'Regulations',
      };
      dropedFiles.push(fileData);
      setTotalFileSize(checkTotalFileSize([...uploadFile, ...dropedFiles]));
      setUploadFile([...uploadFile, ...dropedFiles]);
    });
  };

  return (
    <div
      ref={wrapperRef}
      className="drop-file-input"
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="drop-file-input__label">
        <img style={{}} src={uploadImg} alt="" />
        <p>{t(`events.Drag or Drop your files here`)}</p>
      </div>
      <input type="file" value="" onChange={onFileDrop} multiple />
    </div>
  );
}

export default DragDrop;
