import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import _ from 'lodash';
import './style.css';
import { useLocationType } from 'src/Types/UseLocationType';
import WarningToast from 'src/Components/Utils/WarningToast';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import useConfirmationDialog from 'src/Hooks/useConfirmationDialog';
import useStatusMatch from 'src/Hooks/useStatusMatch';
import validateEvent, { prepareFormData, preProcessData } from '../EventUtils';
import useStyles from './EditEventStyle';
import Autocomplete from '../../../Components/Utils/AutocompleteEdit';
import { updateEvent, getEventTypes } from '../../../Services/Auth/Actions/EventActions';
import { commonResponseHandler, formateDateToNonUtcDate } from '../../../Services/Utils';
import EventDetailForm from './EventDetailForm';

let setSubmittingCb;

function EditEvent({ details, reload, isLoading }) {
  const { t } = useTranslation();
  const { organizerId }: any = useParams();
  const classes = useStyles();
  const location = useLocation<useLocationType>();
  const history = useHistory();
  const [image, setImage] = useState('');
  const [eventTypes, setEventTypes] = useState<Array<any>>([]);
  const [eventOrganizersData, setEventOrganizersData] = useState<Array<any>>([]);
  const [initialValues, setInitialValues] = useState({});
  const [eventOrganiser, setEventOrganiser] = useState({});
  const [dateEdit, setDateEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const confirmationDialog = useConfirmationDialog();
  const { isStatusMatch } = useStatusMatch();

  if (!details.attachment) {
    // eslint-disable-next-line no-param-reassign
    details.attachment = [];
  }

  const eventTypesOptions = eventTypes.map((option: any) => {
    const newObject = {
      value: option._id,
      label: option.type,
      id: option._id,
    };
    return newObject;
  });

  const getEventTypesDetailsById = (userId) => {
    getEventTypes(userId).then((response) => {
      setEventTypes(response.data.eventTypes.eventTypes);
    });
  };

  const onAutocompleteChange = (event, data, setFieldValue) => {
    if (data && data.eventOrganizersData) {
      getEventTypesDetailsById(data.userId);
      setEventOrganizersData(data && data.eventOrganizersData);
      setEventOrganiser(data);
      setFieldValue('eventOrganizer', '');
      setFieldValue('enquiryEmail', '');
      setFieldValue('enquiryPhone', '');
      setFieldValue('organizer', (data && data._id) || '');
    } else {
      setEventOrganizersData([]);
    }
  };

  useEffect(() => {
    setInitialValues({
      eventName: details.eventName || '',
      startDateTime: details.startDateTime
        ? formateDateToNonUtcDate(details.startDateTime)
        : new Date(),
      endDateTime: details.endDateTime
        ? formateDateToNonUtcDate(details.endDateTime)
        : new Date(),
      eventOrganizer: details.eventOrganizer || '',
      organizer: (details.organizer && details.organizer._id) || '',
      enquiryEmail: details.enquiryEmail || '',
      enquiryPhone: details.enquiryPhone || '',
      venue: details.venue || '',
      memo: details.memo || '',
      lastDateOfReg: details.lastDateOfReg
        ? formateDateToNonUtcDate(details.lastDateOfReg)
        : new Date(),
      startDateOfReg:
        details.startDateOfReg && formateDateToNonUtcDate(details.startDateOfReg),
      website: details.website || '',
      facebookUrl: details.facebookUrl || '',
      instagramUrl: details.instagramUrl || '',
      eventType: eventTypesOptions[2],
      eventDescription: details.eventDescription || '',
      status: details.status || '',
    });
    setEventOrganizersData(
      details && details.organizer && details.organizer.eventOrganizers,
    );
    if (details.organizer?.userId) {
      getEventTypesDetailsById(details.organizer.userId._id);
    }
    const newOrg = {
      ...details.organizer,
    };
    newOrg.eventOrganizersData =
      details && details.organizer && details.organizer.eventOrganizers;
    newOrg.eventOrganizers =
      details &&
      details.organizer &&
      details.organizer.eventOrganizers.map((el) => el._id);
    delete newOrg.userId;
    // eslint-disable-next-line no-underscore-dangle
    delete newOrg.__v;
    delete newOrg.createdAt;
    delete newOrg.updatedAt;
    newOrg.organizer = {
      email: details.enquiryEmail,
      phone: details.enquiryPhone,
    };
    setEventOrganiser(newOrg);
    setFiles(details?.attachment);
  }, [details]);

  const setData = (data) => {
    const result = _.get(data, 'data.organizers');
    if (result && result.length > 0) {
      const tempOrgData = result[0];
      setEventOrganizersData(tempOrgData.eventOrganizersData);
      setInitialValues({
        ...initialValues,
        enquiryPhone:
          tempOrgData.organizerType === 'individual'
            ? tempOrgData.organizer.phone || ''
            : '',
        enquiryEmail:
          tempOrgData.organizerType === 'individual'
            ? tempOrgData.organizer.email || ''
            : '',
        organizer: tempOrgData._id,
      });
    }
  };

  const callApi = (data) => {
    setSubmittingCb(true);

    const formData = prepareFormData(data, image, files);
    commonResponseHandler(
      updateEvent(details._id, formData),
      () => {
        history.push(location.state?.goBackpath ? location.state.goBackpath : '/events');
      },
      () => {
        if (reload) {
          reload();
        }
        setSubmittingCb(false);
      },
    );
  };

  const confirmEditDate = (setSubmitting, data) => {
    const description = t(
      'popup.There is a mismatch between the event date and rally date. Please manually change the rally date',
    );

    confirmationDialog(description, () => callApi(data), setSubmitting(false));
  };

  const confirmCancel = (setSubmitting, data) => {
    const description = (
      <>
        <p className={classes.desc}>
          {t('popup.Are you sure you want to cancel the event?')}
        </p>
        <p className={classes.desc}>{t('popup.This is an irreversible action.')}</p>
      </>
    );
    confirmationDialog(description, () => callApi(data), setSubmitting(false));
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (
      !isStatusMatch({
        status: values.status,
        startDate: values.startDateTime,
        endDate: values.endDateTime,
        startRegDate: values.startDateOfReg,
        lastRegDate: values.lastDateOfReg,
      })
    ) {
      setSubmitting(false);
      return;
    }
    if (details.status === EnumEventStatus.Cancelled) {
      WarningToast(t('toast.Unfortunately, the cancelled cannot be edited'));
      setSubmitting(false);
      return;
    }

    setSubmittingCb = setSubmitting;
    setSubmitting(true);
    const data: any = {
      ...values,
    };
    preProcessData(data);
    if (!validateEvent(data, t)) {
      setSubmitting(false);
      return;
    }
    if (values.status === EnumEventStatus.Cancelled) {
      confirmCancel(setSubmitting, data);
      return;
    }
    if (dateEdit === true) {
      confirmEditDate(setSubmitting, data);
      return;
    }
    setSubmitting(false);
    callApi(data);
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <EventDetailForm
          setFiles={setFiles}
          isLoading={isLoading}
          files={files}
          setDateEdit={setDateEdit}
          eventTypes={eventTypes}
          setImage={setImage}
          details={details}
          organizerId={organizerId}
          setData={setData}
          setEventTypes={setEventTypes}
          onAutocompleteChange={onAutocompleteChange}
          eventOrganiser={eventOrganiser}
          eventOrganizersData={eventOrganizersData}
          onSubmit={onSubmit}
          initialValues={{
            ...initialValues,
            eventType:
              (details.eventType && details.eventType?._id) ||
              (eventTypes.length > 0 && eventTypes[1]?._id),
            status: details.status || '',
          }}
          Autocomplete={Autocomplete}
        />
      </div>
    </div>
  );
}
export default EditEvent;
