import { Formik, Form, Field } from 'formik';
import { Grid, Box } from '@material-ui/core';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  CircularLoader,
  ErrorMessageCustom,
  FormButton,
  FormLabel,
  SubTitle,
  Select,
} from 'src/Components/Utils';
import OrganizerContactAddEdit from 'src/Containers/Organizers/OrganizerDetails/OrganizerContactAddEdit';
import { getEventTypes } from 'src/Services/Auth/Actions/EventActions';
import {
  autocomplete,
  getOrganisersWithMembersById,
} from 'src/Services/Auth/Actions/OrganizerActions';
import { Loader } from 'src/Services/Utils';
import { RootState } from 'src/Store';
import AvatarUpload from 'src/Components/AvatarUpload/AvatarUpload';
import EventValidations from 'src/Views/Events/EventValidations';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { useHistory, useLocation } from 'react-router';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import CanAccess from 'src/Components/Utils/CanAccess';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import EnumSubmitActions from 'src/Types/Enums/EnumSubmitActions';
import WarningToast from 'src/Components/Utils/WarningToast';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import { eventOrganizerType } from 'src/Types/eventOrganizerType';
import { EventRallytypesType } from 'src/Types/EventRallytypesType';
import { useLocationType } from 'src/Types/UseLocationType';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import useStatusMatch from 'src/Hooks/useStatusMatch';
import EventImg from './EventImg.svg';
import useStyles from './EventDetailFormStyle';
import AddFileButton from '../Rally/AddFileButton';
import { isEventStatusDisabled, statusType, statusTypes } from '../EventUtils';

function EventDetailForm({
  setFiles,
  isLoading,
  files,
  setDateEdit,
  eventTypes,
  setImage,
  details,
  organizerId,
  setData,
  setEventTypes,
  onAutocompleteChange,
  eventOrganiser,
  eventOrganizersData,
  onSubmit,
  initialValues,
  newEvent = false,
  Autocomplete,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const role = useSelector((state: RootState) => state.role.role);
  const userRole = useSelector((state: RootState) => state.role.user);
  const history = useHistory();
  const location = useLocation<useLocationType>();
  const locationId = window.location.pathname.split('/')[3];
  const modal = useModalDialog();
  const setFieldValueRef = useRef(null);
  const { isStatusMatch } = useStatusMatch();

  const fetchExistingUserData = async (id: string) => {
    const data = await getOrganisersWithMembersById(id);
    setData(data);
  };

  const setOpenAddOrganizerContact = useMemo(
    () => modal(OrganizerContactAddEdit, 'AddOrganizerContact'),
    [],
  );

  const handleOrgContactPopupOpen = (organizer, setFieldValue) => {
    if (!organizer) {
      WarningToast(t('toast.Please choose an Organizer'));
      return;
    }
    const setSelectedOrgDatas = (eventManager: eventOrganizerType) => {
      setFieldValue('eventOrganizer', eventManager._id);
      setFieldValue('enquiryPhone', eventManager.phone || '');
      setFieldValue('enquiryEmail', eventManager.email || '');
    };
    const reload = (eventManager) => {
      fetchExistingUserData(organizer);
      setTimeout(() => {
        setSelectedOrgDatas(eventManager);
      }, 1000);
    };
    setOpenAddOrganizerContact(true, {
      tittle: 'Add New Organiser Contact',
      details: organizer,
      reload,
      isModal: true,
      submitAction: EnumSubmitActions.create,
    });
  };

  const fetchEventType = (userId) =>
    getEventTypes(userId).then((response) => {
      setEventTypes(response.data.eventTypes.eventTypes);
    });

  useEffect(() => {
    (async () => {
      let organiserId = '';
      if (organizerId) {
        organiserId = organizerId;
      }
      if (
        (!organizerId && role === EnumUserRoles.eventOrganizer) ||
        role === EnumUserRoles.organizer
      ) {
        organiserId = userRole._id;
      }
      if (organiserId.length === 24) {
        fetchExistingUserData(organiserId);
        fetchEventType(organiserId);
      }
      if (location?.state?.userId) {
        fetchEventType(location.state.userId);
      }
    })();
  }, []);

  const handleEventStatusChange = (event, values, handleChange) => {
    if (
      isStatusMatch({
        status: event.target.value,
        startDate: values.startDateTime,
        endDate: values.endDateTime,
        startRegDate: values.startDateOfReg,
        lastRegDate: values.lastDateOfReg,
      })
    ) {
      handleChange(event);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={EventValidations}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }: any) => {
        setFieldValueRef.current = setFieldValue;
        return (
          <Form>
            <div className={classes.topWrap}>
              {newEvent ? (
                <div className={classes.newEventTitle}>
                  <Icon
                    onClick={() => {
                      if (locationId) {
                        history.push(`/organizers/details/${locationId}/events`);
                      } else {
                        history.goBack();
                      }
                    }}
                    icon="mdi:keyboard-backspace"
                    className={classes.iconBack}
                  />
                  <SubTitle title={t('events.Add new Events')} />
                </div>
              ) : (
                <SubTitle title={t('forms.Event Details')} />
              )}
              <div className={classes.btnWrap}>
                <Loader isLoading={isSubmitting} type="ThreeDots">
                  <FormButton
                    className={classes.btn}
                    name={t('forms.Submit')}
                    type="submit"
                  />
                </Loader>
              </div>
            </div>
            {isLoading ? (
              <CircularLoader />
            ) : (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item lg={3} xs={12} sm={3}>
                    <AvatarUpload
                      image={EventImg}
                      defaultImage={details?.eventImage}
                      setImage={setImage}
                    />
                    <ErrorMessageCustom errors={errors} field="image" touched={touched} />
                  </Grid>
                  <Grid item lg={3} xs={12} sm={12}>
                    <FormLabel name={t('forms.Event Name')} require />
                    <Field
                      className={classes.input}
                      text="eventName"
                      type="text"
                      name="eventName"
                      value={values.eventName}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="eventName"
                      touched={touched}
                    />
                  </Grid>
                  {role === EnumUserRoles.superadmin && !organizerId ? (
                    <Grid item lg={3} xs={12} sm={6}>
                      <FormLabel name={t('forms.Event Organiser')} require />
                      <Autocomplete
                        onChange={(event, data) =>
                          onAutocompleteChange(event, data, setFieldValue)
                        }
                        selectValue={eventOrganiser}
                        search={(text) => autocomplete({}, text)}
                        name="organizer"
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="organizer"
                        touched={touched}
                      />
                    </Grid>
                  ) : null}
                  {role === EnumUserRoles.superadmin ||
                  role === EnumUserRoles.organizer ? (
                    <Grid item lg={3} xs={12} sm={6}>
                      <FormLabel name={t('forms.Organiser Contact')} />
                      <Select
                        name="eventOrganizer"
                        value={values.eventOrganizer}
                        onChange={(e) => {
                          handleChange(e);
                          fetchEventType(e.target.value);
                          const selectedOrganizer = eventOrganizersData.find(
                            (o: eventOrganizerType) => o._id === e.target.value,
                          );
                          setFieldValue('enquiryPhone', selectedOrganizer?.phone || '');
                          setFieldValue('enquiryEmail', selectedOrganizer?.email || '');
                        }}
                        onBlur={handleBlur}
                        style={{ display: 'block' }}
                        placeholder={t('forms.Select')}
                        options={
                          eventOrganizersData &&
                          eventOrganizersData.map((item: eventOrganizerType) => ({
                            value: item._id,
                            label: `${item.lastName} ${item.firstName}`,
                          }))
                        }
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <CanAccess permission={EnumPermissions.CreateEventOrganizer}>
                          <button
                            type="button"
                            className={classes.addNewContact}
                            onClick={() =>
                              handleOrgContactPopupOpen(
                                values.organizer,
                                setFieldValueRef.current,
                              )
                            }
                          >
                            {t('popup.+Add New Contact')}
                          </button>
                        </CanAccess>
                      </div>
                      <ErrorMessageCustom
                        errors={errors}
                        field="eventOrganizer"
                        touched={touched}
                      />
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Enquiry Email')} />
                    <Field
                      className={classes.input}
                      text="email"
                      type="email"
                      name="enquiryEmail"
                      value={values.enquiryEmail}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="enquiryEmail"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Enquiry Contact')} />
                    <Field
                      className={classes.input}
                      text="phone"
                      type="phone"
                      name="enquiryPhone"
                      value={values.enquiryPhone}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="enquiryPhone"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={12} xs={12}>
                    <FormLabel name={t('forms.Event Type')} require />
                    <Select
                      name="eventType"
                      value={values.eventType}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      style={{ display: 'block', marginBottom: '0px' }}
                      placeholder={t('forms.Select')}
                      options={eventTypes.map((item: EventRallytypesType) => ({
                        value: item._id,
                        label: `${item.type}`,
                      }))}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="eventType"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={12} xs={12}>
                    <FormLabel name={t('forms.Venue')} />
                    <Field
                      className={classes.input}
                      text="venue"
                      type="text"
                      name="venue"
                      value={values.venue}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom errors={errors} field="venue" touched={touched} />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Event Start Date')} require />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.input}
                        value={values.startDateTime}
                        onChange={(value) => {
                          if (setDateEdit) {
                            setDateEdit(true);
                          }
                          setFieldValue('startDateTime', value);
                          if (value ? value > values.endDateTime : false || newEvent) {
                            setFieldValue('endDateTime', value);
                            setFieldValue('lastDateOfReg', value);
                          }
                        }}
                        name="startDateTime"
                        format="dd/MM/yyyy"
                        minDate={values.startDateOfReg || Date()}
                        minDateMessage={`${t('events.Date should not be before')}
                      ${moment(values.startDateOfReg || Date()).format('DD/MM/YYYY')}`}
                        invalidDateMessage="invalidDate"
                        allowKeyboardControl
                        clearable
                        disablePast={newEvent}
                      />
                    </MuiPickersUtilsProvider>
                    <ErrorMessageCustom
                      errors={errors}
                      field="startDateTime"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Event End Date')} require />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.input}
                        value={values.endDateTime}
                        onChange={(value) => {
                          setFieldValue('endDateTime', value);
                          if (setDateEdit) {
                            setDateEdit(true);
                          }
                        }}
                        name="endDateTime"
                        format="dd/MM/yyyy"
                        minDate={values.startDateTime || Date()}
                        minDateMessage={`${t('events.Date should not be before')}
                      ${moment(values.startDateTime || Date()).format('DD/MM/YYYY')}`}
                        invalidDateMessage="invalidDate"
                        allowKeyboardControl
                        clearable
                        disablePast={newEvent}
                      />
                    </MuiPickersUtilsProvider>
                    <ErrorMessageCustom
                      errors={errors}
                      field="endDateTime"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Start Date of Registration')} require />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.input}
                        value={values.startDateOfReg}
                        onChange={(value) => {
                          setFieldValue('startDateOfReg', value);
                          if (setDateEdit) {
                            setDateEdit(true);
                          }
                        }}
                        name="startDateOfReg"
                        format="dd/MM/yyyy"
                        minDateMessage={`${t('events.Date should not be before')}
                      ${moment(Date()).format('DD/MM/YYYY')}`}
                        maxDate={values.startDateTime || values.lastDateOfReg}
                        maxDateMessage={`${t(`events.Date should not be after`)}
                      ${moment(values.startDateTime || values.lastDateOfReg).format(
                        'DD/MM/YYYY',
                      )}`}
                        invalidDateMessage="invalidDate"
                        allowKeyboardControl
                        clearable
                        disablePast={newEvent}
                      />
                    </MuiPickersUtilsProvider>
                    <ErrorMessageCustom
                      errors={errors}
                      field="startDateOfReg"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Last Date of Registration')} require />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.input}
                        value={values.lastDateOfReg}
                        onChange={(value) => {
                          setFieldValue('lastDateOfReg', value);
                          if (setDateEdit) {
                            setDateEdit(true);
                          }
                        }}
                        name="lastDateOfReg"
                        format="dd/MM/yyyy"
                        minDate={values.startDateOfReg || Date()}
                        minDateMessage={`${t('events.Date should not be before')}
                      ${moment(values.startDateOfReg || Date()).format('DD/MM/YYYY')}`}
                        maxDate={values.endDateTime}
                        maxDateMessage={`${t(`events.Date should not be after`)}
                      ${moment(values.endDateTime).format('DD/MM/YYYY')}`}
                        invalidDateMessage="invalidDate"
                        allowKeyboardControl
                        clearable
                        disablePast={newEvent}
                      />
                    </MuiPickersUtilsProvider>
                    <ErrorMessageCustom
                      errors={errors}
                      field="lastDateOfReg"
                      touched={touched}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Website')} />
                    <Field
                      className={classes.input}
                      text="website"
                      type="text"
                      name="website"
                      value={values.website}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="website"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Facebook URL')} />
                    <Field
                      className={classes.input}
                      text="facebookUrl"
                      type="text"
                      name="facebookUrl"
                      value={values.facebookUrl}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="facebookUrl"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Instagram URL')} />
                    <Field
                      className={classes.input}
                      text="instagramUrl"
                      type="text"
                      name="instagramUrl"
                      value={values.instagramUrl}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="instagramUrl"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Status')} require />
                    <Select
                      name="status"
                      value={values.status}
                      onChange={(event) =>
                        handleEventStatusChange(event, values, handleChange)
                      }
                      onBlur={handleBlur}
                      style={{ display: 'block', marginBottom: '0px' }}
                      disabled={initialValues.status === EnumEventStatus.Cancelled}
                      placeholder={t('forms.Select')}
                      options={statusTypes.map((item: statusType) => ({
                        key: item.value,
                        value: item.value,
                        label: t(`eventStatus.${item.label}`),
                        disabled: isEventStatusDisabled(newEvent, item.value),
                      }))}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="status"
                      touched={touched}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Event Details')} />
                    <Field
                      className={classes.input}
                      text="Event Details"
                      component="textarea"
                      rows="4"
                      name="eventDescription"
                      value={values.eventDescription}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="eventDescription"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <FormLabel name={t('forms.Memo')} />
                    <Field
                      className={classes.input}
                      text="memo"
                      component="textarea"
                      rows="4"
                      name="memo"
                      value={values.memo}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom errors={errors} field="memo" touched={touched} />
                  </Grid>
                </Grid>
                <div style={{ marginTop: '10px', marginLeft: '-8px' }}>
                  <AddFileButton files={files} setFiles={setFiles} />
                </div>
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default EventDetailForm;
