import makeStyles from '@material-ui/core/styles/makeStyles';
import theme from 'src/Theme';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DFE0EB',
    marginBottom: '20px',
    '@media only screen and (max-width: 450px)': {
      padding: '0 10px 10px 10px',
      display: 'block',
      height: '100px',
    },
  },
  btnWrap: {
    textAlign: 'left',
    '@media only screen and (max-width: 450px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  btn: {
    float: 'left',
    width: '100%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    '@media only screen and (max-width: 450px)': {
      margin: '0px',
      width: '250px',
    },
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid black',
    padding: '6px 8px',
    borderRadius: '5px',
    // fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    fontFamily: typography.fonts.secondary,
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  iconBack: {
    fontSize: '30px',
    color: theme.palette.primary.main,
  },
  addNewContact: {
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    color: 'blue',
    margin: '0px',
    cursor: 'pointer',
  },
  newEventTitle: {
    display: 'flex',
    alignItems: 'center',
  },
}));
