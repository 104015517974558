import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';
import theme from '../Theme/index';

export default makeStyles(() => ({
  uploadCSVButton: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
    position: 'relative',
    '@media only screen and (max-width: 651px)': {
      minWidth: '250px',
    },
  },
  inputCSV: {
    opacity: '0',
    fontSize: '0',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '6px',
    left: '0px',
    cursor: 'pointer',
  },
  sampleCSVLabel: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  toastErrorMessage: { maxHeight: '600px', overflow: 'auto' },
  eyeIcon: {
    color: colors.primary.color,
    fontSize: '20px',
  },
  btnSection: {
    '@media only screen and (max-width: 651px)': {
      display: 'grid !important',
    },
  },
  btnDownload: {
    '@media only screen and (max-width: 651px)': {
      minWidth: '250px',
    },
  },
}));
