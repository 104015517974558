import React, { useMemo } from 'react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import useTable from 'src/Hooks/useTable';
import CustomTable, { TopActionNormalButtonType } from 'src/Components/Table/Table';
import topActions from 'src/Types/Enums/EnumTopActions';
import {
  CommonTranslatedResponseHandler,
  formateDateWithoutTime,
} from 'src/Services/Utils';
import {
  DeleteEventType,
  GetEventTypes,
} from '../../Services/Auth/Actions/EventTypeActions';
import AddEditEventType from './AddEditEventType';
import useStyles from './EventTypeListingStyle';

type TopActionButtonType = TopActionNormalButtonType;

function EventTypeListing() {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirm: any = useConfirm();
  const modal = useModalDialog();
  const { addToSortableField } = useTable({
    tableName: 'EventType',
    sortableFields: ['type'],
  });

  const reloadTable = ({ sort, page, rowsPerPage, search: debouncedSearch }) =>
    GetEventTypes({
      sortBy: sort.sortBy,
      sort: sort.sort,
      search: debouncedSearch,
      page,
      limit: rowsPerPage,
    }).then((response) => {
      const data = response.data.eventTypes.eventTypes;
      const totalCount = response.data.eventTypes.count;
      return {
        data,
        totalCount,
      };
    });

  const DeleteEventTypeById = (deleteId, reload) => {
    confirm({
      description: t('popup.Do you want to delete ?'),
    }).then(() => {
      const responseHandlerProps = {
        promise: DeleteEventType(deleteId),
        successCb: () => {
          reload();
        },
        successToast: t('eventType.deleted'),
      };
      CommonTranslatedResponseHandler(responseHandlerProps);
    });
  };

  const AddEditModalSetOpen = useMemo(
    () => modal(AddEditEventType, 'add-edit-event-type'),
    [],
  );

  const showAddModalPopup = (reload) => {
    AddEditModalSetOpen(true, { reload });
  };

  const showEditModalPopup = (updateId, reload) => {
    AddEditModalSetOpen(true, { updateId, reload });
  };

  const columns = [
    {
      title: t('eventTypes.Event Type'),
      field: 'type',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbody}>
          {row?.type && addToSortableField('type')}
          <div className={classes.wrap}>
            <div>
              <div className={classes.tbodyName}>{row.type}</div>
            </div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.createdAt)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('eventTypes.Actions'),
      field: 'type',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row: any, reload) => (
        <div>
          <div className={classes.wrapper}>
            <Icon
              onClick={() => showEditModalPopup(row._id, reload)}
              icon="feather:edit-3"
              className={classes.editIcon}
              style={{ marginRight: '20px' }}
            />
            <Icon
              onClick={() => DeleteEventTypeById(row._id, reload)}
              icon="ant-design:delete-twotone"
              className={classes.icon}
              style={{
                cursor: 'pointer',
                marginRight: 'auto',
              }}
            />
          </div>
        </div>
      ),
      isSortable: false,
    },
  ];

  const topActionButton: TopActionButtonType[] = [
    {
      type: topActions.button,
      extraProps: {
        name: t('organizers.Add New'),
        onClick: async ({ reload }) => showAddModalPopup(reload),
      },
    },
  ];
  return (
    <div>
      <CustomTable
        tableName="Event Type Listing"
        columns={columns}
        reload={reloadTable}
        heading={t('eventTypes.Event Type Listing')}
        onDoubleClick={(item, reload) => showEditModalPopup(item._id, reload)}
        topActionButtons={topActionButton}
      />
    </div>
  );
}

export default EventTypeListing;
