import React from 'react';

type OptionType = {
  key: string;
  value: string;
  label: string;
  disabled: boolean;
};

type SelectProps = {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLSelectElement>) => void;
  style: React.CSSProperties;
  disabled?: boolean;
  placeholder: string;
  options: OptionType[];
};

export default function Select({
  name,
  value,
  onChange,
  onBlur,
  style,
  disabled = false,
  placeholder,
  options,
}: SelectProps) {
  // const { t } = useTranslation();
  // const classes = useStyles();

  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      disabled={disabled}
    >
      {placeholder && <option value="" label={placeholder} />}
      {options.map((item) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <option {...item} />
      ))}
    </select>
  );
}
