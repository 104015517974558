import { makeStyles } from '@material-ui/core';
import { typography } from 'src/Theme';
import colors from 'src/Theme/colors';

export default makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  tbodyName: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    color: '#ADAEB0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    width: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
