import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  AddFileType,
  FindFileTypeById,
  UpdateFileType,
} from 'src/Services/Auth/Actions/RallyTypeActions';
import { CommonTranslatedResponseHandler } from 'src/Services/Utils';
import { ErrorMessageCustom, FormButton, FormLabel } from 'src/Components/Utils';
import { FileTypeData, FileTypes } from 'src/Types/FileType';
import useStyles from './FileTypeListingStyle';

function AddEditFileType({ updateId, reload, setOpenPopup }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileTypeSchema = Yup.object().shape({
    type: Yup.string().required(t('File Type.File type is required')),
  });

  const [data, setData] = useState<FileTypeData>({
    _id: '',
    createdAt: '',
    key: '',
    type: '',
    createdBy: '',
  });

  useEffect(() => {
    if (updateId !== undefined) {
      FindFileTypeById(updateId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [updateId]);

  return (
    <div>
      <Formik
        initialValues={{ type: data?.type || '' }}
        enableReinitialize
        validationSchema={fileTypeSchema}
        onSubmit={(values, { setSubmitting }) => {
          const updateData: FileTypes = {
            ...values,
            key: values.type.toLowerCase().split(' ').join('_'),
          };

          if (updateId) {
            const responseHandlerProps = {
              promise: UpdateFileType(updateId, updateData),
              successCb: () => {
                setOpenPopup(false);
                reload();
              },
              errorCb: () => setSubmitting(false),
              successToast: t('File Type.updated'),
              errorToast: t('File Type.existingError'),
            };
            CommonTranslatedResponseHandler(responseHandlerProps);
          } else {
            const responseHandlerProps = {
              promise: AddFileType(updateData),
              successCb: () => {
                setOpenPopup(false);
                reload();
              },
              errorCb: () => setSubmitting(false),
              successToast: t('File Type.created'),
              errorToast: t('File Type.existingError'),
            };
            CommonTranslatedResponseHandler(responseHandlerProps);
          }
        }}
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form>
            <div className={classes.titleWrapper}>
              <h3>{updateId ? t('File Type.update') : t('File Type.addNew')}</h3>
              <FormButton
                className="btn"
                name={t('forms.Submit')}
                isLoading={isSubmitting}
              />
            </div>
            <div className="input-1">
              <FormLabel name={t('forms.Type')} require />
              <Field
                className={classes.input}
                text="type"
                type="text"
                name="type"
                value={values.type}
                onChange={handleChange}
              />
              <ErrorMessageCustom errors={errors} field="type" touched={touched} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddEditFileType;
